import axios from 'axios';
import humps from 'humps';

import api from '@/api';

export default {
  state: {
    templates: [],
  },

  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    },
  },

  actions: {
    createTemplate({ commit, dispatch, state }, template) {
      const data = template;
      const { backgroundImage, letterheadImage, signatureImage } = data;

      ['backgroundImage', 'letterheadImage', 'signatureImage'].forEach((key) => delete data[key]);

      return api.templates.create(humps.decamelizeKeys(data)).then(async (response) => {
        commit('setTemplates', [humps.camelizeKeys(response.data), ...state.templates]);

        let background = null;
        let letterhead = null;
        let signature = null;

        if (backgroundImage) {
          const backgroundResponse = await axios.get(backgroundImage, { responseType: 'blob' });

          background = new File([backgroundResponse.data], `background.${backgroundImage.split('.').pop()}`);
        }

        if (letterheadImage) {
          const letterheadResponse = await axios.get(letterheadImage, { responseType: 'blob' });

          letterhead = new File([letterheadResponse.data], `letterhead.${letterheadImage.split('.').pop()}`);
        }

        if (signatureImage) {
          const signatureResponse = await axios.get(signatureImage, { responseType: 'blob' });

          signature = new File([signatureResponse.data], `signature.${signatureImage.split('.').pop()}`);
        }

        if (background || letterhead || signature) {
          dispatch('updateTemplateImages', {
            id: response.data.id,
            background,
            letterhead,
            signature,
          });
        }
      });
    },

    getTemplates({ commit }) {
      return api.templates.all().then((response) => {
        commit('setTemplates', humps.camelizeKeys(response.data));
      });
    },

    getPreviewTemplates({ commit }, locale) {
      return api.templates.preview.all(locale).then((templates) => {
        commit('setTemplates', templates);
      });
    },

    updateTemplateImages({ commit, state }, {
      id, background, letterhead, signature,
    }) {
      const formData = new FormData();
      if (background) formData.append('background_image', background);
      if (letterhead) formData.append('letterhead_image', letterhead);
      if (signature) formData.append('signature_image', signature);

      return api.templates.updateTemplateImage(id, formData).then((response) => {
        commit('setTemplates', [humps.camelizeKeys(response.data), ...state.templates].filter(
          (v, i, a) => a.findIndex((t) => (t.id === v.id)) === i,
        ));
      });
    },

    deleteTemplate({ commit, state }, id) {
      return api.templates.delete(id).then(() => {
        commit('setTemplates', state.templates.filter((template) => template.id !== id));
      });
    },
  },
};
