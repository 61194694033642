<template>
  <button
    class="placeholder"
    :class="{
      [`placeholder--${recurringId}`]: id || '0'
    }"
  >
    {{ text }}
  </button>
</template>

<script src="./index.vue.js"></script>
