export default {
  state: {
    requesting: false,

    currentDialog: null,
  },

  mutations: {
    setRequesting(state, value) {
      state.requesting = value;
    },

    setCurrentDialog(state, dialog) {
      state.currentDialog = dialog;
    },
  },

  actions: {
    setCurrentDialog({ commit }, dialog) {
      commit('setCurrentDialog', dialog);
    },
  },
};
