import api from '@/api';

export default {
  name: 'RegisterActivate',

  computed: {
    requesting() {
      return this.$store.state.app.requesting;
    },
  },

  mounted() {
    this.$store.commit('setRequesting', true);

    api.auth.activateProfile({
      uid: this.$route.params.uid,
      token: this.$route.params.token,
    }).then(() => {
      this.$router.push({ name: 'login', query: { activated: '1' } });
    }).catch((error) => {
      // eslint-disable-next-line
      console.log(error.response && error.response.status);
    }).finally(() => {
      this.$store.commit('setRequesting', false);
    });
  },
};
