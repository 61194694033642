import { ref } from 'vue';
import Cookies from 'js-cookie';

import i18n from '@/i18n';

import api from '@/api';

import Button from '@/components/atoms/button/index.vue';

import DialogMessage from '@/components/molecules/dialogs/message/index.vue';

import authMixin from '@/mixins/auth';

export default {
  name: 'Login',

  components: {
    Button,

    DialogMessage,
  },

  mixins: [
    authMixin,
  ],

  setup() {
    const messageDialog = ref({});

    const email = ref(null);
    const password = ref(null);
    const errors = ref([]);

    return {
      messageDialog,

      email,
      password,
      errors,
    };
  },

  computed: {
    requesting() {
      return this.$store.state.app.requesting;
    },

    currentDialog() {
      return this.$store.state.app.currentDialog;
    },
  },

  methods: {
    login() {
      this.$store.commit('setRequesting', true);

      api.auth.createJwt({
        email: this.email,
        password: this.password,
      }).then((response) => {
        const accessTokenPayload = this.getJwtPayload(response.data.access);

        Cookies.set('jwt-access', response.data.access, {
          expires: new Date(accessTokenPayload.exp * 1000),
        });

        const refreshTokenPayload = this.getJwtPayload(response.data.refresh);

        Cookies.set('jwt-refresh', response.data.refresh, {
          expires: new Date(refreshTokenPayload.exp * 1000),
        });

        this.$store.dispatch('getCurrentUser').then(() => {
          this.$store.dispatch('setCurrentDocument', null);
          this.$store.dispatch('getDocuments');

          this.$router.push({ name: 'editor', query: this.$route.query }).then(() => {
            this.$store.commit('setRequesting', false);
          });
        });
      }).catch((error) => {
        this.errors = Object.values(error.response.data)
          .flat()
          .map((value) => value.replace(/[^a-z0-9 -]/gi, '')
            .replace(/\W+(.)/g, (_, chr) => chr.toUpperCase()));

        this.$store.commit('setRequesting', false);
      });
    },

    toggleDialog(dialog) {
      this.$store.commit('setCurrentDialog', dialog);
    },
  },

  mounted() {
    if (this.$route.query.registered) {
      this.messageDialog = {
        title: i18n.global.t('login.registered.title'),
        text: i18n.global.t('login.registered.text'),
      };

      this.toggleDialog('dialog-message');
    }

    if (this.$route.query.activated) {
      this.messageDialog = {
        title: i18n.global.t('login.activated.title'),
        text: i18n.global.t('login.activated.text'),
      };

      this.toggleDialog('dialog-message');
    }

    if (this.$route.query.logout) {
      this.messageDialog = {
        title: i18n.global.t('login.logout.title'),
        text: i18n.global.t('login.logout.text'),
      };

      this.toggleDialog('dialog-message');
    }
  },
};
