<template>
  <nav
    class="toolbar"
    :class="{ 'toolbar--initalized': initalized }"
  >
    <div class="toolbar__inner">
      <div class="toolbar__menu">
        <button
          class="toolbar__button toolbar__button--icon"
          :title="$t('global.openSidebar')"
          @click="openSidebar"
        >
          <Icon class="toolbar__button-icon" name="hamburger" />
        </button>
      </div>

      <div
        class="toolbar__logo-wrapper"
        @click="goHome"
      >
        <img class="toolbar__logo" src="@/assets/images/logo.svg" />
        <img class="toolbar__logo-batch" src="@/assets/images/batch-web.svg" />
      </div>

      <div class="toolbar__actions">
        <div class="toolbar__section">
          <div class="toolbar__segment">
            <button
              class="toolbar__button toolbar__button--mobile"
              :title="$t('toolbar.newDocument.title')"
              @click="$emit('createDocument')"
            >
              <Icon class="toolbar__button-icon" name="document-new" />
              <span class="toolbar__button-text">{{ $t('toolbar.newDocument.text') }}</span>
            </button>

            <button
              v-if="currentDocument"
              class="toolbar__button toolbar__button--mobile"
              :title="$t('toolbar.myTemplates.title')"
              @click="$emit('manageTemplates')"
            >
              <Icon class="toolbar__button-icon" name="bookmark" />
              <span class="toolbar__button-text">{{ $t('toolbar.myTemplates.text') }}</span>
            </button>
          </div>
        </div>

        <div class="toolbar__section">
          <div v-if="currentDocument" class="toolbar__segment">
            <button
              class="toolbar__button toolbar__button--mobile"
              :title="$t('toolbar.exportDocument.title')"
              @click="$emit('exportDocument')"
            >
              <Icon class="toolbar__button-icon" name="document-export" />
              <span class="toolbar__button-text">{{ $t('toolbar.exportDocument.text') }}</span>
            </button>

            <button
              v-if="currentUser && currentDocument.documentType !== DOCUMENT_TYPES.SERIAL"
              class="
                toolbar__button
                toolbar__button--mobile
                toolbar__button--wide
                toolbar__button--pin
              "
              :title="$t('toolbar.sendDocument.title')"
              @click="$emit('sendDocument')"
            >
              <Icon class="toolbar__button-icon" name="document-send" />
              <span class="toolbar__button-text">{{ $t('toolbar.sendDocument.text') }}</span>
            </button>
          </div>

          <div v-if="!currentUser" class="toolbar__segment">
            <button
              class="toolbar__button"
              :title="$t('global.login')"
              @click="$router.push({ name: 'login' })"
            >
              <span class="toolbar__button-text">{{ $t('global.login') }}</span>
            </button>

            <button
              class="toolbar__button"
              :title="$t('global.registerFree')"
              @click="$router.push({ name: 'register' })"
            >
              <span class="toolbar__button-text">{{ $t('global.register') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script src="./index.vue.js"></script>
