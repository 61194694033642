import Button from '@/components/atoms/button/index.vue';
import Placeholder from '@/components/atoms/placeholder/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogExportSerial',

  props: {
    open: Boolean,
    serialPlaceholders: Array,
    serialImportFile: Object,
    progress: Number,
  },

  components: {
    Button,
    Placeholder,

    Dialog,
  },
};
