import { ref } from 'vue';

import api from '@/api';
import i18n from '@/i18n';

import Button from '@/components/atoms/button/index.vue';

export default {
  name: 'Register',

  components: {
    Button,
  },

  setup() {
    const email = ref(null);
    const password = ref(null);
    const errors = ref([]);

    return {
      email,
      password,
      errors,
    };
  },

  computed: {
    requesting() {
      return this.$store.state.app.requesting;
    },
  },

  methods: {
    register() {
      this.$store.commit('setRequesting', true);

      api.auth.createProfile({
        email: this.email,
        password: this.password,
        locale: i18n.global.locale.value,
      }).then(() => {
        this.$router.push({ name: 'login', query: { ...this.$route.query, registered: '1' } }).then(() => {
          this.$store.commit('setRequesting', false);
        });
      }).catch((error) => {
        this.errors = Object.values(error.response.data)
          .flat()
          .map((value) => value.replace(/[^a-z0-9 -]/gi, '')
            .replace(/\W+(.)/g, (_, chr) => chr.toUpperCase()));

        this.$store.commit('setRequesting', false);
      });
    },
  },
};
