import fs from 'fs';

import Helvetica from 'pdfkit/js/data/Helvetica.afm';
import HelveticaBold from 'pdfkit/js/data/Helvetica-Bold.afm';

import Times from 'pdfkit/js/data/Times-Roman.afm';
import TimesBold from 'pdfkit/js/data/Times-Bold.afm';

import Courier from 'pdfkit/js/data/Courier.afm';
import CourierBold from 'pdfkit/js/data/Courier-Bold.afm';

fs.writeFileSync('data/Helvetica.afm', Helvetica);
fs.writeFileSync('data/Helvetica-Bold.afm', HelveticaBold);

fs.writeFileSync('data/Times-Roman.afm', Times);
fs.writeFileSync('data/Times-Bold.afm', TimesBold);

fs.writeFileSync('data/Courier.afm', Courier);
fs.writeFileSync('data/Courier-Bold.afm', CourierBold);
