<template>
  <Dialog
    class="upsell-pro-dialog"
    :open="open"
  >
    <div class="editor__section">
      <div class="upsell-pro-dialog__header">
        <FeatureLock
          :type="0"
          @click.stop="$emit('openPaywall')"
        />
      </div>

      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="font-family"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.upsellPro.headline') }}
          </label>
          <p
            class="editor__text"
            v-html="$t('dialog.upsellPro.description')"
          />
        </div>
      </div>
    </div>

    <footer class="dialog__footer">
      <Button
        :text="$t('global.cancel')"
        :style="'outline'"
        @click="$emit('cancel')"
      />

      <Button
        :text="$t('dialog.upsellPro.subscribe')"
        @click="subscribe"
      />
    </footer>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
