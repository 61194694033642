import client from './client';

import mockData from './data/templates';

export default {
  create(data) {
    return client.post('/templates/', data);
  },

  all() {
    return client.get('/templates/');
  },

  updateTemplateImage(id, formData) {
    return client.patch(`/templates/${id}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  delete(id) {
    return client.delete(`/templates/${id}/`);
  },

  preview: {
    all(locale) {
      return new Promise((resolve) => {
        resolve(mockData[locale].templates);
      });
    },
  },
};
