<template>
  <div class="menu">
    <div
      class="menu__main"
      :class="{ 'menu__main--locked': currentUser && currentUser.subscription.type < 1 }"
    >
      <FeatureLock
        v-if="!currentUser || currentUser && currentUser.subscription.type < 1"
        class="menu__feature-lock"
        backgroundColor="fbfbfb"
        :type="currentUser ? 0 : 2"
        :disabled="!currentUser"
        @click.stop="currentUser ? $emit('openPaywall') : null"
      />

      <div class="menu__inner">
        <div class="menu__section">
          <button
            class="menu-button"
            :title="$t('menu.addPage')"
            :disabled="formLocked"
            @click="addPage"
          >
            <Icon class="menu-button__icon" name="add-page" />
          </button>

          <button
            class="menu-button"
            :title="$t('menu.removeLastPage')"
            :disabled="formLocked"
            @click="removeLastPage"
          >
            <Icon class="menu-button__icon" name="remove-page" />
          </button>

          <button
            class="menu-button"
            :title="formLocked ? $t('menu.unlockDocument') : $t('menu.lockDocument')"
            @click="$emit('toggleLock')"
          >
            <Icon
              class="menu-button__icon"
              :name="formLocked ? 'lock-closed' : 'lock-open'"
            />
          </button>
        </div>

        <div class="menu__section">
          <button
            class="menu-button"
            :title="$t('menu.formatBold')"
            :disabled="!currentSelection"
            @click="formatBold"
          >
            <Icon class="menu-button__icon" name="bold" />
          </button>

          <button
            class="menu-button"
            :title="$t('menu.formatItalic')"
            :disabled="!currentSelection"
            @click="formatItalic"
          >
            <Icon class="menu-button__icon" name="italic" />
          </button>

          <button
            class="menu-button"
            :title="$t('menu.formatUnderline')"
            :disabled="!currentSelection"
            @click="formatUnderline"
          >
            <Icon class="menu-button__icon" name="underline" />
          </button>
        </div>

        <div class="menu__section">
          <button
            class="menu-button"
            :title="$t('menu.changeFontSize')"
            :disabled="formLocked"
            @click="changeFontSize"
          >
            <Icon class="menu-button__icon" name="font-size" />
          </button>

          <button
            class="menu-button"
            :title="$t('menu.updateFontFamily')"
            :disabled="formLocked"
            @click="$emit('updateFontFamily')"
          >
            <Icon class="menu-button__icon menu-button__icon--large" name="font-family" />
          </button>
        </div>

        <div class="menu__section">
          <button
            class="menu-button"
            :title="isFormInvoice ? $t('menu.undoInvoice') : $t('menu.doInvoice')"
            :disabled="formLocked"
            @click="currentUser && currentUser.subscription.type < 2
              ? $emit('openPaywall') : $emit('toggleInvoice')"
          >
            <Icon
              class="menu-button__icon"
              :name="isFormInvoice ? `${menuIconInvoice}-enabled` : `${menuIconInvoice}-default`"
            />
          </button>

          <button
            class="menu-button"
            :title="isFormSerial ? $t('menu.undoSerial') : $t('menu.doSerial')"
            :disabled="formLocked"
            @click="$emit('toggleSerial')"
          >
            <Icon
              class="menu-button__icon menu-button__icon--large"
              :name="isFormSerial ? 'serial-letter-enabled' : 'serial-letter-default'"
            />
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="isFormSerial"
      class="menu__secondary"
    >
      <div class="menu__secondary-column">
        <Button
          :text="$t('menu.managePlaceholders')"
          :size="'small'"
          :style="'secondary'"
          :disabled="formLocked"
          @click="$emit('managePlaceholders')"
        />
      </div>

      <div class="menu__secondary-column">
        <Placeholder
          v-for="(text, index) in formSerialPlaceholders"
          :key="index"
          :id="index"
          :text="text"
          :disabled="!currentFocus || formLocked"
          @click="addPlaceholder(text)"
        />
      </div>
    </div>
  </div>
</template>

<script src="./index.vue.js"></script>
