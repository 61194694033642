<template>
  <Dialog
    class="templates-dialog"
    :open="open"
    :buttonConfirm="$t('global.done')"
    @open="getTemplates"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="serial-data"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.templates.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.templates.description') }}</p>
        </div>
      </div>

      <div
        v-if="templates.length"
        class="editor__field"
      >
        <table class="editor__table editor__table--actions">
          <thead>
            <tr>
              <th class="editor__table-headline">
                <label class="editor__fieldset-label">{{ $t('dialog.templates.name') }}</label>
              </th>
              <th class="editor__table-headline">
                <label class="editor__fieldset-label">{{ $t('dialog.templates.type') }}</label>
              </th>
              <th class="editor__table-headline">
                <label class="editor__fieldset-label">{{ $t('dialog.templates.actions') }}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(template, index) in templates" :key="index">
              <td class="editor__table-datum">
                <b>{{ template.name }}</b>
              </td>
              <td class="editor__table-datum">
                {{ $t(`dialog.templates.type${template.documentType}`) }}
              </td>
              <td class="editor__table-datum">
                <Button
                  :text="$t('global.apply')"
                  :size="'small'"
                  :disabled="currentDocument.locked"
                  @click="applyTemplate(template)"
                />
                <Button
                  :text="$t('global.delete')"
                  :size="'small'"
                  :style="'outline-danger'"
                  :disabled="!currentUser"
                  @click="deleteTemplate(template)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="editor__fieldset editor__fieldset--horizontal">
        <label
          for="formNewTemplate"
          class="editor__fieldset-label"
        >{{ $t('dialog.templates.new') }}</label>
        <div>
          <input
            v-model="newTemplateName"
            id="formNewTemplate"
            class="editor__input"
            :placeholder="$t('dialog.templates.inputPlaceholder')"
          />
          <Button
            :text="$t('global.add')"
            :size="'small'"
            :disabled="!currentUser || !newTemplateName.length"
            @click="createTemplate"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
