import humps from 'humps';

import api from '@/api';

export default {
  state: {
    currentUser: null,
  },

  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },

  actions: {
    getCurrentUser({ commit }) {
      return api.auth.getProfile().then((response) => {
        commit('setCurrentUser', humps.camelizeKeys(response.data));
      });
    },

    updateCurrentUser({ commit }, data) {
      return api.auth.updateProfile(humps.decamelizeKeys(data)).then((response) => {
        commit('setCurrentUser', humps.camelizeKeys(response.data));
      });
    },

    updateCurrentUserAvatar({ commit }, file) {
      const formData = new FormData();
      formData.append('avatar_image', file);

      return api.auth.updateProfileImage(formData).then((response) => {
        commit('setCurrentUser', humps.camelizeKeys(response.data));
      });
    },

    resetCurrentUser({ commit }) {
      commit('setCurrentUser', null);
    },
  },
};
