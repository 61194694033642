<template>
  <Dialog
    class="send-dialog"
    :open="open"
  >
    <div
      v-if="loading"
      class="send-dialog__loading"
    />

    <div
      v-if="message"
      class="send-dialog__message"
      :class="{ [`send-dialog__message--${message.type}`]: message.type }"
    >
      <template v-if="message.type === 'success'">
        <div class="send-dialog__message-inner">
          <div
            class="send-dialog__message-checkmark"
            :class="{ 'send-dialog__message-checkmark--checked': checked }"
          />
        </div>

        <h1 class="send-dialog__message-headline">{{ $t('dialog.send.success.headline') }}</h1>
        <p class="send-dialog__message-text">{{ $t('dialog.send.success.text') }}</p>

        <input
          v-if="message.code"
          class="send-dialog__message-input"
          :value="message.code"
          readonly
        />
      </template>

      <template v-if="message.type === 'error'">
        <div class="send-dialog__message-inner">
          <div
            class="send-dialog__message-errormark"
            :class="{ 'send-dialog__message-errormark--checked': checked }"
          />
        </div>

        <h1 class="send-dialog__message-headline">{{ $t('dialog.send.error.headline') }}</h1>
        <p class="send-dialog__message-text">{{ $t('dialog.send.error.text') }}</p>
      </template>
    </div>

    <template v-if="!loading && !message">
      <div class="editor__section">
        <div class="editor__section-desc">
          <label class="editor__fieldset-label">{{ $t('dialog.send.headline') }}</label>
          <p class="editor__text">{{ $t('dialog.send.description') }}</p>
        </div>
      </div>

      <div class="send-dialog__content">
        <div class="send-dialog__content-side">
          <div v-if="currentUser" class="send-dialog__product-list">
            <div v-if="!availableProducts.length" class="send-dialog__product-list-error">
              <div class="send-dialog__product-list-error-inner">
                <h5 class="send-dialog__product-list-error-headline">
                  {{ $t('dialog.send.noAvailableProducts.headline') }}
                </h5>
                <p class="send-dialog__product-list-error-text">
                  {{ $t('dialog.send.noAvailableProducts.text') }}
                </p>
              </div>
            </div>
            <template
              v-else
              v-for="product in availableProducts"
              :key="product.id"
            >
              <input
                v-model="selectedProduct"
                class="send-dialog__product-list-input"
                type="radio"
                :id="product.id"
                :value="product">
              <label
                :class="`send-dialog__product-list-label send-dialog__product-list-label--${
                  product.id.includes('Bw') ? 'mono' : 'color'
                }`"
                :for="product.id"
              >
                <div class="send-dialog__product-list-info">
                  <h4 class="send-dialog__product-list-headline">
                    {{ $t(`dialog.send.${product.id}.headline`) }}
                  </h4>
                  <p class="send-dialog__product-list-text">
                    {{ $t(`dialog.send.${product.id}.text0`) }}
                  </p>
                  <p class="send-dialog__product-list-text">
                    {{ $t(`dialog.send.${product.id}.text1`) }}
                  </p>
                </div>

                <div class="send-dialog__product-list-price">
                  <span
                    v-if="currentUser.credits[product.id] === 0"
                    class="send-dialog__product-price"
                  >{{ $t(`dialog.send.${product.id}.price`) }}</span>
                  <div
                    v-if="currentUser.credits[product.id] > 0"
                  >
                    <p class="send-dialog__product-list-text"><strong>
                      {{ $t('dialog.send.credit') }}
                    </strong></p>
                    <span class="send-dialog__product-credit-text">
                      {{ currentUser.credits[product.id] }}
                    </span>
                  </div>
                  <p
                    v-if="currentUser.credits[product.id] === 0"
                    class="send-dialog__product-price-info"
                  >{{ $t('dialog.send.tax') }}</p>
                  <a
                    v-if="currentUser.credits[product.id] > 0"
                    class="send-dialog__product-link"
                    @click="initiatePayment(product)"
                  >
                    {{ $t('dialog.send.buyMore') }}
                  </a>
                </div>
              </label>
            </template>
          </div>

          <div class="send-dialog__attachments">
            <div class="send-dialog__attachments-content">
              <label class="send-dialog__attachments-headline">
                {{ $t('dialog.send.attachments.headline') }}
              </label>
              <p
                v-if="totalAttachmentPages > 0"
                class="send-dialog__attachments-text"
                v-html="$t('dialog.send.attachments.info', {
                  totalPages, count: totalAttachmentPages
                })"
              />

              <p v-else class="send-dialog__attachments-text">
                {{ $t('dialog.send.attachments.text') }}
              </p>
            </div>
            <Button
              :text="$t('dialog.send.attachments.button')"
              :size="'small'"
              @click="$emit('openAttachments')"
            />
          </div>

          <div class="send-dialog__conditions">
            <h5 class="send-dialog__conditions-headline">
              {{ $t('dialog.send.conditions.headline') }}
            </h5>
            <p class="send-dialog__conditions-text" v-html="$t('dialog.send.conditions.text')" />
          </div>
        </div>
        <div
          class="send-dialog__preview-wrapper"
          :class="{ 'send-dialog__preview-wrapper--loading': previewLoading }"
        >
          <div class="send-dialog__preview">
            <iframe
              v-if="previewSource"
              v-show="!previewLoading"
              class="send-dialog__preview-frame"
              :src="`${previewSource}#toolbar=0&navpanes=0&zoom=FitH`"
            />
          </div>
        </div>
      </div>
    </template>

    <footer class="dialog__footer send-dialog__footer">
      <img class="send-dialog__footer-logo" src="@/assets/images/logo-pin.svg" />

      <div class="send-dialog__footer-inner">
        <template v-if="message">
          <Button
            :text="$t('global.close')"
            @click="$emit('cancel')"
          />
        </template>

        <template v-else>
          <Button
            :text="$t('global.cancel')"
            :style="'outline'"
            :disabled="loading"
            @click="$emit('cancel')"
          />

          <Button
            :text="confirmButtonText"
            :style="'pin'"
            :disabled="!validatePageRange || loading"
            @click="confirm"
          />
        </template>
      </div>
    </footer>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
