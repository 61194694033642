import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/api';

import Badge from '@/components/atoms/badge/index.vue';
import Button from '@/components/atoms/button/index.vue';
import Icon from '@/components/atoms/icon/index.vue';
import Switch from '@/components/atoms/switch/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

import authMixin from '@/mixins/auth';

export default {
  name: 'DialogPaywall',

  props: {
    open: Boolean,
  },

  components: {
    Badge,
    Button,
    Icon,
    Switch,

    Dialog,
  },

  mixins: [
    authMixin,
  ],

  setup(_, context) {
    const store = useStore();
    const router = useRouter();

    const checkout = ref(null);

    const showProYearly = ref(false);
    const showBusinessYearly = ref(false);

    const selectedProCreditOption = ref(5);
    const selectedBusinessCreditOption = ref(15);

    const currentUser = computed(() => store.state.auth.currentUser);

    const currentProPrice = computed(() => {
      if (showProYearly.value) {
        if (parseInt(selectedProCreditOption.value, 10) === 0) {
          return process.env.VUE_APP_STRIPE_PRICE_PRO_NO_CREDITS_YEARLY;
        }

        return process.env.VUE_APP_STRIPE_PRICE_PRO_YEARLY;
      }

      if (parseInt(selectedProCreditOption.value, 10) === 0) {
        return process.env.VUE_APP_STRIPE_PRICE_PRO_NO_CREDITS_MONTHLY;
      }

      return process.env.VUE_APP_STRIPE_PRICE_PRO_MONTHLY;
    });

    const currentBusinessPrice = computed(() => {
      if (showBusinessYearly.value) {
        if (parseInt(selectedBusinessCreditOption.value, 10) === 0) {
          return process.env.VUE_APP_STRIPE_PRICE_BUSINESS_NO_CREDITS_YEARLY;
        }

        return process.env.VUE_APP_STRIPE_PRICE_BUSINESS_YEARLY;
      }

      if (parseInt(selectedBusinessCreditOption.value, 10) === 0) {
        return process.env.VUE_APP_STRIPE_PRICE_BUSINESS_NO_CREDITS_MONTHLY;
      }

      return process.env.VUE_APP_STRIPE_PRICE_BUSINESS_MONTHLY;
    });

    const toggleProSwitch = () => {
      showProYearly.value = !showProYearly.value;
    };

    const toggleBusinessSwitch = () => {
      showBusinessYearly.value = !showBusinessYearly.value;
    };

    const subscribePro = () => {
      context.emit('cancel');
      store.commit('setRequesting', true);

      api.stripe.createCheckoutSession({
        price: currentProPrice.value,
        quantity: 1,
        mode: 'subscription',
        clientReferenceId: currentUser.value.id,
        subscriptionCredits: selectedProCreditOption.value,
        successUrl: `${window.location.origin}${router.resolve({ name: 'editor', query: { checkoutSuccess: 1 } }).href}`,
        cancelUrl: `${window.location.origin}${router.resolve({ name: 'editor', query: { checkoutCanceled: 1 } }).href}`,
      }).then((response) => {
        window.location.href = response.data.url;
      });
    };

    const subscribeBusiness = () => {
      context.emit('cancel');
      store.commit('setRequesting', true);

      api.stripe.createCheckoutSession({
        price: currentBusinessPrice.value,
        quantity: 1,
        mode: 'subscription',
        clientReferenceId: currentUser.value.id,
        subscriptionCredits: selectedBusinessCreditOption.value,
        successUrl: `${window.location.origin}${router.resolve({ name: 'editor', query: { checkoutSuccess: 1 } }).href}`,
        cancelUrl: `${window.location.origin}${router.resolve({ name: 'editor', query: { checkoutCanceled: 1 } }).href}`,
      }).then((response) => {
        window.location.href = response.data.url;
      });
    };

    return {
      checkout,

      showProYearly,
      showBusinessYearly,

      selectedProCreditOption,
      selectedBusinessCreditOption,

      currentUser,

      toggleProSwitch,
      toggleBusinessSwitch,
      subscribePro,
      subscribeBusiness,
    };
  },
};
