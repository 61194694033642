import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import DOCUMENT_TYPES from '@/services/document/types';

import Icon from '@/components/atoms/icon/index.vue';

import Startpage from '@/components/molecules/pages/startpage/index.vue';

export default {
  name: 'Toolbar',

  components: {
    Icon,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const initalized = ref(false);

    const currentDocument = computed(() => store.state.documents.currentDocument);
    const currentUser = computed(() => store.state.auth.currentUser);

    const openSidebar = () => store.commit('setShowSidebar', true);
    const goHome = () => {
      router.push({ name: 'editor' });
      store.dispatch('setCurrentDocument', null);
      store.dispatch('setCurrentPage', Startpage.name);
    };

    onMounted(() => {
      setTimeout(() => {
        initalized.value = true;
      }, 1500);
    });

    return {
      DOCUMENT_TYPES,

      initalized,

      currentDocument,
      currentUser,

      openSidebar,
      goHome,
    };
  },
};
