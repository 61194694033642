export default {
  computed: {
    formSender: {
      get() {
        return this.currentDocument.sender;
      },

      set(value) {
        this.$store.commit('updateSender', value);
      },
    },

    formRecipient: {
      get() {
        return this.currentDocument.recipient;
      },

      set(value) {
        this.$store.commit('updateRecipient', value);
      },
    },

    formMemo: {
      get() {
        return this.currentDocument.memo;
      },

      set(value) {
        this.$store.commit('updateMemo', value);
      },
    },

    formInfo: {
      get() {
        return this.currentDocument.info;
      },

      set(value) {
        this.$store.commit('updateInfo', value);
      },
    },

    formSubject: {
      get() {
        return this.currentDocument.subject;
      },

      set(value) {
        this.$store.commit('updateSubject', value);
      },
    },

    formDate: {
      get() {
        return Number.isNaN(this.currentDocument.issuedAt)
          ? null : new Date(this.currentDocument.issuedAt * 1000).toISOString().substring(0, 10);
      },

      set(value) {
        this.$store.commit('updateDate', new Date(value).getTime() / 1000);
      },
    },

    formText: {
      get() {
        return this.currentDocument.text;
      },

      set(value) {
        this.$store.commit('updateText', value);
      },
    },

    formFooter: {
      get() {
        return this.currentDocument.footer;
      },

      set(value) {
        this.$store.commit('updateFooter', value);
      },
    },

    formShowPageNumbers: {
      get() {
        return this.currentDocument.pageNumbers;
      },

      set(value) {
        this.$store.commit('updatePageNumbers', value);
      },
    },

    formShowFoldingMarkers: {
      get() {
        return this.currentDocument.foldingMarkers;
      },

      set(value) {
        this.$store.commit('updateFoldingMarkers', value);
      },
    },

    formDocumentType: {
      get() {
        return this.currentDocument.documentType;
      },

      set(value) {
        this.$store.commit('updateDocumentType', value);
      },
    },

    formNorm: {
      get() {
        return this.currentDocument.norm;
      },

      set(value) {
        this.$store.commit('updateNorm', value);
      },
    },

    formFontFamily: {
      get() {
        return this.currentDocument.fontFamily;
      },

      set(value) {
        this.$store.commit('updateFontFamily', value);
      },
    },

    formFontSize: {
      get() {
        return this.currentDocument.fontSize;
      },

      set(value) {
        this.$store.commit('updateFontSize', value);
      },
    },

    formLocked: {
      get() {
        return this.currentDocument.locked;
      },

      set(value) {
        this.$store.commit('updateLocked', value);
      },
    },

    formShowBackground: {
      get() {
        return this.currentDocument.background;
      },

      set(value) {
        this.$store.commit('updateBackground', value);
      },
    },

    formBackgroundImage: {
      get() {
        return this.currentDocument.backgroundImage;
      },

      set(value) {
        this.$store.commit('updateBackgroundImage', value);
      },
    },

    formShowLetterhead: {
      get() {
        return this.currentDocument.letterhead;
      },

      set(value) {
        this.$store.commit('updateLetterhead', value);
      },
    },

    formLetterheadImage: {
      get() {
        return this.currentDocument.letterheadImage;
      },

      set(value) {
        this.$store.commit('updateletterheadImage', value);
      },
    },

    formLetterheadAlignment: {
      get() {
        return this.currentDocument.letterheadAlignment;
      },

      set(value) {
        this.$store.commit('updateLetterheadAlignment', value);
      },
    },

    formShowSignature: {
      get() {
        return this.currentDocument.signature;
      },

      set(value) {
        this.$store.commit('updateSignature', value);
      },
    },

    formSignatureImage: {
      get() {
        return this.currentDocument.signatureImage;
      },

      set(value) {
        this.$store.commit('updatesignatureImage', value);
      },
    },

    formSerialPlaceholders: {
      get() {
        return this.currentDocument.serialPlaceholders;
      },

      set(value) {
        this.$store.commit('updateSerialPlaceholders', value);
      },
    },

    formInvoiceId: {
      get() {
        return this.currentDocument.invoiceId;
      },

      set(value) {
        this.$store.commit('updateInvoiceId', value);
      },
    },

    formInvoiceTaxRate: {
      get() {
        return this.currentDocument.invoiceTaxRate;
      },

      set(value) {
        this.$store.commit('updateInvoiceTaxRate', value);
      },
    },

    formInvoiceCurrency: {
      get() {
        return this.currentDocument.invoiceCurrency;
      },

      set(value) {
        this.$store.commit('updateInvoiceCurrency', value);
      },
    },

    formInvoiceRows: {
      get() {
        return this.currentDocument.invoiceRows;
      },

      set(value) {
        this.$store.commit('updateInvoiceRows', value);
      },
    },
  },
};
