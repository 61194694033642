import { createWebHistory, createRouter } from 'vue-router';

import i18n, { LOCALES_AVAILABLE, LOCALE_DEFAULT } from '@/i18n';

import Layout from '@/app/layout/index.vue';
import Editor from '@/views/editor/index.vue';
import Login from '@/views/login/index.vue';
import Register from '@/views/register/index.vue';
import RegisterActivate from '@/views/register/activate/index.vue';

const routes = [
  {
    path: '/:locale',

    components: {
      default: Layout,
    },

    children: [
      {
        path: '',
        name: 'editor',
        component: Editor,
        children: [
          {
            path: 'document/:documentId',
            name: 'document',
            component: Editor,
          },
        ],
      },

      {
        path: 'login',
        name: 'login',
        component: Login,
      },

      {
        path: 'register',
        name: 'register',
        component: Register,
      },

      {
        path: 'register/activate/:uid/:token',
        name: 'register-activate',
        component: RegisterActivate,
      },
    ],
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  const { locale } = to.params;

  if (!LOCALES_AVAILABLE.includes(locale)) {
    return next(LOCALE_DEFAULT);
  }

  if (i18n.global.locale.value !== locale) {
    i18n.global.locale.value = locale;
  }

  return next();
});

router.afterEach(() => {
  document.title = i18n.global.t('meta.title');
});

export default router;
