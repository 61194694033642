<template>
  <div class="sidebar">
    <header class="sidebar__header">
      <div class="sidebar__profile">
        <button
          class="sidebar__profile-button"
          :class="{ 'sidebar__profile-button--avatar': currentUser }"
          :title="currentUser && $t('sidebar.openUserSettings')"
          :disabled="!currentUser"
          @click="$emit('showUserSettings')"
        >
          <img
            class="sidebar__profile-image"
            :src="`${avatarImage}?t=${new Date().getTime()}`"
          />
          <Icon class="sidebar__profile-icon" name="pencil" />
        </button>

        <div class="sidebar__profile-content">
          <p class="sidebar__profile-name">{{ profileName }}</p>
          <a v-if="currentUser" class="sidebar__profile-link" @click="logout">
            {{ $t('global.logout') }}
          </a>
          <template v-else>
            <router-link
              class="sidebar__profile-link"
              :to="{ name: 'register' }"
            >{{ $t('global.registerFree') }}</router-link>
            &nbsp;&middot;&nbsp;
            <router-link
              class="sidebar__profile-link"
              :to="{ name: 'login' }"
            >{{ $t('global.login') }}</router-link>
          </template>
        </div>
      </div>

      <div class="sidebar__header-inner">
        <h1 class="sidebar__headline">{{ $t('sidebar.headline') }}</h1>
        <span class="sidebar__count">{{ documentsFiltered.length }}</span>

        <button
          class="sidebar__header-button toolbar__button toolbar__button--icon"
          :title="$t('global.closeSidebar')"
          @click="closeSidebar"
        >
          <Icon class="toolbar__button-icon" name="hamburger" />
        </button>
      </div>
    </header>

    <div class="sidebar__search">
      <InputSearch
        v-model="searchTerm"
        :placeholder="$t('sidebar.searchPlaceholder')"
      />
    </div>

    <div class="sidebar__content">
      <ul
        v-if="documentsFiltered.length"
        class="sidebar__list"
      >
        <li
          v-for="(document, index) in documentsFiltered"
          :key="index"
          class="sidebar__item"
          :class="{
            'sidebar__item--active': currentDocument && currentDocument.id === document.id,
            'sidebar__item--disabled': document.locked
          }"
          tabindex="0"
          @click="selectDocument(document)"
          @keyup.enter="selectDocument(document)"
        >
          <Icon
            class="sidebar__item-icon sidebar__item-icon--primary"
            :name="`${formattedIconName(document.documentType)}-default`"
          />

          <Icon
            class="sidebar__item-icon sidebar__item-icon--secondary"
            :name="`${formattedIconName(document.documentType)}-inverted`"
          />

          <div class="sidebar__item-content">
            <h3 class="sidebar__item-headline">{{ document.subject }}</h3>
            <p class="sidebar__item-text" v-html="formattedItemText(document)" />
          </div>

          <div class="sidebar__item-actions">
            <button
              class="sidebar__item-action sidebar__item-action--disabled"
              :title="document.sentAt
                ? $t('sidebar.documentSentAt', { date: formattedDate(document.sentAt) })
                : $t('sidebar.documentNotSent')"
              @click.stop="$emit('sendDocument', document)"
            >
              <template v-if="document.sentAt">
                <Icon
                  class="sidebar__item-icon sidebar__item-icon--action
                  sidebar__item-icon--large sidebar__item-icon--primary"
                  name="sent-success-default"
                />

                <Icon
                  class="sidebar__item-icon sidebar__item-icon--action
                  sidebar__item-icon--large sidebar__item-icon--secondary"
                  name="sent-success-inverted"
                />
              </template>
              <template v-else>
                <Icon
                  class="sidebar__item-icon sidebar__item-icon--action
                  sidebar__item-icon--large sidebar__item-icon--primary"
                  name="sent-pending-default"
                />

                <Icon
                  class="sidebar__item-icon sidebar__item-icon--action
                  sidebar__item-icon--large sidebar__item-icon--secondary"
                  name="sent-pending-inverted"
                />
              </template>
            </button>

            <div
              v-if="document.locked"
              class="sidebar__item-action sidebar__item-action--disabled"
            >
              <Icon
                class="sidebar__item-icon sidebar__item-icon--action"
                name="lock-closed"
                :title="$t('sidebar.documentLocked')"
              />
            </div>

            <button
              v-if="!document.locked"
              class="sidebar__item-action"
              :title="$t('sidebar.documentDelete')"
              @click.stop="$emit('removeDocument', document)"
            >
              <Icon
                class="sidebar__item-icon sidebar__item-icon--action sidebar__item-icon--primary"
                name="trash-default"
              />

              <Icon
                class="sidebar__item-icon sidebar__item-icon--action sidebar__item-icon--secondary"
                name="trash-inverted"
              />
            </button>
          </div>
        </li>
      </ul>

      <div
        v-if="!currentUser"
        class="sidebar__wall">
        <div class="sidebar__wall-inner">
          <Icon class="sidebar__wall-icon" name="folder" />
          <h2 class="sidebar__wall-headline">{{ $t('sidebar.wallHeadline') }}</h2>
          <p class="sidebar__wall-text">{{ $t('sidebar.wallText') }}</p>

          <div class="sidebar__wall-actions">
            <router-link
              class="sidebar__wall-link"
              :to="{ name: 'register' }"
            >{{ $t('global.registerFree') }}</router-link>
            &middot;
            <router-link
              class="sidebar__wall-link"
              :to="{ name: 'login' }"
            >{{ $t('global.login') }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <footer class="sidebar__footer">
      <div class="sidebar__footer-column">
        &copy; jungspree 2019 – {{ new Date().getFullYear() }}
      </div>

      <div class="sidebar__footer-column">
        <a class="sidebar__footer-link" :href="`https://www.jungspree.com/${$i18n.locale}/privacy-policy/`" target="_blank">{{ $t('global.privacyPolicy') }}</a>
        &middot;
        <a class="sidebar__footer-link" :href="`https://www.jungspree.com/${$i18n.locale}/imprint/`" target="_blank">{{ $t('global.imprint') }}</a>
      </div>
    </footer>
  </div>
</template>

<script src="./index.vue.js"></script>
