import axios from 'axios';

import client from './client';

import mockData from './data/documents';

export default {
  create(data) {
    return client.post('/documents/', data);
  },

  get(id) {
    return client.get(`/documents/${id}/`);
  },

  all() {
    return client.get('/documents/');
  },

  update(id, data) {
    return client.patch(`/documents/${id}/`, data);
  },

  updateDocumentImage(id, formData) {
    return client.patch(`/documents/${id}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  delete(id) {
    return client.delete(`/documents/${id}/`);
  },

  preview: {
    create(documentType, locale) {
      return new Promise((resolve) => {
        resolve({
          ...mockData[locale].documents[documentType],

          id: mockData[locale].documents[documentType].id,
          documentType,
        });
      });
    },

    update(id, data, locale) {
      return new Promise((resolve) => {
        resolve({
          ...mockData[locale].documents.find((document) => document.id === id),
          ...data,

          updatedAt: parseInt(new Date().getTime() / 1000, 10),
        });
      });
    },
  },

  external: {
    create(id, locale) {
      return axios.create({
        baseURL: process.env.VUE_APP_LANDING_BASE_URL,
      }).get(`/api/${locale}/documents/${id}.json`);
    },
  },
};
