<template>
  <Dialog
    class="user-settings-dialog"
    :open="open"
    :buttonConfirm="$t('global.done')"
    @open="initialize"
    @confirm="save"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label class="editor__fieldset-label">
            {{ $t('dialog.userSettings.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.userSettings.description') }}</p>
        </div>
      </div>

      <div class="editor__field">
        <div class="user-settings-dialog__profile">
          <div class="user-settings-dialog__profile-column">
            <label
              class="sidebar__profile-button user-settings-dialog__profile-button"
              :title="$t('dialog.userSettings.profileImageTitle')"
            >
              <input
                class="editor__input editor__input--file"
                type="file"
                accept="image/jpeg"
                @change="updateImage"
              />

              <img
                class="sidebar__profile-image"
                :src="`${avatarImage}?t=${new Date().getTime()}`"
              />
              <Icon class="sidebar__profile-icon" name="pencil" />
            </label>
          </div>

          <div class="user-settings-dialog__profile-column">
            <div class="user-settings-dialog__name">
              <input
                class="user-settings-dialog__name-input"
                v-model="firstName"
                :placeholder="$t('dialog.userSettings.firstName')"
              />

              <input
                class="user-settings-dialog__name-input"
                v-model="lastName"
                :placeholder="$t('dialog.userSettings.lastName')"
              />
            </div>

            <h3 class="user-settings-dialog__headline">{{ $t('dialog.userSettings.email') }}</h3>
            <p class="user-settings-dialog__email">{{ currentUser.email }}</p>
          </div>
        </div>
      </div>

      <div class="editor__field">
        <div
          v-if="currentUser.subscription.type < 2"
          class="user-settings-dialog__plan user-settings-dialog__plan--pro"
        >
          <div class="user-settings-dialog__plan-content">
            <h3 class="user-settings-dialog__plan-headline">
              {{ currentUser.subscription.type === 1
                ? $t('dialog.userSettings.proPlan.headline.default')
                : $t('dialog.userSettings.proPlan.headline.upgrade') }}
            </h3>

            <p class="user-settings-dialog__plan-text">
              {{ currentUser.subscription.type === 1
                ? $t('dialog.userSettings.proPlan.intro.default')
                : $t('dialog.userSettings.proPlan.intro.upgrade') }}
            </p>
          </div>

          <div class="user-settings-dialog__plan-button">
            <a
              v-if="currentUser.subscription.type === 1 && stripeCustomerPortalUrl"
              class="button"
              :href="stripeCustomerPortalUrl"
            >{{ $t('dialog.userSettings.managePlan') }}</a>

            <button
              v-if="currentUser.subscription.type < 1"
              class="button"
              @click="$emit('openPaywall')"
            >{{ $t('dialog.userSettings.showPlan') }}</button>
          </div>

          <img
            class="user-settings-dialog__plan-illustration"
            src="@/assets/images/illustration-1.svg"
          />
        </div>

        <div class="user-settings-dialog__plan user-settings-dialog__plan--business">
          <div class="user-settings-dialog__plan-content">
            <h3 class="user-settings-dialog__plan-headline">
              {{ currentUser.subscription.type === 2
                ? $t('dialog.userSettings.businessPlan.headline.default')
                : $t('dialog.userSettings.businessPlan.headline.upgrade') }}
            </h3>

            <p class="user-settings-dialog__plan-text">
              {{ currentUser.subscription.type === 2
                ? $t('dialog.userSettings.businessPlan.intro.default')
                : $t('dialog.userSettings.businessPlan.intro.upgrade') }}
            </p>
          </div>

          <div class="user-settings-dialog__plan-button">
            <a
              v-if="currentUser.subscription.type === 2 && stripeCustomerPortalUrl"
              class="button"
              :href="stripeCustomerPortalUrl"
            >{{ $t('dialog.userSettings.managePlan') }}</a>

            <button
              v-if="currentUser.subscription.type < 2"
              class="button"
              @click="$emit('openPaywall')"
            >{{ $t('dialog.userSettings.showPlan') }}</button>

            <img
              class="user-settings-dialog__plan-illustration"
              src="@/assets/images/illustration-4.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
