<template>
  <Dialog
    :open="open"
    :buttonConfirm="$t('global.done')"
  >
    <div class="editor__section editor__section--multiple">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="signature"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.signature.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.signature.description') }}</p>
        </div>

        <input
          id="signature"
          type="checkbox"
          class="editor__checkbox"
          :checked="modelValue"
          @input="$emit('update:modelValue', $event.target.checked)"
        />
      </div>
    </div>

    <div class="editor__section editor__section--multiple">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label class="button editor__input-button">
            <input
              class="editor__input editor__input--file"
              type="file"
              accept="image/jpeg, image/png"
              @click="$event.target.value = null"
              @change="updateImage"
            />

            {{ $t('dialog.signature.fileInput') }}
          </label>

          <p class="editor__text editor__text--small">{{ $t('dialog.signature.fileText') }}</p>
        </div>
      </div>

      <div v-if="signatureImage" class="editor__field">
        <img :src="signatureImage" width="200" />
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
