<template>
  <button
    class="button"
    :class="{
      [`button--${style}`]: style,
      [`button--${size}`]: size
    }"
  >
    {{ text }}
  </button>
</template>

<script src="./index.vue.js"></script>
