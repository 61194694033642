<template>
  <div class="intro-send-illustration">
    <button
      class="
      toolbar__button
      toolbar__button--mobile
      toolbar__button--wide
      toolbar__button--pin
      "
      :title="$t('toolbar.sendDocument.title')"
    >
      <Icon class="toolbar__button-icon" name="document-send" />
      <span class="toolbar__button-text">{{ $t('toolbar.sendDocument.text') }}</span>
    </button>

    <img class="intro-send-illustration__arrow" src="@/assets/images/arrow.svg" />
  </div>
</template>

<script src="./illustration.vue.js"></script>
