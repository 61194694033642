import Icon from '@/components/atoms/icon/index.vue';

import FeatureLock from '@/components/molecules/feature-lock/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogDocumentCreate',

  props: {
    open: Boolean,
  },

  components: {
    Icon,

    FeatureLock,

    Dialog,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.currentUser;
    },

    iconInvoice() {
      return navigator.language.includes('de') ? 'document-invoice-euro-default' : 'document-invoice-dollar-default';
    },
  },
};
