import humps from 'humps';

import client from './client';

export default {
  createCheckoutSession(data) {
    return client.post('/stripe/create_checkout_session', humps.decamelizeKeys(data));
  },

  getCustomerPortalUrl() {
    return client.post('/stripe/create_customer_portal_session');
  },
};
