import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import axios from 'axios';

import i18n from '@/i18n';

import DOCUMENT_NORMS from './norms';

export default class {
  constructor(props = {}) {
    i18n.locale = props.locale || i18n.locale;

    this.norm = props.norm || DOCUMENT_NORMS.din5008A;
    this.image = props.image || {};
  }

  preloadData() {
    const requests = [];

    if (this.image && this.image.url) {
      requests.push(axios
        .get(this.image.url, { responseType: 'arraybuffer' })
        .then((image) => { this.image.data = image.data; }));
    }

    return Promise.all(requests);
  }

  buildImage() {
    if (this.image.data) {
      this.pdfDocument
        .image(this.image.data, this.norm.margins.left, this.norm.margins.top, {
          fit: [
            this.pdfDocument.page.width - 2 * this.norm.margins.left,
            this.pdfDocument.page.height - 2 * this.norm.margins.top,
          ],
          align: 'center',
          valign: 'center',
        });
    }
  }

  async render() {
    await this.preloadData();

    this.pdfDocument = new PDFDocument({ size: this.norm.size, margins: this.norm.margins });
    this.stream = this.pdfDocument.pipe(blobStream());

    this.buildImage();

    this.pdfDocument.end();

    return this.pdfDocument;
  }
}
