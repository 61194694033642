export default [
  {
    id: 'pinDomesticStandardBw',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_DOMESTIC_STANDARD_BW,
    maxPages: 3,
  },
  {
    id: 'pinDomesticStandardC',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_DOMESTIC_STANDARD_C,
    maxPages: 3,
  },
  {
    id: 'pinInternationalStandardBw',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_INTERNATIONAL_STANDARD_BW,
    maxPages: 3,
  },
  {
    id: 'pinInternationalStandardC',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_INTERNATIONAL_STANDARD_C,
    maxPages: 3,
  },

  {
    id: 'pinDomesticCompactBw',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_DOMESTIC_COMPACT_BW,
    maxPages: 9,
  },
  {
    id: 'pinDomesticCompactC',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_DOMESTIC_COMPACT_C,
    maxPages: 9,
  },
  {
    id: 'pinInternationalCompactBw',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_INTERNATIONAL_COMPACT_BW,
    maxPages: 9,
  },
  {
    id: 'pinInternationalCompactC',
    price: process.env.VUE_APP_STRIPE_PRICE_PIN_INTERNATIONAL_COMPACT_C,
    maxPages: 9,
  },
];
