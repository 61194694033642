import Cookies from 'js-cookie';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogIntro',

  props: {
    open: Boolean,
  },

  components: {
    Dialog,
  },

  methods: {
    initialize() {
      Cookies.set('launched', 1, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });
    },
  },
};
