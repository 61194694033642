<template>
  <Dialog
    :open="open"
    :buttonCancel="$t('global.cancel')"
    :buttonConfirm="$t('global.delete')"
    :buttonConfirmStyle="'danger'"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="font-family"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.documentDelete.headline') }}
          </label>
          <p
            v-if="document"
            class="editor__text"
            v-html="$t('dialog.documentDelete.description', { subject: document.subject })"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
