import { ref } from 'vue';

import i18n from '@/i18n';

import Button from '@/components/atoms/button/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogTemplates',

  props: {
    open: Boolean,
  },

  components: {
    Button,

    Dialog,
  },

  setup() {
    const newTemplateName = ref('');

    return {
      newTemplateName,
    };
  },

  computed: {
    templates() {
      return this.$store.state.templates.templates;
    },

    currentUser() {
      return this.$store.state.auth.currentUser;
    },

    currentDocument() {
      return this.$store.state.documents.currentDocument;
    },
  },

  methods: {
    getTemplates() {
      if (this.currentUser) {
        this.$store.dispatch('getTemplates');
      } else {
        this.$store.dispatch('getPreviewTemplates', i18n.global.locale.value);
      }
    },

    createTemplate() {
      const newTemplate = { ...this.currentDocument };
      delete newTemplate.id;

      this.$store.dispatch('createTemplate', {
        ...newTemplate,

        name: this.newTemplateName,
      });

      this.newTemplateName = '';
    },

    applyTemplate(template) {
      const data = { ...template };

      ['id', 'name'].forEach((key) => delete data[key]);

      const payload = { id: this.currentDocument.id, data };

      if (this.currentUser) {
        this.$store.dispatch('applyTemplate', { id: this.currentDocument.id, template: data })
          .then(() => this.$emit('updatePreview'));
      } else {
        this.$store.dispatch('updatePreviewDocument', { ...payload, locale: i18n.global.locale.value })
          .then(() => this.$emit('updatePreview'));
      }
    },

    deleteTemplate(template) {
      this.$store.dispatch('deleteTemplate', template.id);
    },
  },
};
