import * as CookieConsent from 'vanilla-cookieconsent';

const config = {
  cookie: {
    name: 'cc_cookie',
    domain: '.easyletter.app',
  },

  guiOptions: {
    consentModal: {
      layout: 'cloud inline',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onConsent: ({ cookie }) => {
    if (cookie.categories.includes('analytics')) {
      // Add Google Tag Manager script to head tag
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-47W417SQWM';
      script.setAttribute('data-cookieconsent', 'statistics');
      document.head.appendChild(script);

      const inlineScript = document.createElement('script');
      inlineScript.setAttribute('data-cookieconsent', 'statistics');
      inlineScript.textContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-47W417SQWM');
      `;

      document.head.appendChild(inlineScript);
    }
  },

  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          { name: /^_ga/ },
          { name: '_gid' },
        ],
      },

      services: {
        ga: {
          label: 'Google Analytics',
          onAccept: () => {},
          onReject: () => {},
        },
      },
    },
    ads: {},
  },

  language: {
    default: 'de',
    translations: {
      de: {
        consentModal: {
          title: 'Wir verwenden Cookies',
          description: 'Wir nutzen Cookies, um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten.',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          showPreferencesBtn: 'Individuelle Einstellungen anzeigen',
        },
        preferencesModal: {
          title: 'Cookie-Einstellungen verwalten',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          savePreferencesBtn: 'Aktuelle Auswahl akzeptieren',
          closeIconLabel: 'Schließen',
          serviceCounterLabel: 'Dienst|Dienste',
          sections: [
            {
              title: 'Ihre Datenschutzeinstellungen',
              description: 'In dieser Übersicht können Sie einige Präferenzen im Zusammenhang mit der Verarbeitung Ihrer persönlichen Informationen ausdrücken. Sie können Ihre getroffenen Entscheidungen jederzeit über den bereitgestellten Link überprüfen und ändern.',
            },
            {
              title: 'Unbedingt erforderlich',
              description: 'Diese Cookies sind für das reibungslose Funktionieren der Website unerlässlich und können nicht deaktiviert werden.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Leistung und Analyse',
              description: 'Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Alle Daten sind anonymisiert und können nicht verwendet werden, um Sie zu identifizieren.',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Cookies',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Beschreibung',
                },
                body: [
                  {
                    name: '_ga',
                    domain: window.location.hostname,
                    desc: 'Dieser Cookie wird von Google Analytics verwendet, um Benutzer zu unterscheiden und die Interaktion mit der Website zu verfolgen. Erzeugte Daten werden anonymisiert gesammelt.',
                  },
                  {
                    name: '_gid',
                    domain: window.location.hostname,
                    desc: 'Dieser Cookie wird von Google Analytics verwendet, um Benutzer zu unterscheiden. Erzeugte Daten werden anonymisiert gesammelt.',
                  },
                ],
              },
            },
          ],
        },
      },
      en: {
        consentModal: {
          title: 'We Use Cookies',
          description: 'We use cookies to give you the best possible experience on our website.',
          acceptAllBtn: 'Accept All',
          acceptNecessaryBtn: 'Reject All',
          showPreferencesBtn: 'Show Individual Preferences',
        },
        preferencesModal: {
          title: 'Manage Cookie Settings',
          acceptAllBtn: 'Accept All',
          acceptNecessaryBtn: 'Reject All',
          savePreferencesBtn: 'Accept Current Selection',
          closeIconLabel: 'Close',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Your Privacy Settings',
              description: 'In this overview, you can express some preferences related to the processing of your personal information. You can review and change your decisions at any time via the provided link.',
            },
            {
              title: 'Necessary',
              description: 'These cookies are essential for the website to function smoothly and cannot be disabled.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Performance and Analytics',
              description: 'These cookies collect information about how you use our website. All data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Cookies',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description',
                },
                body: [
                  {
                    name: '_ga',
                    domain: window.location.hostname,
                    desc: 'This cookie is used by Google Analytics to distinguish users and track interaction with the website. Generated data is collected anonymously.',
                  },
                  {
                    name: '_gid',
                    domain: window.location.hostname,
                    desc: 'This cookie is used by Google Analytics to distinguish users. Generated data is collected anonymously.',
                  },
                ],
              },
            },
          ],
        },
      },
    },
  },
};

export default {
  install: (app, pluginConfig = config) => {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$CookieConsent = CookieConsent;
    app.config.globalProperties.$CookieConsent.run(pluginConfig);
  },
};
