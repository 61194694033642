import { SvgSprite } from 'vue-svg-sprite';

/* eslint-disable */
import addPage from '@/assets/icons/add-page.svg';
import removePage from '@/assets/icons/remove-page.svg';
import bold from '@/assets/icons/bold.svg';
import italic from '@/assets/icons/italic.svg';
import underline from '@/assets/icons/underline.svg';
import fontSize from '@/assets/icons/font-size.svg';
import fontFamily from '@/assets/icons/font-family.svg';
import lockClosed from '@/assets/icons/lock-closed.svg';
import lockOpen from '@/assets/icons/lock-open.svg';
import serialLetterDefault from '@/assets/icons/serial-letter-default.svg';
import serialLetterEnabled from '@/assets/icons/serial-letter-enabled.svg';
import invoiceDollarDefault from '@/assets/icons/invoice-dollar-default.svg';
import invoiceDollarEnabled from '@/assets/icons/invoice-dollar-enabled.svg';
import invoiceEuroDefault from '@/assets/icons/invoice-euro-default.svg';
import invoiceEuroEnabled from '@/assets/icons/invoice-euro-enabled.svg';
import arrowLeft from '@/assets/icons/arrow-left.svg';
import preview from '@/assets/icons/preview.svg';
import hamburger from '@/assets/icons/hamburger.svg';
import search from '@/assets/icons/search.svg';
import documentLetterDefault from '@/assets/icons/document-letter-default.svg';
import documentLetterInverted from '@/assets/icons/document-letter-inverted.svg';
import documentSerialDefault from '@/assets/icons/document-serial-default.svg';
import documentSerialInverted from '@/assets/icons/document-serial-inverted.svg';
import documentInvoiceEuroDefault from '@/assets/icons/document-invoice-euro-default.svg';
import documentInvoiceEuroInverted from '@/assets/icons/document-invoice-euro-inverted.svg';
import documentInvoiceDollarDefault from '@/assets/icons/document-invoice-dollar-default.svg';
import documentInvoiceDollarInverted from '@/assets/icons/document-invoice-dollar-inverted.svg';
import trashDefault from '@/assets/icons/trash-default.svg';
import trashInverted from '@/assets/icons/trash-inverted.svg';
import sentSuccessDefault from '@/assets/icons/sent-success-default.svg';
import sentSuccessInverted from '@/assets/icons/sent-success-inverted.svg';
import sentPendingDefault from '@/assets/icons/sent-pending-default.svg';
import sentPendingInverted from '@/assets/icons/sent-pending-inverted.svg';
import documentNew from '@/assets/icons/document-new.svg';
import documentExport from '@/assets/icons/document-export.svg';
import documentSend from '@/assets/icons/document-send.svg';
import bookmark from '@/assets/icons/bookmark.svg';
import folder from '@/assets/icons/folder.svg';
import pencil from '@/assets/icons/pencil.svg';
import star from '@/assets/icons/star.svg';
import magicWand from '@/assets/icons/magic-wand.svg';
/* eslint-enable */

export default {
  name: 'Icon',

  components: {
    SvgSprite,
  },

  props: {
    name: String,
    size: {
      type: String,
      default: '24',
    },
  },
};
