export default {
  methods: {
    formatInvoiceData(data) {
      return data.length ? {
        rows: [
          ['*Beschreibung*', '*Menge*', '*Preis pro Einheit*', '*Gesamt*'],
          ...data
            .map((row, rowIndex) => row.slice(0, -1)
              .map((column, columnIndex) => {
                if (columnIndex === 1) {
                  return parseFloat(this.formInvoiceRows[rowIndex][1]).toLocaleString();
                }

                if (columnIndex === 2) {
                  return this.formatCurrency(parseFloat(this.formInvoiceRows[rowIndex][2]));
                }

                if (columnIndex === 3) {
                  return `*${this.invoiceRowFormattedSubtotal(rowIndex)}*`;
                }

                return column;
              })),
        ],

        subrows: this.formatSubrows(data),
      } : {};
    },

    formatSubrows(data) {
      const subtotal = data
        .map((row) => row[1] * row[2])
        .reduce((a, b) => a + b, 0);

      const subtotalTax = data
        .map((row) => (row[4] ? row[1] * row[2] * (this.formInvoiceTaxRate / 100.0) : 0))
        .reduce((a, b) => a + b, 0);

      const total = subtotal + subtotalTax;

      const subrows = [
        ['Zwischensumme', this.formatCurrency(subtotal)],
      ];

      if (subtotalTax > 0) subrows.push([`Umsatzsteuer (${this.formInvoiceTaxRate}%)`, this.formatCurrency(subtotalTax)]);

      subrows.push(['Fälliger Betrag', this.formatCurrency(total)]);

      return subrows;
    },

    invoiceRowFormattedSubtotal(index) {
      const row = this.formInvoiceRows[index];
      const amount = parseFloat(row[1]) || 0;
      const pricePerUnit = parseFloat(row[2]) || 0;

      return this.formatCurrency(amount * pricePerUnit);
    },

    formatCurrency(value) {
      const formatter = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: this.formInvoiceCurrency,
        currencyDisplay: 'code',
      });

      return formatter.format(value);
    },
  },
};
