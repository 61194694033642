export const AVAILABLE_FONTS = {
  helvetica: {
    name: 'Helvetica',
    family: 'Helvetica',
    familyBold: 'Helvetica-Bold',
  },

  times: {
    name: 'Times',
    family: 'Times-Roman',
    familyBold: 'Times-Bold',
  },

  courier: {
    name: 'Courier',
    family: 'Courier',
    familyBold: 'Courier-Bold',
  },
};

export default {
  fontSize: 9,
  fontSizeTiny: 6,
  fontSizeMax: 12,
  fontFamily: AVAILABLE_FONTS.helvetica,
  showPageNumbers: true,
  showFoldingMarkers: true,
  maxTextHeight: 200,
  letterhead: {
    padding: {
      vertical: 2.5,
      horizontal: 2.5,
    },
  },
  lineGap: 1.5,
  markupGlyphs: {
    bold: '*',
    italic: '\\',
    underline: '_',
  },
};
