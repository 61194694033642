<template>
  <div
    class="editor"
    :class="{
      'editor--requesting': requesting,
      'editor--presenting': presenting,
      'editor--previewing': showPreview
    }"
    @click.self="toggleSidebar(false); toggleDialog(null);"
  >
    <DialogIntro
      :open="currentDialog === 'dialog-intro'"
      @cancel="toggleDialog(null)"
      @confirm="$router.push({ name: 'register' })"
    />

    <DialogIntroSend
      :open="currentDialog === 'dialog-intro-send'"
      @confirm="toggleDialog('dialog-paywall')"
    />

    <DialogIntroSendIllustration
      v-if="currentDialog === 'dialog-intro-send'"
    />

    <DialogMessage
      :open="currentDialog === 'dialog-message'"
      :title="messageDialog.title"
      :text="messageDialog.text"
      @confirm="toggleDialog(null)"
    />

    <DialogPaywall
      :open="currentDialog === 'dialog-paywall'"
      @cancel="toggleDialog(null)"
    />

    <DialogUpsellPro
      :open="currentDialog === 'dialog-upsell-pro'"
      @cancel="toggleDialog(null)"
      @openPaywall="toggleDialog('dialog-paywall')"
    />

    <DialogUserSettings
      v-if="currentUser"
      :open="currentDialog === 'dialog-user-settings'"
      @confirm="toggleDialog(null)"
      @openPaywall="toggleDialog('dialog-paywall')"
    />

    <DialogDocumentCreate
      :open="currentDialog === 'dialog-document-create'"
      @createDocument="documentType => {
        createDocument(documentType);
        toggleDialog(null);
      }"
      @cancel="toggleDialog(null)"
      @openPaywall="toggleDialog('dialog-paywall')"
    />

    <DialogDocumentDelete
      :open="currentDialog === 'dialog-remove-document'"
      :document="currentDialogDocument"
      @cancel="toggleDialog(null); currentDialogDocument = null;"
      @confirm="
        deleteDocument(currentDialogDocument);
        toggleDialog(null);
        currentDialogDocument = null;
      "
    />

    <DialogTemplates
      v-if="currentDocument"
      :open="currentDialog === 'dialog-templates'"
      @updatePreview="updatePreviewDocument"
      @confirm="toggleDialog(null)"
    />

    <DialogManagePlaceholders
      v-if="currentDocument"
      :open="currentDialog === 'dialog-manage-placeholders'"
      @confirm="toggleDialog(null)"
    />

    <DialogFontFamily
      v-if="currentDocument"
      v-model="formFontFamily"
      :open="currentDialog === 'dialog-font-family'"
      @change="updateFontFamily"
      @mouseup="setSelection(null)"
      @confirm="toggleDialog(null)"
    />

    <DialogBackground
      v-if="currentDocument"
      v-model="formShowBackground"
      :open="currentDialog === 'dialog-background'"
      :backgroundImage="formBackgroundImage && `${formBackgroundImage}?t=${new Date().getTime()}`"
      @updatePreview="updatePreviewDocument"
      @change="updateBackgroundSettings"
      @mouseup="setSelection(null)"
      @confirm="toggleDialog(null)"
    />

    <DialogLetterhead
      v-if="currentDocument"
      v-model="formShowLetterhead"
      :open="currentDialog === 'dialog-letterhead'"
      :letterheadAlignment="formLetterheadAlignment"
      :letterheadImage="formLetterheadImage && `${formLetterheadImage}?t=${new Date().getTime()}`"
      @updatePreview="updatePreviewDocument"
      @change="$event => {
        if ($event && ['0', '1', '2'].includes($event.target.value))
          formLetterheadAlignment = parseInt($event.target.value, 10);

        updateLetterheadSettings();
      }"
      @mouseup="setSelection(null)"
      @confirm="toggleDialog(null)"
    />

    <DialogSignature
      v-if="currentDocument"
      v-model="formShowSignature"
      :open="currentDialog === 'dialog-signature'"
      :signatureImage="formSignatureImage && `${formSignatureImage}?t=${new Date().getTime()}`"
      @updatePreview="updatePreviewDocument"
      @change="updateSignatureSettings"
      @mouseup="setSelection(null)"
      @confirm="toggleDialog(null)"
    />

    <DialogExportSerial
      v-if="currentDocument"
      :open="currentDialog === 'dialog-export-serial'"
      :serialPlaceholders="formSerialPlaceholders"
      :serialImportFile="formSerialImportFile"
      :progress="exportProgress"
      @change="updateSerialImportFile($event)"
      @cancel="toggleDialog(null)"
      @confirm="exportSerial"
    />

    <DialogSend
      :source="previewSourceSend"
      :sourcePageRange="previewSourcePageRange"
      :open="currentDialog === 'dialog-send'"
      @cancel="toggleDialog(null)"
      @confirm="toggleDialog(null)"
      @openAttachments="toggleDialog('dialog-attachments')"
    />

    <DialogAttachments
      v-if="currentUser"
      :open="currentDialog === 'dialog-attachments'"
      @confirm="toggleDialog('dialog-send')"
    />

    <DialogCompletion
      v-if="currentUser && currentDocument"
      :open="currentDialog === 'dialog-completion'"
      @confirm="toggleDialog(null)"
      @applySuggestion="
        replaceFormText($event);
        toggleDialog(null);
      "
    />

    <div class="editor__toolbar">
      <Toolbar
        @exportDocument="exportDocument"
        @sendDocument="sendDocument"
        @createDocument="toggleDialog('dialog-document-create')"
        @manageTemplates="toggleDialog('dialog-templates')"
      />
    </div>

    <div
      class="editor__sidebar"
      :class="{ 'editor__sidebar--open': showSidebar }"
    >
      <Sidebar
        @hide="showSidebar = false"
        @showUserSettings="toggleDialog('dialog-user-settings')"
        @selectedDocument="updatePreviewDocument"
        @removeDocument="document => {
          currentDialogDocument = document;
          toggleDialog('dialog-remove-document');
        }"
      />
    </div>

    <div class="editor__inner">
      <div v-if="currentDocument" class="editor__column editor__column--form">
        <div class="editor__menu">
            <Menu
              :formDocumentType="formDocumentType"
              :formFontSize="formFontSize"
              :formSender="formSender"
              :formRecipient="formRecipient"
              :formMemo="formMemo"
              :formInfo="formInfo"
              :formText="formText"
              :formFooter="formFooter"
              :formLocked="formLocked"
              :formSerialPlaceholders="formSerialPlaceholders"
              :formInvoiceRows="formInvoiceRows"
              :currentSelection="currentSelection"
              :currentFocus="currentFocus"
              @managePlaceholders="toggleDialog('dialog-manage-placeholders')"
              @updateFontSize="updateFontSize($event)"
              @updateFontFamily="toggleDialog('dialog-font-family')"
              @updateText="formText[$event.index] = $event.value"
              @updateContent="updatePreviewDocument().then(() => { persistDocument(); })"
              @updateFooter="formFooter[$event.index] = $event.value"
              @updateInvoiceDescription="formInvoiceRows[$event.index][0] = $event.value"
              @updateField="this[$event.id] = $event.value"
              @toggleLock="toggleLock"
              @toggleSerial="toggleSerial"
              @toggleInvoice="toggleInvoice"
              @openPaywall="toggleDialog('dialog-paywall')"
            />
          </div>

        <div class="editor__form">
          <div class="editor__section editor__section--multiple">
              <div
                class="editor__fieldset"
                :class="{ 'editor__fieldset--disabled': formLocked }"
              >
                <label
                  for="formSender"
                  class="editor__fieldset-label"
                >{{ $t('editor.form.sender') }}</label>
                <textarea
                  id="formSender"
                  class="editor__textarea"
                  v-model="formSender"
                  :placeholder="$t('editor.form.senderPlaceholder')"
                  :disabled="
                    (isFormSerial && !currentUser) ||
                    (isFormInvoice && !currentUser) ||
                    formLocked
                  "
                  @input="currentUser && updateAll()"
                  @keyup="setSelection($event)"
                  @mouseup="setSelection($event)"
                  @focus="setFocus($event)"
                  @blur="setFocus($event)"
                />
              </div>

              <div
                class="editor__fieldset"
                :class="{ 'editor__fieldset--disabled': formLocked }"
              >
                <label
                  for="formRecipient"
                  class="editor__fieldset-label"
                >{{ $t('editor.form.recipient') }}</label>
                <textarea
                  id="formRecipient"
                  class="editor__textarea"
                  v-model="formRecipient"
                  :placeholder="$t('editor.form.recipientPlaceholder')"
                  :disabled="
                    (isFormSerial && !currentUser) ||
                    (isFormInvoice && !currentUser) ||
                    formLocked
                  "
                  @input="currentUser && updateAll()"
                  @keyup="setSelection($event)"
                  @mouseup="setSelection($event)"
                  @focus="setFocus($event)"
                  @blur="setFocus($event)"
                />
              </div>
            </div>

          <div class="editor__section editor__section--multiple">
            <div
              class="editor__fieldset"
              :class="{ 'editor__fieldset--disabled': formLocked }"
            >
              <label
                for="formMemo"
                class="editor__fieldset-label"
              >{{ $t('editor.form.memo') }}</label>
              <textarea
                id="formMemo"
                class="editor__textarea"
                v-model="formMemo"
                :placeholder="$t('editor.form.memoPlaceholder')"
                :disabled="formLocked"
                @input="updateAll"
                @keyup="setSelection($event)"
                @mouseup="setSelection($event)"
                @focus="setFocus($event)"
                @blur="setFocus($event)"
              />
            </div>

            <div
              class="editor__fieldset"
              :class="{ 'editor__fieldset--disabled': formLocked }"
            >
              <label
                for="formInfo"
                class="editor__fieldset-label"
              >{{ $t('editor.form.info') }}</label>
              <textarea
                id="formInfo"
                class="editor__textarea"
                v-model="formInfo"
                :placeholder="$t('editor.form.infoPlaceholder')"
                :disabled="formLocked"
                @input="updateAll"
                @keyup="setSelection($event)"
                @mouseup="setSelection($event)"
                @focus="setFocus($event)"
                @blur="setFocus($event)"
              />
            </div>
          </div>

          <div
            class="editor__section"
            :class="{ 'editor__section--multiple': isFormInvoice }"
          >
            <div
              class="editor__fieldset editor__fieldset--horizontal"
              :class="{ 'editor__fieldset--disabled': formLocked }"
            >
              <label
                for="formDate"
                class="editor__fieldset-label"
              >{{ $t('editor.form.date') }}</label>
              <input
                id="formDate"
                class="editor__input editor__input--date"
                type="date"
                v-model="formDate"
                :disabled="formLocked"
                @keyup="setSelection(null)"
                @mouseup="setSelection(null)"
                @change="updateAll"
              />
            </div>

            <div
              v-if="isFormInvoice"
              class="editor__fieldset editor__fieldset--horizontal"
              :class="{ 'editor__fieldset--disabled': formLocked }"
            >
              <label
                for="formInvoiceId"
                class="editor__fieldset-label"
              >{{ $t('editor.form.invoiceId') }}</label>
              <input
                id="formInvoiceId"
                class="editor__input editor__input--inline"
                v-model="formInvoiceId"
                :disabled="formLocked"
                @keyup="setSelection(null)"
                @mouseup="setSelection(null)"
                @change="updateAll"
              />
            </div>
          </div>

          <div
            v-if="isFormInvoice"
            class="editor__section editor__section--multiple"
          >
            <div
              class="editor__fieldset editor__fieldset--horizontal"
              :class="{ 'editor__fieldset--disabled': formLocked }"
            >
              <label
                for="formInvoiceTaxRate"
                class="editor__fieldset-label"
              >{{ $t('editor.form.taxRate') }}</label>
              <div>
                <input
                  id="formInvoiceTaxRate"
                  class="editor__input editor__input--inline editor__input--number-reset"
                  type="number"
                  placeholder="0"
                  v-model="formInvoiceTaxRate"
                  :disabled="formLocked"
                  @keyup="setSelection(null)"
                  @mouseup="setSelection(null)"
                  @change="updateAll"
                />
                <span>%</span>
              </div>
            </div>

            <div
              class="editor__fieldset editor__fieldset--horizontal"
              :class="{ 'editor__fieldset--disabled': formLocked }"
            >
              <label
                for="formInvoiceCurrency"
                class="editor__fieldset-label"
              >{{ $t('editor.form.currency') }}</label>
              <div>
                <select
                  id="formInvoiceCurrency"
                  class="editor__select editor__input--inline"
                  v-model="formInvoiceCurrency"
                  :disabled="formLocked"
                  @mouseup="setSelection(null)"
                  @change="updateInvoiceCurrency"
                >
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
          </div>

          <div class="editor__section">
              <div
                class="editor__fieldset"
                :class="{ 'editor__fieldset--disabled': formLocked }"
              >
                <label
                  for="formSubject"
                  class="u-visually-hidden"
                >{{ $t('editor.form.subject') }}</label>
                <input
                  id="formSubject"
                  class="editor__input editor__input--subject"
                  v-model="formSubject"
                  :placeholder="$t('editor.form.subjectPlaceholder')"
                  :disabled="formLocked"
                  @input="updateAll"
                  @keyup="setSelection(null)"
                  @mouseup="setSelection(null)"
                />
              </div>
            </div>

          <template v-if="isFormInvoice">
            <hr class="editor__divider" />

            <div class="editor__section">
              <div class="editor__field editor__field--table">
                <table class="editor__table editor__table--invoice">
                  <thead>
                    <tr>
                      <th
                        class="editor__table-headline"
                        v-for="(column, columnIndex) in formInvoiceColumns"
                        :key="columnIndex"
                      >
                        <label
                          v-if="columnIndex > 0"
                          class="editor__fieldset-label"
                        >{{ $t(`editor.form.invoice.${column}`) }}</label>
                      </th>
                    </tr>
                  </thead>

                  <transition-group name="row" tag="tbody">
                    <tr
                      v-for="(row, rowIndex) in formInvoiceRows"
                      :key="rowIndex"
                    >
                      <td
                        class="editor__table-datum"
                        v-for="column, columnIndex in formInvoiceColumns"
                        :key="columnIndex"
                      >
                        <div
                          v-if="columnIndex === 0"
                        >
                          <Button
                            text="✕"
                            :size="'small'"
                            :style="'outline-danger'"
                            :disabled="formLocked"
                            @click="removeInvoiceRow(rowIndex)"
                          />
                        </div>
                        <div
                          v-if="columnIndex === 1"
                          class="editor__fieldset"
                          :class="{ 'editor__fieldset--disabled': formLocked }"
                        >
                          <textarea
                            :id="`formInvoiceRowDescription-${rowIndex}`"
                            class="editor__textarea"
                            :value="row[columnIndex - 1]"
                            :disabled="formLocked"
                            @input="
                              updateInvoiceField($event.target.value, rowIndex, columnIndex - 1)
                            "
                            @keyup="setSelection($event)"
                            @mouseup="setSelection($event)"
                          />
                        </div>

                        <div
                          v-if="[2, 3].includes(columnIndex)"
                          class="editor__fieldset"
                          :class="{ 'editor__fieldset--disabled': formLocked }"
                        >
                          <input
                            class="editor__input"
                            :value="row[columnIndex - 1]"
                            type="number"
                            step=".01"
                            :disabled="formLocked"
                            @change="updateInvoiceField(
                              $event.target.value, rowIndex, columnIndex - 1)"
                            @mouseup="setSelection(null)"
                          />
                        </div>

                        <div
                          v-if="columnIndex === 4"
                          class="editor__fieldset"
                          :class="{ 'editor__fieldset--disabled': formLocked }"
                        >
                          <input
                            class="editor__input editor__input--total"
                            :value="invoiceRowFormattedSubtotal(rowIndex)"
                            disabled
                          />
                        </div>

                        <div
                          v-if="columnIndex === 5"
                        >
                          <input
                            type="checkbox"
                            class="editor__checkbox"
                            :checked="row[columnIndex - 1]"
                            :disabled="formLocked"
                            @change="updateInvoiceField(
                              $event.target.checked, rowIndex, columnIndex - 1)"
                            @mouseup="setSelection(null)"
                          >
                        </div>
                      </td>
                    </tr>
                  </transition-group>
                </table>
              </div>

              <footer class="editor__field editor__field--right">
                <Button
                  :text="$t('global.addRow')"
                  :size="'small'"
                  :disabled="formLocked"
                  @click="addInvoiceRow"
                />
              </footer>
            </div>

            <hr class="editor__divider" />
          </template>

          <div class="editor__section">
            <transition-group name="row">
              <div
                class="editor__page"
                v-for="(text, index) in formText"
                :key="index"
              >
                <div
                  class="editor__fieldset"
                  :class="{ 'editor__fieldset--disabled': formLocked }"
                >
                  <textarea
                    :id="`formText-${index}`"
                    class="editor__textarea"
                    :class="{ 'editor__textarea--actions': currentUser && index === 0 }"
                    :value="text"
                    :placeholder="$t('editor.form.textPlaceholder')"
                    :disabled="formLocked"
                    @input="updateFormText($event, index)"
                    @keyup="setSelection($event)"
                    @mouseup="setSelection($event)"
                    @focus="setFocus($event)"
                    @blur="setFocus($event)"
                  />

                  <button
                    v-if="currentUser && index === 0"
                    class="editor__action"
                    @click="openDialogCompletion"
                  >
                    <Icon name="magic-wand" />
                  </button>

                  <label
                    :for="`formText-${index}`"
                    class="editor__fieldset-label editor__fieldset-label--small"
                  >
                    <Button
                      v-if="index > 0"
                      :text="$t('global.remove')"
                      :size="'small'"
                      :style="'outline-danger'"
                      :disabled="formLocked"
                      @click="removePage(index)"
                    />
                    {{ $t('global.page') }} {{ index + 1 }}
                  </label>
                </div>
              </div>
            </transition-group>
          </div>

          <div class="editor__section editor__section--multiple">
            <transition-group name="column">
              <div
                class="editor__fieldset"
                v-for="(text, index) in formFooter"
                :key="index"
              >
                <label
                  :for="`formFooter-${index}`"
                  class="editor__fieldset-label"
                >{{ $t('editor.form.footer') }} {{ index + 1 }}</label>
                <textarea
                  :id="`formFooter-${index}`"
                  class="editor__textarea"
                  :value="text"
                  :placeholder="$t('editor.form.footerPlaceholder')"
                  :disabled="formLocked"
                  @input="updateFormFooter($event, index)"
                  @keyup="setSelection(formFooter.length > 1 ? $event : null)"
                  @mouseup="setSelection(formFooter.length > 1 ? $event : null)"
                  @focus="setFocus($event)"
                  @blur="setFocus($event)"
                />
                <Button
                  v-if="index === 0"
                  :text="$t('global.addColumn')"
                  :size="'small'"
                  :disabled="formFooter.length === 4 || formLocked"
                  @click="addFooterColumn"
                />
                <Button
                  v-if="index > 0"
                  :text="$t('global.remove')"
                  :size="'small'"
                  :style="'outline-danger'"
                  :disabled="formLocked"
                  @click="removeFooterColumn(index)"
                />
              </div>
            </transition-group>
          </div>

          <h2 class="editor__headline">{{ $t('editor.settings') }}</h2>

          <hr class="editor__divider" />

          <div class="editor__section editor__section--multiple">
            <FeatureLock
              v-if="currentUser && currentUser.subscription.type < 1"
              @click.stop="toggleDialog('dialog-paywall')"
            />

            <div class="editor__field">
              <div class="editor__section-desc">
                <label
                  for="edit-background"
                  class="editor__fieldset-label"
                >
                  {{ $t('editor.backgroundHeadline') }}
                </label>
                <p class="editor__text">{{ $t('editor.backgroundDescription') }}</p>
              </div>
              <Button
                id="edit-background"
                :text="$t('editor.edit')"
                :disabled="
                  !currentUser ||
                  currentUser && currentUser.subscription.type < 1 ||
                  formLocked
                "
                @click="toggleDialog('dialog-background')"
              />
            </div>
          </div>

          <div class="editor__section editor__section--multiple">
            <div class="editor__field">
              <div class="editor__section-desc">
                <label
                  for="edit-letterhead"
                  class="editor__fieldset-label"
                >
                  {{ $t('editor.letterheadHeadline') }}
                </label>
                <p class="editor__text">{{ $t('editor.letterheadDescription') }}</p>
              </div>
              <Button
                id="edit-letterhead"
                :text="$t('editor.edit')"
                :disabled="!currentUser || formLocked"
                @click="toggleDialog('dialog-letterhead')"
              />
            </div>
          </div>

          <div class="editor__section editor__section--multiple">
            <FeatureLock
              v-if="currentUser && currentUser.subscription.type < 1"
              @click.stop="toggleDialog('dialog-paywall')"
            />

            <div class="editor__field">
              <div class="editor__section-desc">
                <label
                  for="edit-signature"
                  class="editor__fieldset-label"
                >
                  {{ $t('editor.signatureHeadline') }}
                </label>
                <p class="editor__text">{{ $t('editor.signatureDescription') }}</p>
              </div>
              <Button
                id="edit-signature"
                :text="$t('editor.edit')"
                :disabled="
                  !currentUser ||
                  currentUser && currentUser.subscription.type < 1 ||
                  formLocked
                "
                @click="toggleDialog('dialog-signature')"
              />
            </div>
          </div>

          <div class="editor__section editor__section--multiple">
            <div class="editor__field editor__field--separated">
              <div class="editor__section-desc">
                <label
                  for="folding-markers"
                  class="editor__fieldset-label"
                >
                  {{ $t('editor.foldingMarkersHeadline') }}
                </label>
                <p class="editor__text">{{ $t('editor.foldingMarkersDescription') }}</p>
              </div>
              <input
                id="folding-markers"
                type="checkbox"
                class="editor__checkbox"
                v-model="formShowFoldingMarkers"
                :disabled="formLocked"
                @mouseup="setSelection(null)"
                @change="updateFoldingMarkers"
              />
            </div>
          </div>

          <div class="editor__section editor__section--multiple">
            <div class="editor__field editor__field--separated">
              <div class="editor__section-desc">
                <label
                  for="page-numbers"
                  class="editor__fieldset-label"
                >
                  {{ $t('editor.pageNumbersHeadline') }}
                </label>
                <p class="editor__text">{{ $t('editor.pageNumbersDescription') }}</p>
              </div>
              <input
                id="page-numbers"
                type="checkbox"
                class="editor__checkbox"
                v-model="formShowPageNumbers"
                :disabled="formLocked"
                @mouseup="setSelection(null)"
                @change="updatePageNumbers"
              />
            </div>
          </div>

          <div class="editor__section editor__section--multiple">
            <div class="editor__field editor__field--separated">
              <div class="editor__section-desc">
                <label
                  for="form-type"
                  class="editor__fieldset-label"
                >
                  {{ $t('editor.formTypeHeadline') }}
                </label>
                <p class="editor__text">{{ $t('editor.formTypeDescription') }}</p>
              </div>
              <select
                id="form-type"
                class="editor__select"
                v-model="formNorm"
                :disabled="formLocked"
                @mouseup="setSelection(null)"
                @change="updateNorm"
              >
                <option value="din5008A">{{ $t('norms.din5008A') }}</option>
                <option value="din5008B">{{ $t('norms.din5008B') }}</option>
                <option value="usLetter">{{ $t('norms.usLetter') }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentDocument" class="editor__column editor__column--preview">
        <Preview
          :source="previewSource"
          :fontFamily="AVAILABLE_FONTS[formFontFamily].name"
          :fontSize="formFontSize"
          :norm="formNorm"
          :rendering="previewRendering"
          :updatedAt="new Date(currentDocument.updatedAt * 1000)"
          :show="showPreview"
          @toggle="showPreview = !showPreview"
        />
      </div>

      <PageManager v-if="!currentDocument" />
    </div>
  </div>
</template>

<script src="./index.vue.js"></script>
