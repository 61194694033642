import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import axios from 'axios';

import i18n from '@/i18n';

import {
  mmToPx, tokenizeText, renderText, renderTableFooter, renderTableInvoice, tablePages,
} from './utils';

import DOCUMENT_DEFAULTS from './defaults';
import DOCUMENT_NORMS from './norms';

import './fonts';

export default class {
  constructor(props = {}) {
    i18n.locale = props.locale || i18n.locale;

    this.norm = props.norm || DOCUMENT_NORMS.din5008A;
    this.fontFamily = props.fontFamily || DOCUMENT_DEFAULTS.fontFamily;
    this.fontFamilyBold = props.fontFamilyBold || DOCUMENT_DEFAULTS.fontFamilyBold;
    this.fontSize = props.fontSize || DOCUMENT_DEFAULTS.fontSize;

    this.sender = props.sender;
    this.memo = props.memo;
    this.recipient = props.recipient;
    this.subject = props.subject;
    this.date = props.date;
    this.text = props.text;
    this.footer = props.footer;

    this.showPageNumbers = props.showPageNumbers || DOCUMENT_DEFAULTS.showPageNumbers;
    this.showFoldingMarkers = props.showFoldingMarkers || DOCUMENT_DEFAULTS.showFoldingMarkers;

    this.background = props.background || {};
    this.letterhead = props.letterhead || {};
    this.signature = props.signature || {};

    this.serialData = props.serialData || {};

    this.invoiceId = props.invoiceId;
    this.invoiceData = props.invoiceData || {};
  }

  preloadData() {
    const requests = [];

    if (this.background && this.background.url && this.background.show) {
      requests.push(axios
        .get(this.background.url, { responseType: 'arraybuffer' })
        .then((image) => { this.background.data = image.data; }));
    }

    if (this.letterhead && this.letterhead.url && this.letterhead.show) {
      requests.push(axios
        .get(this.letterhead.url, { responseType: 'arraybuffer' })
        .then((image) => { this.letterhead.data = image.data; }));
    }

    if (this.signature && this.signature.url && this.signature.show) {
      requests.push(axios
        .get(this.signature.url, { responseType: 'arraybuffer' })
        .then((image) => { this.signature.data = image.data; }));
    }

    return Promise.all(requests);
  }

  buildBackground() {
    if (this.background.data) {
      this.pdfDocument
        .image(this.background.data, 0, 0, {
          fit: [this.pdfDocument.page.width, this.pdfDocument.page.height],
          align: 'center',
          valign: 'center',
        });
    }
  }

  buildLetterhead() {
    if (this.letterhead.data) {
      this.pdfDocument
        .image(
          this.letterhead.data,
          mmToPx(DOCUMENT_DEFAULTS.letterhead.padding.horizontal),
          mmToPx(DOCUMENT_DEFAULTS.letterhead.padding.vertical), {
            fit: [
              this.pdfDocument.page.width
              - mmToPx(DOCUMENT_DEFAULTS.letterhead.padding.horizontal) * 2,
              this.norm.letterhead.height
              - mmToPx(DOCUMENT_DEFAULTS.letterhead.padding.vertical) * 2,
            ],
            align: this.letterhead.align,
            valign: 'center',
          },
        );
    }
  }

  buildSignature() {
    const scale = this.fontSize / DOCUMENT_DEFAULTS.fontSize;

    const position = {
      x: this.norm.margins.left,
      y: this.contentOffset.y + this.pdfDocument.heightOfString(this.text.slice(-1), {
        height: mmToPx(DOCUMENT_DEFAULTS.maxTextHeight),
        width: this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
        lineGap: DOCUMENT_DEFAULTS.lineGap,
      }) + mmToPx(10) * scale,
    };

    if (this.signature.data) {
      this.pdfDocument
        .image(
          this.signature.data,
          position.x,
          position.y, {
            fit: [mmToPx(35) * scale, mmToPx(20)],
          },
        );
    }
  }

  buildAddress() {
    // Render small return address field
    if (['pin', 'din5008A'].includes(this.norm.id)) {
      const fontSize = this.norm.id === 'pin' ? DOCUMENT_DEFAULTS.fontSizeTiny : this.fontSize - 1;

      const textBaseReturn = this.pdfDocument
        .fillColor('gray')
        .fontSize(fontSize)
        .text('', this.norm.margins.left, this.norm.letterhead.height + (this.norm.id === 'pin' ? 0 : mmToPx(8)))
        .text('', {
          height: fontSize,
          width: this.norm.address.width,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued: true,
        });

      renderText(
        this.serialData,
        tokenizeText((this.sender || '').replace(/\n/g, ' · ')),
        textBaseReturn, this.fontFamily, this.fontFamilyBold,
      );
    }

    // Render sender field with memo depending on document type
    const textBaseSender = this.pdfDocument
      .fillColor('black')
      .fontSize(this.norm.id === 'pin' ? DOCUMENT_DEFAULTS.fontSize : this.fontSize)
      .text('', this.norm.margins.left, this.norm.letterhead.height + (this.norm.id === 'pin' ? 0 : mmToPx(8)) + this.norm.address.height)
      .text('', {
        height: this.norm.memo.height,
        width: this.norm.address.width,
        lineGap: DOCUMENT_DEFAULTS.lineGap,
        continued: true,
      });

    renderText(
      this.serialData,
      tokenizeText(['pin', 'din5008A'].includes(this.norm.id) ? this.memo : `${this.sender}\n${this.memo}`),
      textBaseSender, this.fontFamily, this.fontFamilyBold,
    );

    // Render recipient field
    const textBaseRecipient = this.pdfDocument
      .fillColor('black')
      .fontSize(this.norm.id === 'pin' ? DOCUMENT_DEFAULTS.fontSize : this.fontSize)
      .text('', this.norm.margins.left, this.norm.letterhead.height + (this.norm.id === 'pin' ? 0 : mmToPx(8)) + this.norm.address.height + this.norm.memo.height)
      .text('', {
        height: this.norm.recipient.height,
        width: this.norm.address.width,
        lineGap: DOCUMENT_DEFAULTS.lineGap,
        continued: true,
      });

    renderText(
      this.serialData,
      tokenizeText(this.recipient),
      textBaseRecipient, this.fontFamily, this.fontFamilyBold,
    );
  }

  buildInfo() {
    let text = '';

    if (this.date) {
      text += `${i18n.global.t('document.date')}: ${new Date(this.date).toLocaleDateString(i18n.locale, { year: 'numeric', month: 'long', day: 'numeric' })}\n\n`;
    }

    if (this.invoiceId) {
      text += `\n*${i18n.global.t('document.invoiceId')}*: ${this.invoiceId}\n\n`;
    }

    text += this.info || '';

    const textBase = this.pdfDocument
      .fillColor('black')
      .fontSize(this.fontSize)
      .text('', mmToPx(125), this.norm.letterhead.height + this.norm.info.margin.top)
      .text('', {
        height: mmToPx(70),
        width: mmToPx(75),
        lineGap: DOCUMENT_DEFAULTS.lineGap,
        continued: true,
      });

    renderText(
      this.serialData,
      tokenizeText(text),
      textBase, this.fontFamily, this.fontFamilyBold,
    );
  }

  buildSubject() {
    const fontSize = this.subject.length > 50 ? 11 : 14;
    const width = 0.75 * this.pdfDocument.page.width
    - this.norm.margins.left
    - this.norm.margins.right;

    this.contentOffset = {
      y: this.norm.mainContent.top === 0
        ? this.norm.letterhead.height + mmToPx(70 + 5 + 8.46) : this.norm.mainContent.top,
    };

    this.pdfDocument
      .fillColor('black')
      .font(this.fontFamilyBold)
      .fontSize(fontSize)
      .text('', this.norm.margins.left, this.contentOffset.y)
      .text(this.subject, {
        width,
        lineGap: DOCUMENT_DEFAULTS.lineGap,
      });

    const height = this.pdfDocument.heightOfString(this.subject, { width });

    this.contentOffset.y += this.subject.length > 0 ? height + 2 * fontSize : 0;
  }

  buildText(text, currentPage) {
    if (currentPage > 0) {
      this.contentOffset.y = this.norm.letterhead.height;
    }

    const textBase = this.pdfDocument
      .fillColor('black')
      .font(this.fontFamily)
      .fontSize(this.fontSize)
      .text('', this.norm.margins.left, this.contentOffset.y)
      .text('', {
        height: mmToPx(DOCUMENT_DEFAULTS.maxTextHeight),
        width: this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
        lineGap: DOCUMENT_DEFAULTS.lineGap,
        continued: true,
      });

    renderText(
      this.serialData,
      tokenizeText(text),
      textBase, this.fontFamily, this.fontFamilyBold,
    );
  }

  buildFooter() {
    this.footerPosition = {
      x: this.norm.margins.left,
      y: this.pdfDocument.page.height - mmToPx(15) - this.pdfDocument.heightOfString(
        [...this.footer].sort((a, b) => b.length - a.length)[0], {
          width: this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
        },
      ),
    };

    this.pdfDocument
      .fillColor('gray')
      .font(this.fontFamily)
      .fontSize(this.fontSize - 1);

    if (this.footer.length > 1) {
      renderTableFooter(
        this.serialData,
        this.pdfDocument,
        this.footerPosition,
        [this.footer],
        this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
        this.fontFamily,
        this.fontFamilyBold,
      );
    } else {
      this.pdfDocument
        .text('', this.norm.margins.left, this.footerPosition.y)
        .text(this.footer[0], {
          align: 'center',
          width: this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
        });
    }
  }

  buildPageNumber(current, total) {
    this.pdfDocument
      .fillColor('black')
      .font(this.fontFamily)
      .fontSize(this.fontSize - 1)
      .text('', this.norm.margins.left, this.footerPosition.y - mmToPx(4.23))
      .text(i18n.global.t('document.page', [current + 1, total]), {
        align: 'right',
        width: this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
      });
  }

  buildFoldingMarkers() {
    const inset = mmToPx(5);
    const width = mmToPx(5);
    const height = mmToPx(0.25);
    const punchMarkWidth = mmToPx(7);

    this.pdfDocument
      .rect(inset, this.norm.foldingMarkers.top.y - height / 2, width, height)
      .fill('black');

    this.pdfDocument
      .rect(inset, this.pdfDocument.page.height / 2 - height / 2, punchMarkWidth, height)
      .fill('black');

    this.pdfDocument
      .rect(inset, this.norm.foldingMarkers.bottom.y - height / 2, width, height)
      .fill('black');
  }

  buildBill(data, currentPage) {
    if (currentPage > 0) {
      this.contentOffset.y = this.norm.letterhead.height;
    }

    this.billPosition = {
      x: this.norm.margins.left,
      y: this.contentOffset.y,
    };

    this.pdfDocument
      .fillColor('black')
      .font(this.fontFamily)
      .fontSize(this.fontSize);

    renderTableInvoice(
      this.pdfDocument,
      this.billPosition,
      data,
      this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
      this.fontSize,
      this.fontFamily,
      this.fontFamilyBold,
    );
  }

  buildLetter() {
    this.text.forEach((text, page) => {
      if (page > 0) this.pdfDocument.addPage();

      this.buildBackground();

      if (page === 0) {
        this.buildLetterhead();
        this.buildAddress();
        this.buildInfo();
        this.buildSubject();
      }

      this.buildText(text, page);
      this.buildFooter();

      if (this.showPageNumbers) this.buildPageNumber(page, this.text.length);
      if (this.showFoldingMarkers) this.buildFoldingMarkers();

      if (page === this.text.length - 1) {
        this.buildSignature();
      }
    });
  }

  buildInvoice() {
    const invoicePages = tablePages(
      this.pdfDocument,
      this.invoiceData,
      this.pdfDocument.page.width - this.norm.margins.left - this.norm.margins.right,
    );

    const pages = invoicePages.pagedRows.map((rows, index) => ({
      rows,

      subrows: index === invoicePages.pagedRows.length - 1 ? this.invoiceData.subrows : [],
    }));

    const textFitsOnLastInvoicePage = invoicePages.tmpHeight + mmToPx(150)
      < mmToPx(DOCUMENT_DEFAULTS.maxTextHeight);

    const totalPages = pages.length + (textFitsOnLastInvoicePage
      ? this.text.length - 1 : this.text.length);

    pages.forEach((data, page) => {
      if (page > 0) this.pdfDocument.addPage();

      this.buildBackground();

      if (page === 0) {
        this.buildLetterhead();
        this.buildAddress();
        this.buildInfo();
        this.buildSubject();
      }

      this.buildBill(data, page);
      this.buildFooter();

      if (this.showPageNumbers) this.buildPageNumber(page, totalPages);
      if (this.showFoldingMarkers) this.buildFoldingMarkers();

      if (page === pages.length - 1) {
        // Merge first page on last invoice page, if space
        if (textFitsOnLastInvoicePage) {
          this.contentOffset.y += invoicePages.tmpHeight + mmToPx(60);

          this.buildText(this.text[0] || '', 0);

          if (this.text.length === 1) this.buildSignature();

          this.text.slice(1).forEach((text, additionalPage) => {
            this.pdfDocument.addPage();

            this.buildBackground();

            this.buildText(text, additionalPage + 1);
            this.buildFooter();

            if (this.showPageNumbers) this.buildPageNumber(page + additionalPage + 1, totalPages);
            if (this.showFoldingMarkers) this.buildFoldingMarkers();

            if (additionalPage === this.text.length - 2) {
              this.buildSignature();
            }
          });
        // Append pages
        } else {
          this.text.forEach((text, additionalPage) => {
            this.pdfDocument.addPage();

            this.buildBackground();

            this.buildText(text, additionalPage + 1);
            this.buildFooter();

            if (this.showPageNumbers) this.buildPageNumber(page + additionalPage + 1, totalPages);
            if (this.showFoldingMarkers) this.buildFoldingMarkers();

            if (additionalPage === this.text.length - 1) {
              this.buildSignature();
            }
          });
        }
      }
    });
  }

  async render() {
    await this.preloadData();

    this.pdfDocument = new PDFDocument({ size: this.norm.size, margins: this.norm.margins });
    this.stream = this.pdfDocument.pipe(blobStream());

    this.pdfDocument
      .font(this.fontFamily)
      .fontSize(this.fontSize);

    if (Object.keys(this.invoiceData).length) {
      this.buildInvoice();
    } else {
      this.buildLetter();
    }

    this.pdfDocument.end();

    return this.pdfDocument;
  }
}
