import auth from './auth';
import constants from './constants';
import documents from './documents';
import jobs from './jobs';
import misc from './misc';
import stripe from './stripe';
import templates from './templates';
import attachments from './attachments';

export default {
  auth,
  constants,
  documents,
  jobs,
  misc,
  stripe,
  templates,
  attachments,
};
