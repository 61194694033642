import axios from 'axios';
import Cookies from 'js-cookie';

import authMixin from '@/mixins/auth';

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

client.interceptors.request.use(
  (config) => {
    const localConfig = config;
    const token = Cookies.get('jwt-access');

    if (token) {
      localConfig.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },

  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (res) => res,

  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/jwt/create/' && err.response) {
      // eslint-disable-next-line
      if (err.response.status === 401 && !originalConfig._retry) {
        // eslint-disable-next-line
        originalConfig._retry = true;

        try {
          const response = await client.post('/auth/jwt/refresh/', {
            refresh: Cookies.get('jwt-refresh'),
          });

          const tokenPayload = authMixin.methods.getJwtPayload(response.data.access);

          Cookies.set('jwt-access', response.data.access, {
            expires: new Date(tokenPayload.exp * 1000),
          });

          return client(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default client;
