<template>
  <Dialog
    class="intro-send-dialog t-inverted"
    :open="open"
    :modifier="'blue'"
    :buttonConfirm="$t('dialog.introSend.confirm')"
    @open="initialize"
  >
    <h2 class="intro-send-dialog__headline">{{ $t('dialog.introSend.headline') }}</h2>

    <p class="intro-send-dialog__text" v-html="$t('dialog.introSend.text')" />

    <img class="intro-send-dialog__illustration" src="@/assets/images/send-pin.svg" />
  </Dialog>
</template>

<script src="./index.vue.js"></script>
