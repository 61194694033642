export default {
  de: {
    templates: [
      {
        id: 'b42ef0e6-74ce-11ec-90d6-0242ac120003',
        name: 'Meine Vorlage',
        subject: 'Eine einfache Vorlage',
        sender: 'Max Mustermann\nHauptstraße 123\n12345 Musterstadt',
        recipient: 'Anna Musterfrau\nHauptstraße 25\n12345 Musterstadt',
        memo: 'Dies ist eine *Notiz*',
        info: '*Kontakt*\nTelefon: (0123) 1234 567\nFax: (0123) 1234 567\n\nE-Mail: max.mustermann@email.de',
        text: ['Sie können beliebig viele Vorlagen aus Briefen, Serienbriefen und Rechnungen erstellen und diese mit einem Klick auf das aktuelle Dokument anwenden. Neben allen Textfeldern werden dabei auch Briefkopf, Briefpapier sowie Unterschrift gespeichert.\n\nGeben Sie Ihrer Vorlage einen aussagekräftigen Namen, um Inhalte die Sie regelmäßig benötigen schnell und einfach abrufen zu können.'],
        footer: ['Dies ist eine Fußzeile', 'Hier können weitere Informationen\nhinterlegt werden', 'Wie Bankverbindungen,\nE-Mail-Adressen\noder Telefonnummern', 'Es können bis zu\n4 Spalten erstellt werden'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: [],

        invoiceId: null,
        invoiceTaxRate: 0,
        invoiceCurrency: 'EUR',
        invoiceRows: [['Entry', 1, 100, null, true]],

        documentType: 0,

        sentAt: 1659341714,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
    ],
  },

  en: {
    templates: [
      {
        id: 'b42ef0e6-74ce-11ec-90d6-0242ac120003',
        name: 'My template',
        subject: 'A simple template',
        sender: 'John Smith\n132, Main Street\nNew York 12401',
        recipient: 'Alex Best\n102, Main Street\nNew York 12300',
        memo: 'This is a *memo*',
        info: '*Contact*\nPhone: (555) 555-1234\ne-mail: john.smith@email.com',
        text: ['You can create any number of templates from letters, serial letters and invoices and apply them to the current document with one click. In addition to all text fields, the letterhead, stationery and signature are also saved.\n\nGive your template a meaningful name so that you can quickly and easily access the content that you regularly need.'],
        footer: ['This is a footer', 'Further information\ncan be presented here', 'Such as bank details,\nemail addresses\nor telephone numbers', 'Up to 4 columns\ncan be created'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: [],

        invoiceId: null,
        invoiceTaxRate: 0,
        invoiceCurrency: 'EUR',
        invoiceRows: [['Entry', 1, 100, null, true]],

        documentType: 0,

        sentAt: 1659341714,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
    ],
  },
};
