import { ref } from 'vue';

import api from '@/api';

import Icon from '@/components/atoms/icon/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

import avatarImage from '@/assets/images/avatar.svg';

export default {
  name: 'DialogUserSettings',

  props: {
    open: Boolean,
  },

  components: {
    Icon,

    Dialog,
  },

  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const stripeCustomerPortalUrl = ref(null);

    return {
      firstName,
      lastName,
      stripeCustomerPortalUrl,
    };
  },

  computed: {
    avatarImage() {
      return this.currentUser && this.currentUser.avatarImage
        ? this.currentUser.avatarImage : avatarImage;
    },

    currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },

  methods: {
    initialize() {
      if (this.currentUser) {
        this.firstName = this.currentUser.firstName;
        this.lastName = this.currentUser.lastName;

        api.stripe.getCustomerPortalUrl().then((response) => {
          this.stripeCustomerPortalUrl = response.data.url;
        });
      }
    },

    updateImage(event) {
      this.$store.dispatch('updateCurrentUserAvatar', event.target.files[0]);
    },

    save() {
      if (this.firstName !== this.currentUser.firstName
        || this.lastName !== this.currentUser.lastName) {
        this.$store.dispatch('updateCurrentUser', {
          firstName: this.firstName,
          lastName: this.lastName,
        });
      }
    },
  },
};
