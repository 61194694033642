import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/api';

import Dialog from '@/components/particles/dialog/index.vue';

import Button from '@/components/atoms/button/index.vue';

import FeatureLock from '@/components/molecules/feature-lock/index.vue';

export default {
  name: 'DialogUpsellPro',

  props: {
    open: Boolean,
  },

  components: {
    Dialog,

    Button,

    FeatureLock,
  },

  setup(_, context) {
    const store = useStore();
    const router = useRouter();

    const currentUser = computed(() => store.state.auth.currentUser);

    const subscribe = () => {
      context.emit('cancel');
      store.commit('setRequesting', true);

      api.stripe.createCheckoutSession({
        price: process.env.VUE_APP_STRIPE_PRICE_PRO_NO_CREDITS_MONTHLY,
        quantity: 1,
        mode: 'subscription',
        clientReferenceId: currentUser.value.id,
        subscriptionCredits: '0',
        successUrl: `${window.location.origin}${router.resolve({ name: 'editor', query: { checkoutSuccess: 1 } }).href}`,
        cancelUrl: `${window.location.origin}${router.resolve({ name: 'editor', query: { checkoutCanceled: 1 } }).href}`,
      }).then((response) => {
        window.location.href = response.data.url;
      });
    };

    return {
      subscribe,
    };
  },
};
