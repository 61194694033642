import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import humps from 'humps';

import i18n from '@/i18n';

import api from '@/api';

import PRODUCTS from '@/services/products';

import Dialog from '@/components/particles/dialog/index.vue';

import Button from '@/components/atoms/button/index.vue';

import authMixin from '@/mixins/auth';

export default {
  name: 'DialogSend',

  props: {
    open: Boolean,
    source: String,
    sourcePageRange: Number,
  },

  components: {
    Dialog,

    Button,
  },

  mixins: [
    authMixin,
  ],

  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    const previewLoading = ref(false);
    const previewSource = ref(null);
    const loading = ref(false);
    const checked = ref(false);
    const message = ref(null);
    const selectedProduct = ref(null);

    const currentUser = computed(() => store.state.auth.currentUser);
    const currentDocument = computed(() => store.state.documents.currentDocument);

    const totalAttachmentPages = computed(() => currentDocument.value?.attachments?.reduce(
      (a, b) => a + b.numPages, 0,
    ));

    const totalPages = computed(() => props.sourcePageRange + totalAttachmentPages.value);

    const availableProducts = computed(() => PRODUCTS.filter(
      (product) => product.maxPages >= totalPages.value,
    ));

    const confirmButtonText = computed(() => (currentUser.value?.credits[selectedProduct.value?.id] === 0 ? i18n.global.t('dialog.send.buyCredit') : i18n.global.t('dialog.send.useCredit')));
    const validatePageRange = computed(
      () => props.sourcePageRange <= selectedProduct.value?.maxPages,
    );

    watch(() => props.open, (open) => {
      if (open) {
        message.value = null;
        checked.value = false;
        previewLoading.value = true;

        setTimeout(() => {
          const availableProduct = availableProducts.value[0];
          selectedProduct.value = availableProduct;

          previewLoading.value = false;
          previewSource.value = props.source;
        }, 2000);
      }
    });

    const initiatePayment = (product) => {
      context.emit('cancel');
      store.commit('setRequesting', true);

      api.stripe.createCheckoutSession({
        price: product.price,
        quantity: 1,
        mode: 'payment',
        clientReferenceId: currentUser.value.id,
        successUrl: `${window.location.origin}${router.resolve({ name: 'document', params: { documentId: currentDocument.value.id }, query: { send: 1 } }).href}`,
        cancelUrl: `${window.location.origin}${router.resolve({ name: 'document', params: { documentId: currentDocument.value.id }, query: { checkoutCanceled: 1 } }).href}`,
      }).then((response) => {
        window.location.href = response.data.url;
      });
    };

    const send = () => {
      loading.value = true;

      api.jobs.create({
        productId: api.constants.products[selectedProduct.value.id],
        document: currentDocument.value.id,
        fileData: props.source.replace('data:application/pdf;base64,', ''),
      }).then((response) => {
        if (response.status === 201) {
          const data = humps.camelizeKeys(response.data);

          store.dispatch('updateCurrentUser', {
            credits: data.userProfileCredits,
          });

          store.dispatch('updateDocument', {
            id: currentDocument.value.id,
            data: {
              sentAt: new Date().toISOString(),
            },
          }).then(() => {
            message.value = {
              type: 'success',
              code: data.referenceNumber,
            };

            loading.value = false;

            setTimeout(() => {
              checked.value = true;
            }, 500);
          });
        } else {
          loading.value = false;
        }
      }).catch((error) => {
        // eslint-disable-next-line
        console.log(error.response?.status);

        message.value = { type: 'error' };
        loading.value = false;

        setTimeout(() => {
          checked.value = true;
        }, 500);
      });
    };

    const confirm = () => {
      if (currentUser.value.credits[selectedProduct.value.id] === 0) {
        initiatePayment(selectedProduct.value);
      } else {
        send();
      }
    };

    return {
      previewSource,
      previewLoading,
      loading,
      checked,
      message,
      selectedProduct,

      currentUser,
      currentDocument,
      confirmButtonText,
      validatePageRange,
      availableProducts,

      totalPages,
      totalAttachmentPages,

      initiatePayment,
      confirm,
    };
  },
};
