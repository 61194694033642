import { ref, watch } from 'vue';

import Button from '@/components/atoms/button/index.vue';

export default {
  name: 'Dialog',

  props: {
    open: Boolean,
    modifier: String,
    buttonCancel: String,
    buttonCancelDisabled: Boolean,
    buttonConfirm: String,
    buttonConfirmDisabled: Boolean,
    buttonConfirmStyle: String,
  },

  components: {
    Button,
  },

  setup(props, context) {
    const dialog = ref(null);
    const initialized = ref(false);

    watch(() => props.open, (open) => {
      if (open) {
        setTimeout(() => {
          initialized.value = true;
          dialog.value.focus();
        }, 200);

        context.emit('open');
      } else {
        initialized.value = false;
      }
    });

    return {
      dialog,
      initialized,
    };
  },
};
