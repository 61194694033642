<template>
  <Dialog
    class="attachments-dialog"
    :open="open"
    :buttonConfirm="$t('global.done')"
  >
    <div v-if="loading" class="attachments-dialog__loading" />

    <div v-if="!loading" class="editor__section">
      <div class="editor__section-desc">
        <label
          for="attachments"
          class="editor__fieldset-label"
        >
          {{ $t('dialog.attachments.headline') }}
        </label>
        <p class="editor__text">{{ $t('dialog.attachments.description') }}</p>
      </div>
    </div>

    <div class="attachments-dialog__content">
      <div class="attachments-dialog__content-side">
        <div
          class="attachments-dialog__list"
          :class="{ 'attachments-dialog__list--empty': sortedAttachments.length === 0 }"
        >
          <div
            v-if="sortedAttachments.length === 0"
            class="attachments-dialog__list-title"
          >
            <label class="button editor__input-button">
              <input
                class="editor__input editor__input--file"
                type="file"
                accept="image/jpeg, application/pdf"
                multiple="false"
                @click="$event.target.value = null"
                @change="upload"
              />
              {{ $t('dialog.attachments.fileInput') }}
            </label>

            <p
              v-if="errorMessage"
              class="editor__text editor__text--small editor__text--error">
              {{ errorMessage }}
            </p>
            <p
              v-else
              class="editor__text editor__text--small">
              {{ $t('dialog.attachments.fileText') }}
            </p>
          </div>
          <transition-group name="row">
            <div
              v-for="attachment in sortedAttachments"
              :key="attachment.id"
            >
              <input
                type="radio"
                :id="`attachment-label-${attachment.id}`"
                class="attachments-dialog__list-input"
                v-model="selectedAttachment"
                :value="attachment"
                @change="selectedAttachmentChange"
              />
              <label
                class="attachments-dialog__list-label"
                :for="`attachment-label-${attachment.id}`"
              >
                <span class="attachment-dialog__list-label-info">
                  <span class="attachment-dialog__list-label-text">{{ attachment.filename }}</span>
                  <span class="attachment-dialog__list-label-text">
                    {{ $t('dialog.attachments.totalPages', { count: attachment.numPages }) }}
                  </span>
                </span>
                <Button
                  :text="$t('global.remove')"
                  :size="'small'"
                  :style="'outline-danger'"
                  @click.stop="remove(attachment)"
                />
              </label>
            </div>
          </transition-group>
        </div>

        <div
          class="editor__section editor__section--multiple"
          v-if="sortedAttachments.length > 0"
        >
          <div class="editor__section-desc">
            <label class="button editor__input-button">
              <input
                class="editor__input editor__input--file"
                type="file"
                accept="image/jpeg, application/pdf"
                multiple="false"
                @click="$event.target.value = null"
                @change="upload"
              />
              {{ $t('dialog.attachments.fileInput') }}
            </label>

            <p
              v-if="errorMessage"
              class="editor__text editor__text--small editor__text--error">
              {{ errorMessage }}
            </p>
            <p
              v-else
              class="editor__text editor__text--small">
              {{ $t('dialog.attachments.fileText') }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="sortedAttachments.length"
        class="send-dialog__preview-wrapper"
      >
        <div class="attachments-dialog__preview">
          <iframe
            v-if="selectedAttachmentSource"
            class="attachments-dialog__preview-frame"
            :src="`${selectedAttachmentSource}#toolbar=0&navpanes=0&zoom=FitH`"
          />
          <p
            v-else
            class="attachments-dialog__preview-text"
          >
            {{ $t('dialog.attachments.previewText') }}
          </p>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
