<template>
  <Dialog
    class="paywall-dialog"
    :class="{ 'paywall-dialog--single': currentUser?.subscription.type === 1 }"
    :open="open"
    :modifier="'dark'"
  >
    <div class="paywall-dialog__inner">
      <div
        v-if="currentUser?.subscription.type < 1"
        class="paywall-dialog__column"
      >
        <header class="paywall-dialog__header">
          <div class="paywall-dialog__logo">
            <img class="paywall-dialog__logo-image" src="@/assets/images/logo.svg" />
            <Badge class="paywall-dialog__logo-badge" text="Pro" :style="'pro'" />
          </div>

          <p class="paywall-dialog__header-text">
            {{ $t('dialog.paywall.pro.subline') }}
          </p>
        </header>

        <ul class="paywall-dialog__list">
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.pro.argument1')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.pro.argument2')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.pro.argument3')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.pro.argument4')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.pro.argument5')" />
        </ul>

        <div class="paywall-dialog__credits">
          <h4
            class="paywall-dialog__credits-headline"
            v-html="$t('dialog.paywall.credits.headline')"
          />
          <input
            type="radio"
            id="pro-credit-selection-0"
            class="paywall-dialog__credits-input"
            v-model="selectedProCreditOption"
            value="0"
          />
          <label
            for="pro-credit-selection-0"
            class="paywall-dialog__credits-label"
            tabindex="0"
            @keyup.enter="selectedProCreditOption = 0"
          >
            {{ $t('dialog.paywall.credits.option0') }}
          </label>
          <input
            type="radio"
            id="pro-credit-selection-5"
            class="paywall-dialog__credits-input"
            v-model="selectedProCreditOption"
            value="5"
          />
          <label
            for="pro-credit-selection-5"
            class="paywall-dialog__credits-label"
            tabindex="0"
            @keyup.enter="selectedProCreditOption = 5"
          >
            {{ $t('dialog.paywall.credits.option5') }}
          </label>
        </div>

        <div
          class="paywall-cta"
          tabindex="0"
          @click="subscribePro"
          @keyup.enter="subscribePro"
        >
          <span class="paywall-cta__kicker" v-html="$t('dialog.paywall.pro.kicker')" />
          <p class="paywall-cta__text">{{ $t('dialog.paywall.pro.text') }}</p>
          <p class="paywall-cta__price">
            <template v-if="showProYearly">
              {{ $t(`dialog.paywall.pro.price.credits${selectedProCreditOption}.yearly`) }}
            </template>
            <template v-else>
              {{ $t(`dialog.paywall.pro.price.credits${selectedProCreditOption}.monthly`) }}
            </template>
          </p>
          <footer class="paywall-cta__footer">
            <template v-if="showProYearly">
              <span
                class="paywall-cta__footer-text paywall-cta__footer-text--emphasized"
              >{{ $t(`dialog.paywall.pro.priceFooter.credits${
                selectedProCreditOption}.save`) }}</span>

              <span
                class="paywall-cta__footer-text paywall-cta__footer-text--symbol"
              >&nbsp;&middot;&nbsp;</span>

              <span
                class="paywall-cta__footer-text paywall-cta__footer-text--strikethrough"
              >{{ $t(`dialog.paywall.pro.priceFooter.credits${
                selectedProCreditOption}.reference`) }}</span>
            </template>
            <template v-else>
              <span class="paywall-cta__footer-text paywall-cta__footer-text--symbol">–</span>
            </template>
          </footer>
        </div>

        <div class="paywall-switch paywall-switch--pro" @click="toggleProSwitch">
          <Switch
            class="paywall-switch__toggle"
            v-model:checked="showProYearly"
            :label="$t('dialog.paywall.pro.switch.label')"
          />

          <p
            class="paywall-switch__text"
            v-html="$t(`dialog.paywall.pro.switch.text.credits${
              selectedProCreditOption
            }.${showProYearly ? 'yearly' : 'monthly'}`)"
          />
        </div>

        <div class="paywall-cta-button">
          <Button
            :text="$t('dialog.paywall.pro.cta')"
            :size="'small'"
            @click="subscribePro"
          />
        </div>
      </div>

      <div class="paywall-dialog__column">
        <header class="paywall-dialog__header">
          <div class="paywall-dialog__logo">
            <img class="paywall-dialog__logo-image" src="@/assets/images/logo.svg" />
            <Badge class="paywall-dialog__logo-badge" text="Business" :style="'business'" />
          </div>

          <p class="paywall-dialog__header-text">
            {{ $t('dialog.paywall.business.subline') }}
          </p>
        </header>

        <ul class="paywall-dialog__list">
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.business.argument1')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.business.argument2')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.business.argument3')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.business.argument4')" />
          <li class="paywall-dialog__list-item" v-html="$t('dialog.paywall.business.argument5')" />
        </ul>

        <div class="paywall-dialog__credits">
          <h4
            class="paywall-dialog__credits-headline"
            v-html="$t('dialog.paywall.credits.headline')"
          />
          <input
            type="radio"
            id="business-credit-selection-0"
            class="paywall-dialog__credits-input"
            v-model="selectedBusinessCreditOption"
            value="0"
          />
          <label
            for="business-credit-selection-0"
            class="paywall-dialog__credits-label"
            tabindex="0"
            @keyup.enter="selectedBusinessCreditOption = 0"
          >
            {{ $t('dialog.paywall.credits.option0') }}
          </label>
          <input
            type="radio"
            id="business-credit-selection-15"
            class="paywall-dialog__credits-input"
            v-model="selectedBusinessCreditOption"
            value="15"
          />
          <label
            for="business-credit-selection-15"
            class="paywall-dialog__credits-label"
            tabindex="0"
            @keyup.enter="selectedBusinessCreditOption = 15"
          >
            {{ $t('dialog.paywall.credits.option15') }}
          </label>
        </div>

        <div
          class="paywall-cta paywall-cta--business"
          tabindex="0"
          @click="subscribeBusiness"
          @keyup.enter="subscribeBusiness"
        >
          <span class="paywall-cta__kicker" v-html="$t('dialog.paywall.business.kicker')" />
          <p class="paywall-cta__text">{{ $t('dialog.paywall.business.text') }}</p>
          <p class="paywall-cta__price">
            <template v-if="showBusinessYearly">
              {{ $t(`dialog.paywall.business.price.credits${
                selectedBusinessCreditOption}.yearly`) }}
            </template>
            <template v-else>
              {{ $t(`dialog.paywall.business.price.credits${
                selectedBusinessCreditOption}.monthly`) }}
            </template>
          </p>
          <footer class="paywall-cta__footer">
            <template v-if="showBusinessYearly">
              <span
                class="paywall-cta__footer-text paywall-cta__footer-text--emphasized"
              >{{ $t(`dialog.paywall.business.priceFooter.credits${
                selectedBusinessCreditOption}.save`) }}</span>

              <span
                class="paywall-cta__footer-text paywall-cta__footer-text--symbol"
              >&nbsp;&middot;&nbsp;</span>

              <span
                class="paywall-cta__footer-text paywall-cta__footer-text--strikethrough"
              >{{ $t(`dialog.paywall.business.priceFooter.credits${
                selectedBusinessCreditOption}.reference`) }}</span>
            </template>
            <template v-else>
              <span class="paywall-cta__footer-text paywall-cta__footer-text--symbol">–</span>
            </template>
          </footer>
        </div>

        <div class="paywall-switch paywall-switch--business" @click="toggleBusinessSwitch">
          <Switch
            class="paywall-switch__toggle"
            v-model:checked="showBusinessYearly"
            :label="$t('dialog.paywall.business.switch.label')"
          />

          <p
            class="paywall-switch__text"
            v-html="$t(`dialog.paywall.business.switch.text.credits${
              selectedBusinessCreditOption
            }.${
              showBusinessYearly ? 'yearly' : 'monthly'}`)"
          />
        </div>

        <div class="paywall-cta-button">
          <Button
            :text="$t('dialog.paywall.business.cta')"
            :size="'small'"
            :style="'business'"
            @click="subscribeBusiness"
          />
        </div>
      </div>
    </div>

    <footer class="paywall-dialog__footer">
      <p class="paywall-dialog__footer-text" v-html="$t('dialog.paywall.footer0')" />
    </footer>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
