import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import axios from 'axios';
import Cookies from 'js-cookie';
import { PDFDocument } from 'pdf-lib';

import i18n from '@/i18n';

import Document from '@/services/document';
import Attachment from '@/services/document/attachment';

import { AVAILABLE_FONTS } from '@/services/document/defaults';
import DOCUMENT_TYPES from '@/services/document/types';
import DOCUMENT_NORMS from '@/services/document/norms';

import Button from '@/components/atoms/button/index.vue';
import Icon from '@/components/atoms/icon/index.vue';

import DialogUserSettings from '@/components/molecules/dialogs/user-settings/index.vue';
import DialogDocumentCreate from '@/components/molecules/dialogs/document-create/index.vue';
import DialogDocumentDelete from '@/components/molecules/dialogs/document-delete/index.vue';
import DialogBackground from '@/components/molecules/dialogs/background/index.vue';
import DialogLetterhead from '@/components/molecules/dialogs/letterhead/index.vue';
import DialogSignature from '@/components/molecules/dialogs/signature/index.vue';
import DialogFontFamily from '@/components/molecules/dialogs/font-family/index.vue';
import DialogIntro from '@/components/molecules/dialogs/intro/index.vue';
import DialogIntroSend from '@/components/molecules/dialogs/intro-send/index.vue';
import DialogIntroSendIllustration from '@/components/molecules/dialogs/intro-send/illustration.vue';
import DialogPaywall from '@/components/molecules/dialogs/paywall/index.vue';
import DialogUpsellPro from '@/components/molecules/dialogs/upsell-pro/index.vue';
import DialogTemplates from '@/components/molecules/dialogs/templates/index.vue';
import DialogManagePlaceholders from '@/components/molecules/dialogs/manage-placeholders/index.vue';
import DialogExportSerial from '@/components/molecules/dialogs/export-serial/index.vue';
import DialogSend from '@/components/molecules/dialogs/send/index.vue';
import DialogAttachments from '@/components/molecules/dialogs/attachments/index.vue';
import DialogCompletion from '@/components/molecules/dialogs/completion/index.vue';
import DialogMessage from '@/components/molecules/dialogs/message/index.vue';
import PageManager from '@/components/molecules/pages/manager/index.vue';
import FeatureLock from '@/components/molecules/feature-lock/index.vue';

import Menu from '@/components/organisms/menu/index.vue';
import Preview from '@/components/organisms/preview/index.vue';
import Sidebar from '@/components/organisms/sidebar/index.vue';
import Toolbar from '@/components/organisms/toolbar/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

import editorMixin from '@/mixins/editor';
import exportMixin from '@/mixins/export';
import invoiceMixin from '@/mixins/invoice';

export default {
  name: 'Editor',

  components: {
    Button,
    Icon,

    Dialog,
    DialogUserSettings,
    DialogDocumentCreate,
    DialogDocumentDelete,
    DialogBackground,
    DialogLetterhead,
    DialogSignature,
    DialogFontFamily,
    DialogIntro,
    DialogIntroSend,
    DialogIntroSendIllustration,
    DialogPaywall,
    DialogUpsellPro,
    DialogTemplates,
    DialogManagePlaceholders,
    DialogExportSerial,
    DialogSend,
    DialogAttachments,
    DialogCompletion,
    DialogMessage,

    PageManager,

    FeatureLock,

    Menu,
    Preview,
    Sidebar,
    Toolbar,
  },

  mixins: [
    editorMixin,
    exportMixin,
    invoiceMixin,
  ],

  setup() {
    const store = useStore();

    const showSidebar = computed(() => store.state.editor.showSidebar);
    const currentDialog = computed(() => store.state.app.currentDialog);

    const showPreview = ref(false);

    const currentDialogDocument = ref(null);
    const messageDialog = ref({});

    const exportProgress = ref(null);

    const formSerialImportFile = ref(null);
    const formInvoiceColumns = ['remove', 'description', 'amount', 'pricePerUnit', 'total', 'applyTax'];

    const currentSelection = ref(null);
    const currentFocus = ref(null);

    const previewRendering = ref(false);
    const previewSource = ref(null);
    const previewSourceSend = ref(null);
    const previewSourcePageRange = ref(null);
    const previewDocument = new Document();

    const timeout = null;
    const timeoutDelay = 1000;

    const toggleSidebar = (status) => store.commit('setShowSidebar', status);
    const toggleDialog = (dialog) => store.commit('setCurrentDialog', dialog);

    return {
      AVAILABLE_FONTS,

      showSidebar,
      showPreview,

      currentDialog,
      currentDialogDocument,
      messageDialog,

      exportProgress,

      formSerialImportFile,
      formInvoiceColumns,

      currentSelection,
      currentFocus,

      previewRendering,
      previewSource,
      previewSourceSend,
      previewSourcePageRange,
      previewDocument,

      timeout,
      timeoutDelay,

      toggleSidebar,
      toggleDialog,
    };
  },

  watch: {
    currentDialog(value) {
      if (value === 'dialog-send' && this.currentDocument) {
        this.renderSendPreview();
      }

      if (value === 'dialog-document-create'
        && this.documents.length > 0
        && this.currentUser.subscription.type === 0) {
        this.toggleDialog('dialog-upsell-pro');
      }
    },

    currentDocument() {
      if (this.currentDialog === 'dialog-send') {
        this.renderSendPreview();
      }

      if (
        !this.viewedDocument()
        && !this.isFormSerial
        && this.currentDocument
        && this.currentUser
      ) {
        this.toggleDialog('dialog-intro-send');
      }
    },
  },

  computed: {
    requesting() {
      return this.$store.state.app.requesting;
    },

    presenting() {
      return this.showSidebar || this.currentDialog;
    },

    isFormSerial() {
      return this.formDocumentType === DOCUMENT_TYPES.SERIAL;
    },

    isFormInvoice() {
      return this.formDocumentType === DOCUMENT_TYPES.INVOICE;
    },

    currentUser() {
      return this.$store.state.auth.currentUser;
    },

    currentDocument() {
      return this.$store.state.documents.currentDocument;
    },

    documents() {
      return this.$store.state.documents.documents;
    },
  },

  methods: {
    createDocument(documentType) {
      this.$store.commit('setRequesting', true);

      if (this.currentUser) {
        this.$store.dispatch('createDocument', {
          documentType,
          subject: i18n.global.t('editor.newDocumentDefaultTitle'),
          text: [''],
          footer: [''],
          invoiceRows: [[i18n.global.t('editor.newProductDefaultTitle'), 1, 100, null, true]],
        }).then(() => {
          this.$store.commit('setRequesting', false);
          this.$router.push({ name: 'document', params: { documentId: this.currentDocument.id } });

          this.updatePreviewDocument();
        });
      } else {
        this.$store.dispatch('createPreviewDocument', { documentType, locale: i18n.global.locale.value }).then(() => {
          this.$store.commit('setRequesting', false);

          this.updatePreviewDocument();
        });
      }
    },

    createDocumentFromExternalSource(id) {
      this.$store.dispatch('getCurrentUser').then(() => {
        this.$store.dispatch('getDocuments').then(() => {
          if (this.documents.length > 0 && this.currentUser.subscription.type === 0) {
            this.toggleDialog('dialog-upsell-pro');
          } else {
            this.$store.commit('setRequesting', true);
            this.$store.dispatch('createAndPersistExternalDocument', { id, locale: i18n.global.locale.value }).then(() => {
              this.$store.commit('setRequesting', false);

              this.updatePreviewDocument();
            });
          }
        }).catch(() => {
          this.$store.commit('setRequesting', true);
          this.$store.dispatch('createAndPersistExternalDocument', { id, locale: i18n.global.locale.value }).then(() => {
            this.$store.commit('setRequesting', false);

            this.updatePreviewDocument();
          });
        });
      }).catch(() => {
        this.$store.commit('setRequesting', true);
        this.$store.dispatch('createExternalDocument', { id, locale: i18n.global.locale.value }).then(() => {
          this.$store.commit('setRequesting', false);

          this.updatePreviewDocument();
        });
      });
    },

    deleteDocument(document) {
      const deleteCurrentDocument = this.currentDocument && this.currentDocument.id === document.id;

      this.$store.dispatch('deleteDocument', document.id).then(() => {
        if (deleteCurrentDocument) {
          this.$router.push({ name: 'editor' });
        }
      });
    },

    removePage(index) {
      this.formText.splice(index, 1);

      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          text: this.formText,
        });
      });
    },

    updateFontSize(fontSize) {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          fontSize,
        }, true);
      });
    },

    updateFontFamily() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          fontFamily: this.formFontFamily,
        });
      });
    },

    updateBackgroundSettings() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          background: this.formShowBackground,
        });
      });
    },

    updateLetterheadSettings() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          letterhead: this.formShowLetterhead,
          letterheadAlignment: this.formLetterheadAlignment,
        });
      });
    },

    updateSignatureSettings() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          signature: this.formShowSignature,
        });
      });
    },

    updateAll() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument();
      });
    },

    updateSender() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          sender: this.formSender,
        });
      });
    },

    updateRecipient() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          recipient: this.formRecipient,
        });
      });
    },

    updateMemo() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          memo: this.formMemo,
        });
      });
    },

    updateInfo() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          info: this.formInfo,
        });
      });
    },

    updateIssuedAt() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        if (this.formDate) {
          this.persistDocument({
            issuedAt: new Date(this.formDate).toISOString(),
          });
        }
      });
    },

    updateInvoiceId() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          invoiceId: this.formInvoiceId,
        });
      });
    },

    updateInvoiceTaxRate() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          invoiceTaxRate: this.formInvoiceTaxRate,
        });
      });
    },

    updateInvoiceCurrency() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          invoiceCurrency: this.formInvoiceCurrency,
        });
      });
    },

    updateSubject() {
      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          subject: this.formSubject,
        });
      });
    },

    replaceFormText(text) {
      this.formText = [text];

      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument();
      });
    },

    updateFormText($event, index) {
      this.formText[index] = $event.target.value;

      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument();
      });
    },

    addFooterColumn() {
      this.formFooter.push('');

      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          footer: this.formFooter,
        });
      });
    },

    removeFooterColumn(index) {
      this.formFooter.splice(index, 1);

      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          footer: this.formFooter,
        });
      });
    },

    updateFormFooter($event, index) {
      this.formFooter[index] = $event.target.value;

      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument();
      });
    },

    updateFoldingMarkers() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          foldingMarkers: this.formShowFoldingMarkers,
        });
      });
    },

    updatePageNumbers() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          pageNumbers: this.formShowPageNumbers,
        });
      });
    },

    updateNorm() {
      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          norm: this.formNorm,
        });
      });
    },

    updatePreviewDocument(delay = 0) {
      return new Promise((resolve) => {
        if (!this.currentDocument) resolve();

        clearTimeout(this.timeout);

        this.previewDocument = this.updateDocument(this.previewDocument);

        this.timeout = setTimeout(() => {
          this.renderPreview().then(() => {
            resolve();
          });
        }, delay);
      });
    },

    updateDocument(currentDocument, options = {}) {
      const document = currentDocument;

      // Set fields
      document.sender = this.formSender;
      document.recipient = this.formRecipient;
      document.memo = this.formMemo;
      document.info = this.formInfo;
      document.date = this.formDate;
      document.subject = this.formSubject;
      document.text = this.formText;
      document.footer = this.formFooter;
      document.showPageNumbers = this.formShowPageNumbers;
      document.showFoldingMarkers = this.formShowFoldingMarkers;
      document.norm = options.norm || DOCUMENT_NORMS[this.formNorm];

      // Set font
      document.fontFamily = AVAILABLE_FONTS[this.formFontFamily].family;
      document.fontFamilyBold = AVAILABLE_FONTS[this.formFontFamily].familyBold;
      document.fontSize = this.formFontSize;

      // Set invoice
      if (this.isFormInvoice) {
        document.invoiceId = this.formInvoiceId;
        document.invoiceData = this.formatInvoiceData(this.formInvoiceRows);
      } else {
        document.invoiceId = null;
        document.invoiceData = {};
      }

      // Set graphics
      document.background = {
        url: this.formBackgroundImage,
        show: this.formShowBackground,
      };

      document.letterhead = {
        url: this.formLetterheadImage,
        show: this.formShowLetterhead,
        align: ['left', 'center', 'right'][this.formLetterheadAlignment],
      };

      document.signature = {
        url: this.formSignatureImage,
        show: this.formShowSignature,
      };

      return document;
    },

    persistDocument(data, setCurrentDocument = false) {
      setTimeout(() => {
        const payload = {
          id: this.currentDocument.id,
          data: {
            ...data || {
              sender: this.formSender,
              recipient: this.formRecipient,
              memo: this.formMemo,
              info: this.formInfo,
              subject: this.formSubject,
              text: this.formText,
              invoiceId: this.formInvoiceId,
              invoiceTaxRate: this.formInvoiceTaxRate,
              invoiceRows: this.formInvoiceRows,
              footer: this.formFooter,

              ...(this.formDate ? {
                issuedAt: new Date(this.formDate).toISOString(),
              } : {}),
            },
          },
        };

        if (this.currentUser) {
          this.$store.dispatch('updateDocument', { ...payload, options: { setCurrentDocument } });
        } else {
          this.$store.dispatch('updatePreviewDocument', { ...payload, locale: i18n.global.locale.value });
        }
      }, 300);
    },

    renderPreview() {
      return new Promise((resolve) => {
        this.previewRendering = true;

        setTimeout(() => {
          this.previewDocument.render().then((pdfDocument) => {
            this.previewDocument.stream.on('finish', () => {
              const pageRange = pdfDocument.bufferedPageRange();
              this.previewSourcePageRange = pageRange.start + pageRange.count;
              this.previewSource = this.previewDocument.stream.toBlobURL('application/pdf');

              setTimeout(() => {
                this.previewRendering = false;

                resolve();
              }, 400);
            });
          });
        }, 100);
      });
    },

    async renderSendPreview() {
      const previewDocumentSend = this.updateDocument(this.previewDocument, {
        norm: DOCUMENT_NORMS.pin,
      });

      const previewDocumentPromise = new Promise((resolve) => {
        previewDocumentSend.render().then(() => {
          previewDocumentSend.stream.on('finish', () => {
            const reader = new FileReader();
            reader.onloadend = () => { resolve(reader.result); };
            reader.readAsArrayBuffer(previewDocumentSend.stream.toBlob('application/pdf'));
          });
        });
      });

      const attachmentPromises = this.currentDocument.attachments.sort(
        (a, b) => a.createdAt - b.createdAt,
      ).map((attachment) => {
        // Render pdf files
        if (attachment.file.split('.').slice(-1)[0] === 'pdf') {
          return axios
            .get(attachment.file, { responseType: 'arraybuffer' })
            .then((response) => response.data);
        }

        // Render image files
        const attachmentDocument = new Attachment({ image: { url: attachment.file } });
        return new Promise((resolve) => {
          attachmentDocument.render().then(() => {
            attachmentDocument.stream.on('finish', () => {
              const reader = new FileReader();
              reader.onloadend = () => { resolve(reader.result); };
              reader.readAsArrayBuffer(attachmentDocument.stream.toBlob('application/pdf'));
            });
          });
        });
      });

      const combinedPdf = await PDFDocument.create();

      // Add the current previewDocument
      const previewPdfBytes = await previewDocumentPromise;
      const previewPdfDoc = await PDFDocument.load(previewPdfBytes);
      const previewPages = await combinedPdf.copyPages(
        previewPdfDoc, previewPdfDoc.getPageIndices(),
      );

      await Promise.all(previewPages.map((page) => combinedPdf.addPage(page)));

      // Add sorted attachments
      const processAttachmentPromises = async () => {
        await attachmentPromises.reduce(async (previousPromise, attachmentPromise) => {
          await previousPromise;

          const attachmentData = await attachmentPromise;
          const attachmentPdfDoc = await PDFDocument.load(attachmentData);
          const attachmentPages = await combinedPdf.copyPages(
            attachmentPdfDoc, attachmentPdfDoc.getPageIndices(),
          );

          await Promise.all(attachmentPages.map((page) => combinedPdf.addPage(page)));
        }, Promise.resolve());
      };

      await processAttachmentPromises();

      // Convert combined PDF to data URL
      const combinedPdfBytes = await combinedPdf.save();
      const pdfBlob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
      const reader = new FileReader();
      reader.onloadend = () => { this.previewSourceSend = reader.result; };
      reader.readAsDataURL(pdfBlob);
    },

    setSelection($event) {
      if ($event === null || $event.target.selectionStart === $event.target.selectionEnd) {
        this.currentSelection = null;
      } else {
        this.currentSelection = $event.target;
      }
    },

    setFocus($event) {
      if (this.currentFocus === $event.target) {
        this.currentFocus = null;
      } else {
        this.currentFocus = $event.target;
      }

      if ($event.relatedTarget === null || ($event.relatedTarget && $event.relatedTarget.classList.contains('placeholder'))) {
        this.currentFocus = $event.target;
      }
    },

    addInvoiceRow() {
      this.formInvoiceRows.push(['', '', '', '', true]);

      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          invoiceRows: this.formInvoiceRows,
        });
      });
    },

    removeInvoiceRow(index) {
      this.formInvoiceRows.splice(index, 1);

      this.updatePreviewDocument().then(() => {
        this.persistDocument({
          invoiceRows: this.formInvoiceRows,
        });
      });
    },

    updateInvoiceField(value, rowIndex, columnIndex) {
      this.formInvoiceRows[rowIndex][columnIndex] = value;

      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument();
      });
    },

    toggleLock() {
      this.formLocked = !this.formLocked;

      this.persistDocument({
        locked: this.formLocked,
      });
    },

    toggleSerial() {
      this.formDocumentType = this.formDocumentType !== DOCUMENT_TYPES.SERIAL
        ? DOCUMENT_TYPES.SERIAL : DOCUMENT_TYPES.LETTER;

      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          documentType: this.formDocumentType,
        });
      });
    },

    toggleInvoice() {
      this.formDocumentType = this.formDocumentType !== DOCUMENT_TYPES.INVOICE
        ? DOCUMENT_TYPES.INVOICE : DOCUMENT_TYPES.LETTER;

      this.updatePreviewDocument(this.timeoutDelay).then(() => {
        this.persistDocument({
          documentType: this.formDocumentType,
        });
      });
    },

    exportDocument() {
      if (this.formDocumentType === DOCUMENT_TYPES.SERIAL) {
        this.toggleDialog('dialog-export-serial');
      } else {
        this.export();
      }
    },

    sendDocument() {
      if (this.currentUser.isActive) {
        this.toggleDialog('dialog-send');
      } else {
        this.messageDialog = {
          title: i18n.global.t('editor.activationRequired.title'),
          text: i18n.global.t('editor.activationRequired.text'),
        };

        this.toggleDialog('dialog-message');
      }
    },

    openDialogCompletion() {
      if (this.currentUser.isActive) {
        this.toggleDialog('dialog-completion');
      } else {
        this.messageDialog = {
          title: i18n.global.t('editor.activationRequired.title'),
          text: i18n.global.t('editor.activationRequired.text'),
        };

        this.toggleDialog('dialog-message');
      }
    },

    accessToken() {
      return !!Cookies.get('jwt-access');
    },

    launched() {
      return !!Cookies.get('launched');
    },

    viewedDocument() {
      return !!Cookies.get('viewed-document');
    },
  },

  mounted() {
    this.updatePreviewDocument();

    this.$nextTick(() => {
      if (!this.accessToken() && !this.launched() && !this.$route.query.m) {
        this.toggleDialog('dialog-intro');
      }
    });

    if (this.$route.query.checkoutSuccess) {
      this.messageDialog = {
        title: i18n.global.t('editor.checkoutSuccess.title'),
        text: i18n.global.t('editor.checkoutSuccess.text'),
      };

      this.toggleDialog('dialog-message');
    }

    if (this.$route.query.checkoutCanceled) {
      this.messageDialog = {
        title: i18n.global.t('editor.checkoutCanceled.title'),
        text: i18n.global.t('editor.checkoutCanceled.text'),
      };

      this.toggleDialog('dialog-message');
    }

    if (this.$route.query.send) {
      this.toggleDialog('dialog-send');
    }

    if (this.$route.query.m) {
      this.createDocumentFromExternalSource(this.$route.query.m);
    }
  },
};
