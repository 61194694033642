import DOCUMENT_DEFAULTS from './defaults';

export function mmToPx(value, dpi = 72) {
  return (value * dpi) / 25.4;
}

export function insertSubstring(text, string, index, rem) {
  return text.slice(0, index) + string + text.slice(index + Math.abs(rem));
}

export function removeSubstring(string, index) {
  return string.slice(0, index) + string.slice(index + 1);
}

export function formatText(string, selectionStart, selectionEnd, glyph) {
  const glyphs = Object.values(DOCUMENT_DEFAULTS.markupGlyphs);

  let currentGlyph;
  let result = string;

  // Inner selection between glyphs
  if (glyphs.includes(string[selectionStart - 1]) && glyphs.includes(string[selectionEnd])) {
    currentGlyph = string[selectionEnd];

    result = removeSubstring(result, selectionStart - 1);
    result = removeSubstring(result, selectionEnd - 1);

    if (glyph !== currentGlyph) {
      result = insertSubstring(result, glyph, selectionStart - 1, 0);
      result = insertSubstring(result, glyph, selectionEnd, 0);
    }
  // Outer selection with glyphs
  } else if (glyphs.includes(string[selectionStart]) && glyphs.includes(string[selectionEnd - 1])) {
    currentGlyph = string[selectionStart];

    result = removeSubstring(result, selectionStart);
    result = removeSubstring(result, selectionEnd - 2);

    if (glyph !== currentGlyph) {
      result = insertSubstring(result, glyph, selectionStart, 0);
      result = insertSubstring(result, glyph, selectionEnd - 1, 0);
    }
  // No glyphs selected
  } else {
    result = insertSubstring(result, glyph, selectionStart, 0);
    result = insertSubstring(result, glyph, selectionEnd + 1, 0);
  }

  return result;
}

export function insertPlaceholder(value, selectionStart, selectionEnd, placeholder) {
  return value === null
    ? placeholder : value.slice(0, selectionStart) + placeholder + value.slice(selectionEnd);
}

export function tokenizeText(input) {
  const text = input || '';
  const tokens = [];

  function addToken(token) {
    // Solves new line rendering behaviour of pdfkit.
    // Non-surrounding new lines are being split into
    // its discontinued tokens to ensure correct rendering.
    if (token.text[0] !== '\n'
      && token.text.slice(-1) !== '\n'
      && token.text.includes('\n')) {
      token.text.split('\n').forEach((line, i) => {
        tokens.push({
          ...token,

          text: line.length ? line : '\n',
          continued: i === token.text.split('\n').length - 1,
        });
      });
    } else {
      tokens.push({
        ...token,

        continued: token.text.slice(-1) !== '\n',
      });
    }
  }

  const bold = [...text.matchAll(/\*(.*?)\*/gs)].map((m) => ({ ...m, style: 'bold' }));
  const italic = [...text.matchAll(/\\(.*?)\\/gs)].map((m) => ({ ...m, style: 'italic' }));
  const underline = [...text.matchAll(/_(.*?)_/gs)].map((m) => ({ ...m, style: 'underline' }));
  const placeholder = [...text.matchAll(/#(.*?)#/gs)].map((m) => ({ ...m, style: 'placeholder' }));
  const matches = [...bold, ...italic, ...underline, ...placeholder]
    .sort((a, b) => a.index - b.index);

  if (matches.length) {
    matches.forEach((item, i) => {
      if (item.index > 0) {
        addToken({
          text: text.substring(
            i === 0 ? 0 : matches[i - 1].index + matches[i - 1][1].length + 2,
            item.index,
          ),
        });

        addToken({ text: item[1], style: item.style });
      } else {
        addToken({ text: item[1], style: item.style });
      }

      if (i === matches.length - 1) {
        addToken({ text: text.substring(item.index + item[1].length + 2, text.length) });
      }
    });
  } else {
    addToken({ text });
  }

  return tokens;
}

export function renderText(serialData, tokens, base, fontFamily, fontFamilyBold) {
  tokens.forEach((token, i) => {
    const continued = i < tokens.length - 1 && token.continued;

    if (token.style === 'bold') {
      base.font(fontFamilyBold)
        .text(token.text, {
          oblique: false,
          underline: false,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued,
        });
    } else if (token.style === 'italic') {
      base.font(fontFamily)
        .text(token.text, {
          oblique: true,
          underline: false,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued,
        });
    } else if (token.style === 'underline') {
      base.font(fontFamily)
        .text(token.text, {
          oblique: false,
          underline: true,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued,
        });
    } else if (token.style === 'placeholder') {
      if (Object.keys(serialData).includes(token.text)) {
        base.font(fontFamily)
          .text(serialData[token.text], {
            oblique: false,
            underline: false,
            lineGap: DOCUMENT_DEFAULTS.lineGap,
            continued,
          });
      } else {
        base.font('Courier-Bold')
          .fillColor('red')
          .text(token.text, {
            oblique: false,
            underline: false,
            lineGap: DOCUMENT_DEFAULTS.lineGap,
            continued,
          })
          .fillColor('black');
      }
    } else {
      base.font(fontFamily)
        .text(token.text, {
          oblique: false,
          underline: false,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued,
        });
    }
  });
}

export function renderTableFooter(
  serialData, document, position, data, width, fontFamily, fontFamilyBold,
) {
  const current = { ...position };

  data.forEach((value) => {
    const size = value.length;
    const columnWidth = width / size;

    current.x = position.x + width / 2 - (columnWidth / 2) * size;

    value.forEach((text) => {
      const textBase = document
        .text('', current.x, current.y)
        .text('', {
          width: columnWidth,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued: true,
        });

      renderText(
        serialData,
        tokenizeText(text),
        textBase, fontFamily, fontFamilyBold,
      );

      current.x += columnWidth;
    });
  });
}

const padding = { x: mmToPx(2.5), y: mmToPx(3) };
const columnWidths = [0.5, 0.125, 0.2, 0.175];

export function renderTableInvoice(
  document, position, data, width, fontSize, fontFamily, fontFamilyBold,
) {
  const current = { ...position };

  // Render main rows
  data.rows.forEach((row, rowIndex) => {
    current.x = position.x;

    const longestValue = [...row].sort((a, b) => b.length - a.length)[0];
    const threshold = '.......';
    const rowHeight = document.heightOfString(`${longestValue}${threshold}`, {
      width: columnWidths[row.indexOf(longestValue)] * width,
    }) + padding.y * 2;

    row.forEach((columnText, columnIndex) => {
      const columnWidth = width * columnWidths[columnIndex];

      if (rowIndex % 2) {
        document
          .rect(current.x, current.y, columnWidth, rowHeight)
          .fill('#f7f7f7');
      }

      const textBase = document
        .fill('black')
        .text('', current.x + padding.x, current.y + padding.y)
        .text('', {
          width: columnWidth - padding.x * 2,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
          continued: true,
        });

      renderText(
        {},
        tokenizeText(columnText),
        textBase, fontFamily, fontFamilyBold,
      );

      current.x += columnWidth;
    });

    current.y += rowHeight;

    document
      .moveTo(position.x, current.y - 1)
      .lineTo(position.x + width, current.y - 1)
      .strokeColor('#707f8f')
      .stroke();
  });

  // Render subrows
  current.y += mmToPx(5);

  const subWidth = 0.5 * width;
  const subcolumnWidths = [0.5, 0.5];

  (data.subrows || []).forEach((row, rowIndex) => {
    current.x = position.x + width - subWidth - padding.x;

    const longestValue = [...row].sort((a, b) => b.length - a.length)[0];
    const rowHeight = document.heightOfString(longestValue, {
      width: subcolumnWidths[row.indexOf(longestValue)] * subWidth,
    }) + padding.y;

    if (rowIndex === data.subrows.length - 1) {
      current.y += rowHeight * 0.5;
    }

    row.forEach((columnText, columnIndex) => {
      const columnWidth = subWidth * subcolumnWidths[columnIndex];

      let textBase = document
        .fill('gray')
        .fontSize(fontSize)
        .font(fontFamily);

      if (columnIndex % 2 !== 0) {
        textBase = document
          .fill('black')
          .fontSize(fontSize + 2)
          .font(fontFamilyBold);
      }

      if (rowIndex === data.subrows.length - 1) {
        document
          .fill('black')
          .fontSize(fontSize + 4)
          .font(fontFamilyBold);
      }

      textBase
        .text('', current.x + padding.x, current.y + padding.y)
        .text(columnText, {
          align: columnIndex % 2 === 0 ? 'left' : 'right',
          width: columnWidth - padding.x * 2,
          lineGap: DOCUMENT_DEFAULTS.lineGap,
        });

      current.x += columnWidth;
    });

    current.y += rowHeight;
  });
}

export function tablePages(document, data, width) {
  const pagedRows = [[]];
  const maxTableHeightPerPage = mmToPx(DOCUMENT_DEFAULTS.maxTextHeight);

  let pageIndex = 0;
  let tmpHeight = 0;

  data.rows.forEach((row) => {
    const longestValue = [...row].sort((a, b) => b.length - a.length)[0];
    const rowHeight = document.heightOfString(longestValue, {
      width: columnWidths[row.indexOf(longestValue)] * width,
    }) + padding.y * 2;

    if (tmpHeight + rowHeight < maxTableHeightPerPage - mmToPx(pageIndex === 0 ? 90 : 20)) {
      pagedRows[pageIndex].push(row);
      tmpHeight += rowHeight;
    } else {
      tmpHeight = 0;
      pageIndex += 1;
      pagedRows.push([]);
      pagedRows[pageIndex].push(row);
    }
  });

  return { pagedRows, tmpHeight };
}
