import { ref } from 'vue';

import i18n from '@/i18n';

import Button from '@/components/atoms/button/index.vue';
import Placeholder from '@/components/atoms/placeholder/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogManagePlaceholders',

  props: {
    open: Boolean,
  },

  components: {
    Button,
    Placeholder,

    Dialog,
  },

  setup() {
    const newPlaceholder = ref('');

    return {
      newPlaceholder,
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.currentUser;
    },

    currentDocument() {
      return this.$store.state.documents.currentDocument;
    },
  },

  methods: {
    addPlaceholder() {
      const payload = {
        id: this.currentDocument.id,
        data: {
          serialPlaceholders: [
            ...this.currentDocument.serialPlaceholders,

            this.newPlaceholder,
          ],
        },
      };

      if (this.currentUser) {
        this.$store.dispatch('updateDocument', payload);
      } else {
        this.$store.dispatch('updatePreviewDocument', { ...payload, locale: i18n.global.locale.value });
      }

      this.newPlaceholder = '';
    },

    deletePlaceholder(text) {
      const payload = {
        id: this.currentDocument.id,
        data: {
          serialPlaceholders: this.currentDocument.serialPlaceholders.filter(
            (placeholder) => placeholder !== text,
          ),
        },
      };

      if (this.currentUser) {
        this.$store.dispatch('updateDocument', payload);
      } else {
        this.$store.dispatch('updatePreviewDocument', { ...payload, locale: i18n.global.locale.value });
      }
    },
  },
};
