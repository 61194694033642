<template>
  <Dialog
    :open="open"
    :buttonConfirm="$t('global.done')"
  >
    <div class="editor__section editor__section--multiple">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="font-family"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.fontFamily.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.fontFamily.description') }}</p>
        </div>

        <select
          id="font-family"
          class="editor__select"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        >
          <option
            v-for="(key, index) in Object.keys(AVAILABLE_FONTS)"
            :key="index"
            :value="key"
          >
            {{ AVAILABLE_FONTS[key].name }}
          </option>
        </select>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
