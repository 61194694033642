import i18n from '@/i18n';

import Icon from '@/components/atoms/icon/index.vue';

export default {
  name: 'Preview',

  components: {
    Icon,
  },

  props: {
    source: String,
    fontFamily: String,
    fontSize: Number,
    norm: String,
    rendering: Boolean,
    updatedAt: Date,
    show: Boolean,
  },

  computed: {
    updatedText() {
      return this.updating ? i18n.global.t('preview.saving') : this.updatedAt && i18n.global.t('preview.savedAt', {
        date: this.updatedAt.toLocaleString(i18n.global.locale.value, { dateStyle: 'long', timeStyle: 'short' }),
      });
    },

    updating() {
      return this.$store.state.documents.updating;
    },
  },
};
