export default {
  products: {
    pinDomesticStandardBw: 'pin.domestic.standard.bw.2',
    pinDomesticStandardC: 'pin.domestic.standard.c.2',
    pinInternationalStandardBw: 'pin.international.standard.bw.2',
    pinInternationalStandardC: 'pin.international.standard.c.2',
    pinDomesticCompactBw: 'pin.domestic.compact.bw.2',
    pinDomesticCompactC: 'pin.domestic.compact.c.2',
    pinInternationalCompactBw: 'pin.international.compact.bw.2',
    pinInternationalCompactC: 'pin.international.compact.c.2',
  },
};
