<template>
  <Dialog
    class="intro-dialog t-inverted"
    :open="open"
    :modifier="'blue'"
    :buttonConfirm="$t('global.register')"
    :buttonCancel="$t('dialog.intro.cancel')"
    @open="initialize"
  >
    <div class="intro-dialog__logo-wrapper">
      <img class="intro-dialog__logo" src="@/assets/images/logo.svg" />
      <img class="intro-dialog__logo-batch" src="@/assets/images/batch-web.svg" />
    </div>

    <p class="intro-dialog__text">{{ $t('dialog.intro.text') }}</p>

    <img class="intro-dialog__illustration" src="@/assets/images/illustration-1.svg" />
  </Dialog>
</template>

<script src="./index.vue.js"></script>
