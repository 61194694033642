import { AVAILABLE_FONTS } from '@/services/document/defaults';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogFontFamily',

  props: {
    open: Boolean,
    modelValue: String,
  },

  components: {
    Dialog,
  },

  setup() {
    return {
      AVAILABLE_FONTS,
    };
  },
};
