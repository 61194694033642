import humps from 'humps';

import client from './client';

export default {
  create(data) {
    return client.post('/jobs/', humps.decamelizeKeys(data));
  },

  all() {
    return client.get('/jobs/');
  },

  update(id, data) {
    return client.patch(`/jobs/${id}/`, data);
  },
};
