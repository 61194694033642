import { mmToPx } from './utils';

class DocumentNorm {
  constructor(props = {}) {
    this.id = props.id;
    this.size = props.size || 'A4';
    this.margins = props.margins;
    this.letterhead = props.letterhead;
    this.address = props.address;
    this.recipient = props.recipient;
    this.memo = props.memo;
    this.info = props.info;
    this.mainContent = props.mainContent;
    this.foldingMarkers = props.foldingMarkers;
  }
}

export default {
  pin: new DocumentNorm({
    id: 'pin',

    margins: {
      top: 0, bottom: 0, left: mmToPx(25), right: mmToPx(20),
    },

    letterhead: {
      height: mmToPx(49),
    },

    address: {
      height: mmToPx(2.5),
      width: mmToPx(85),
    },

    memo: {
      height: mmToPx(12.5),
    },

    recipient: {
      height: mmToPx(27),
    },

    info: {
      margin: {
        top: 0,
      },
    },

    mainContent: {
      top: mmToPx(111),
    },

    foldingMarkers: {
      top: {
        y: mmToPx(87),
      },

      bottom: {
        y: mmToPx(192),
      },
    },
  }),

  din5008A: new DocumentNorm({
    id: 'din5008A',

    margins: {
      top: 0, bottom: 0, left: mmToPx(25), right: mmToPx(20),
    },

    letterhead: {
      height: mmToPx(27),
    },

    address: {
      height: mmToPx(5),
      width: mmToPx(80),
    },

    memo: {
      height: mmToPx(12.7),
    },

    recipient: {
      height: mmToPx(27.3),
    },

    info: {
      margin: {
        top: mmToPx(5),
      },
    },

    mainContent: {
      top: 0,
    },

    foldingMarkers: {
      top: {
        y: mmToPx(87),
      },

      bottom: {
        y: mmToPx(192),
      },
    },
  }),

  din5008B: new DocumentNorm({
    id: 'din5008B',

    margins: {
      top: 0, bottom: 0, left: mmToPx(25), right: mmToPx(20),
    },

    letterhead: {
      height: mmToPx(45),
    },

    address: {
      height: mmToPx(5),
      width: mmToPx(80),
    },

    memo: {
      height: mmToPx(17.7),
    },

    recipient: {
      height: mmToPx(27.3),
    },

    info: {
      margin: {
        top: mmToPx(5),
      },
    },

    mainContent: {
      top: 0,
    },

    foldingMarkers: {
      top: {
        y: mmToPx(105),
      },

      bottom: {
        y: mmToPx(210),
      },
    },
  }),

  usLetter: new DocumentNorm({
    id: 'usLetter',

    size: 'LETTER',
    margins: {
      top: 0, bottom: 0, left: mmToPx(25), right: mmToPx(20),
    },

    letterhead: {
      height: mmToPx(45),
    },

    address: {
      height: mmToPx(5),
      width: mmToPx(80),
    },

    memo: {
      height: mmToPx(17.7),
    },

    recipient: {
      height: mmToPx(27.3),
    },

    info: {
      margin: {
        top: mmToPx(5),
      },
    },

    mainContent: {
      top: 0,
    },

    foldingMarkers: {
      top: {
        y: mmToPx(87),
      },

      bottom: {
        y: mmToPx(192),
      },
    },
  }),
};
