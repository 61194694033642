<template>
  <div class="switch">
    <input
      class="switch__input"
      type="checkbox"
      :id="`switch-${$.uid}`"
      :checked="checked"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <label
      class="switch__label"
      :for="`switch-${$.uid}`"
      @click="() => $emit('update:checked', !checked)"
      tabindex="0"
      @keyup.enter="() => $emit('update:checked', !checked)"
    >
      {{ label }}
    </label>
  </div>
</template>

<script src="./index.vue.js"></script>
