<template>
  <Dialog
    class="create-dialog"
    :open="open"
    :buttonCancel="$t('global.cancel')"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            class="editor__fieldset-label"
          >
            {{ $t('dialog.documentCreate.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.documentCreate.description') }}</p>
        </div>
      </div>

      <div class="create-dialog__inner">
        <div
          tabindex="0"
          class="create-dialog__button"
          @click="$emit('createDocument', 0)"
          @keyup.enter="$emit('createDocument', 0)"
        >
          <Icon
            class="create-dialog__button-icon"
            name="document-letter-default"
          />
          <div class="create-dialog__button-inner">
            <h3 class="create-dialog__button-headline">
              {{ $t('dialog.documentCreate.letterHeadline') }}
            </h3>
            <p class="create-dialog__button-text">
              {{ $t('dialog.documentCreate.letterDescription') }}
            </p>
          </div>
        </div>

        <div
          class="create-dialog__button"
          :tabindex="!currentUser || currentUser && currentUser.subscription.type < 1 ? -1 : 0"
          @click="$emit('createDocument', 1)"
          @keyup.enter="$emit('createDocument', 1)"
        >
          <FeatureLock
            v-if="!currentUser || currentUser && currentUser.subscription.type < 1"
            :type="currentUser ? 0 : 2"
            @click.stop="currentUser ? $emit('openPaywall') : $emit('createDocument', 1)"
          />

          <Icon
            class="create-dialog__button-icon"
            name="document-serial-default"
          />
          <div class="create-dialog__button-inner">
            <h3 class="create-dialog__button-headline">
              {{ $t('dialog.documentCreate.serialHeadline') }}
            </h3>
            <p class="create-dialog__button-text">
              {{ $t('dialog.documentCreate.serialDescription') }}
            </p>
          </div>
        </div>

        <div
          class="create-dialog__button"
          :tabindex="!currentUser || currentUser && currentUser.subscription.type < 2 ? -1 : 0"
          @click="$emit('createDocument', 2)"
          @keyup.enter="$emit('createDocument', 2)"
        >
          <FeatureLock
            v-if="!currentUser || currentUser && currentUser.subscription.type < 2"
            :type="currentUser ? 1 : 2"
            @click.stop="currentUser ? $emit('openPaywall') : $emit('createDocument', 2)"
          />

          <Icon
            class="create-dialog__button-icon"
            :name="iconInvoice"
          />
          <div class="create-dialog__button-inner">
            <h3 class="create-dialog__button-headline">
              {{ $t('dialog.documentCreate.invoiceHeadline') }}
            </h3>
            <p class="create-dialog__button-text">
              {{ $t('dialog.documentCreate.invoiceDescription') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
