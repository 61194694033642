import client from './client';

export default {
  createProfile(data) {
    return client.post('/auth/users/', data);
  },

  getProfile() {
    return client.get('/auth/users/me/');
  },

  updateProfile(data) {
    return client.patch('/auth/users/me/', data);
  },

  updateProfileImage(formData) {
    return client.patch('/auth/users/me/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  activateProfile(data) {
    return client.post('/auth/users/activation/', data);
  },

  createJwt(data) {
    return client.post('/auth/jwt/create/', data);
  },
};
