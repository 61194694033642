<template>
  <Dialog
    class="manage-placeholders-dialog"
    :open="open"
    :buttonConfirm="$t('global.done')"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            class="editor__fieldset-label"
          >
            {{ $t('dialog.managePlaceholders.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.managePlaceholders.description') }}</p>
        </div>
      </div>

      <div class="editor__section">
        <div
          v-if="currentDocument.serialPlaceholders.length"
          class="editor__fieldset editor__fieldset--horizontal"
        >
          <label
            for="formPlaceholder"
            class="editor__fieldset-label"
          >{{ $t('dialog.managePlaceholders.all') }}</label>

          <div class="manage-placeholders-dialog__pills">
            <div
              class="manage-placeholders-dialog__pill"
              v-for="(text, index) in currentDocument.serialPlaceholders"
              :key="index"
            >
              <Placeholder
                tabindex="-1"
                :id="index"
                :text="text"
              />

              <button
                class="manage-placeholders-dialog__delete"
                @click="deletePlaceholder(text)"
              >✕</button>
            </div>
          </div>
        </div>

        <div class="editor__fieldset editor__fieldset--horizontal">
          <label
            for="formPlaceholder"
            class="editor__fieldset-label"
          >{{ $t('dialog.managePlaceholders.new') }}</label>
          <div>
            <input
              v-model="newPlaceholder"
              id="formPlaceholder"
              class="editor__input"
              :placeholder="$t('dialog.managePlaceholders.inputPlaceholder')"
            />
            <Button
              :text="$t('global.add')"
              :size="'small'"
              :disabled="!newPlaceholder.length"
              @click="addPlaceholder"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
