<template>
  <Dialog
    class="completion-dialog"
    :class="{ 'completion-dialog--loading': loading }"
    :open="open"
    :buttonConfirm="$t('global.done')"
  >
    <div
      v-if="loading"
      class="completion-dialog__loading"
    />

    <div class="editor__section">
      <div class="editor__section-desc">
        <label
          for="completion-type"
          class="editor__fieldset-label"
        >
          {{ $t('dialog.completion.headline') }}
        </label>
        <p class="editor__text">{{ $t('dialog.completion.description') }}</p>
      </div>
    </div>

    <div class="editor__section">
      <div class="completion-dialog__types">
        <label
          v-for="(value, index) in completionTypeOptions"
          class="completion-dialog__radio-label"
          :key="index"
          :for="`completion-radio-${index}`"
        >
          <template v-if="index < completionTypeOptions.length - 1">
            <input
              type="radio"
              class="completion-dialog__radio-input"
              :id="`completion-radio-${index}`"
              :value="value"
              v-model="completionType"
            />

            <span
              class="button button--small"
              :class="{ 'button--outline': completionType !== value }"
            >{{ value }}</span>
          </template>
          <template v-else>
            <div class="editor__fieldset editor__fieldset--horizontal">
              <input
                type="radio"
                class="completion-dialog__radio-input"
                :id="`completion-radio-${index}`"
                :value="value"
                v-model="completionType"
              />

              <span
                class="button button--small"
                :class="{ 'button--outline': completionType !== value }"
              >{{ $t('dialog.completion.custom.label') }}</span>

              <input
                class="editor__input"
                v-model="completionTypeText"
                :placeholder="$t('dialog.completion.custom.input')"
                :disabled="completionType !== 'custom'"
              />
            </div>
          </template>
        </label>
      </div>
    </div>

    <div class="editor__section">
        <div
          v-if="completionTags.length"
          class="editor__fieldset editor__fieldset--horizontal"
        >
          <label
            for="formCompletionTags"
            class="editor__fieldset-label"
          >{{ $t('dialog.completion.tags.all') }}</label>

          <div class="completion-dialog__pills">
            <div
              class="completion-dialog__pill"
              v-for="(text, index) in completionTags"
              :key="index"
            >
              <Placeholder
                tabindex="-1"
                :id="index"
                :text="text"
              />

              <button
                class="completion-dialog__delete"
                @click="deleteCompletionTag(index)"
              >✕</button>
            </div>
          </div>
        </div>

        <div class="editor__fieldset editor__fieldset--horizontal">
          <label
            for="formCompletionTags"
            class="editor__fieldset-label"
          >{{ $t('dialog.completion.tags.new') }}</label>
          <div>
            <input
              v-model="completionTagInput"
              id="formCompletionTags"
              class="editor__input"
              :placeholder="$t('dialog.completion.tags.input')"
            />
            <Button
              :text="$t('global.add')"
              :size="'small'"
              :disabled="!completionTagInput.length"
              @click="addCompletionTag"
            />
          </div>
        </div>
      </div>

    <div class="editor__section">
      <div class="editor__field">
        <Button
          class="completion-dialog__request-button"
          :text="$t('dialog.completion.request')"
          :size="'small'"
          :disabled="loading || completionType.length === 0"
          @click="requestCompletion"
        />
      </div>
    </div>

    <template v-if="suggestion.length">
      <div class="editor__section">
        <div class="editor__field editor__field--separated">
          <div class="editor__section-desc">

          <label
              for="completion-suggestion"
              class="editor__fieldset-label"
            >
              {{ $t('dialog.completion.suggestion.headline') }}
            </label>
          </div>
        </div>
      </div>
      <div class="editor__section">
        <div class="editor__fieldset">
          <textarea
            id="completion-suggestion"
            class="editor__textarea completion-dialog__textarea"
            v-model="suggestion"
            readonly
          />

          <div class="completion-dialog__textarea-button">
            <Button
              class="completion-dialog__button-apply"
              :text="$t('dialog.completion.apply')"
              :size="'small'"
              :style="'outline'"
              @click="$emit('applySuggestion', suggestion)"
            />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
