import { ref } from 'vue';

import Badge from '@/components/atoms/badge/index.vue';
import Icon from '@/components/atoms/icon/index.vue';

export default {
  name: 'FeatureLock',

  props: {
    type: {
      type: Number,
      default: 0,
    },

    backgroundColor: {
      type: String,
      default: 'ffffff',
    },
  },

  components: {
    Badge,
    Icon,
  },

  setup() {
    const el = ref(null);

    return {
      el,
    };
  },

  computed: {
    badgeType() {
      return ['pro', 'business', 'preview'][this.type];
    },
  },

  methods: {
    hexToRgb(hex) {
      const bigint = parseInt(hex, 16);

      // eslint-disable-next-line
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    },
  },

  mounted() {
    const backgroundColorRgb = this.hexToRgb(this.backgroundColor);

    this.el.parentNode.classList.add('f-is-feature-locked');
    this.el.style.backgroundColor = `rgba(${backgroundColorRgb[0]}, ${backgroundColorRgb[1]}, ${backgroundColorRgb[2]}, 0.75)`;
  },

  beforeUnmount() {
    this.el.parentNode.classList.remove('f-is-feature-locked');
  },
};
