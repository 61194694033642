<template>
  <Dialog
    :open="open"
    :buttonConfirm="$t('global.done')"
  >
    <div class="editor__section editor__section--multiple">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="letterhead"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.letterhead.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.letterhead.description') }}</p>
        </div>

        <input
          id="letterhead"
          type="checkbox"
          class="editor__checkbox"
          :checked="modelValue"
          @input="$emit('update:modelValue', $event.target.checked)"
        />
      </div>
    </div>

    <div class="editor__section editor__section--multiple">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="letterhead-alignment"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.letterhead.alignmentHeadline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.letterhead.alignmentDescription') }}</p>
        </div>

        <select
          id="letterhead-alignment"
          class="editor__select"
          :value="letterheadAlignment"
        >
          <option value="0">{{ $t('dialog.letterhead.alignmentTextLeft') }}</option>
          <option value="1">{{ $t('dialog.letterhead.alignmentTextCenter') }}</option>
          <option value="2">{{ $t('dialog.letterhead.alignmentTextRight') }}</option>
        </select>
      </div>
    </div>

    <div class="editor__section editor__section--multiple">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label class="button editor__input-button">
            <input
              class="editor__input editor__input--file"
              type="file"
              accept="image/jpeg, image/png"
              @click="$event.target.value = null"
              @change="updateImage"
            />

            {{ $t('dialog.letterhead.fileInput') }}
          </label>

          <p class="editor__text editor__text--small">{{ $t('dialog.letterhead.fileText') }}</p>
        </div>
      </div>

      <div v-if="letterheadImage" class="editor__field">
        <img :src="letterheadImage" width="200" />
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
