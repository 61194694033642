import client from './client';

export default {
  create(formData) {
    return client.post('/attachments/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  get(id) {
    return client.get(`/attachments/${id}/`);
  },

  all() {
    return client.get('/attachments/');
  },

  update(id, data) {
    return client.patch(`/attachments/${id}/`, data);
  },

  delete(id) {
    return client.delete(`/attachments/${id}/`);
  },
};
