<template>
  <div
    class="input-search"
    :class="{ 'input-search--active': active }"
  >
    <Icon class="input-search__icon" name="search" />

    <input
      class="input-search__input"
      :value="modelValue"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="active = true"
      @blur="active = false"
    />
  </div>
</template>

<script src="./index.vue.js"></script>
