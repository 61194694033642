<template>
  <Dialog
    :open="open"
    :buttonConfirm="$t('global.ok')"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label class="editor__fieldset-label">{{ title }}</label>
          <p class="editor__text">{{ text }}</p>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
