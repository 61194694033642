<template>
  <Dialog
    :open="open"
    :buttonCancel="$t('global.cancel')"
    :buttonCancelDisabled="!!progress"
    :buttonConfirm="$t('global.export')"
    :buttonConfirmDisabled="!!progress || !(serialImportFile && serialImportFile.length)"
  >
    <div class="editor__section">
      <div class="editor__field">
        <div class="editor__section-desc">
          <label
            for="serial-data"
            class="editor__fieldset-label"
          >
            {{ $t('dialog.exportSerial.headline') }}
          </label>
          <p class="editor__text">{{ $t('dialog.exportSerial.description') }}</p>

          <Placeholder
            v-for="(text, index) in serialPlaceholders"
            :key="index"
            :id="index"
            :text="text"
          />
        </div>
      </div>

      <div
        v-if="serialImportFile && serialImportFile.length"
        class="editor__field editor__field--table"
      >
        <table class="editor__table editor__table--serial">
          <thead>
            <tr>
              <th class="editor__table-headline"></th>
              <th
                class="editor__table-headline"
                v-for="(header, index) in Object.keys(serialImportFile[0])"
                :key="index"
              >
                <label class="editor__fieldset-label">{{ header }}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in serialImportFile"
              :key="rowIndex"
            >
              <td class="editor__table-datum">
                <span v-if="progress === rowIndex">Processing</span>
                <span v-if="progress > rowIndex">Done</span>
                <span v-if="!progress">-</span>
              </td>
              <td
                class="editor__table-datum"
                v-for="(column, columnIndex) in Object.values(row)"
                :key="columnIndex"
              >
                {{ column }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="editor__field">
        <div class="editor__section-desc">
          <label class="button editor__input-button">
            <input
              id="serial-data"
              class="editor__input editor__input--file"
              type="file"
              accept=".csv"
            />

            {{ $t('dialog.exportSerial.fileInput') }}
          </label>

          <p class="editor__text editor__text--small">{{ $t('dialog.exportSerial.fileText') }}</p>
        </div>
      </div>

      <Button
        v-if="progress"
        :text="$t('global.cancel')"
        :style="'danger'"
        @click="progress = null"
      />
    </div>
  </Dialog>
</template>

<script src="./index.vue.js"></script>
