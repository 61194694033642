import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import Document from '@/services/document';

import DOCUMENT_NORMS from '@/services/document/norms';
import DOCUMENT_TYPES from '@/services/document/types';

export default {
  computed: {
    filename() {
      if ([DOCUMENT_TYPES.LETTER, DOCUMENT_TYPES.SERIAL].includes(this.formDocumentType)
        && this.formSubject.length) {
        return this.formSubject;
      }

      if (this.formDocumentType === DOCUMENT_TYPES.INVOICE) {
        return this.formInvoiceId && this.formInvoiceId.length ? this.formInvoiceId : `easyletter-invoice-${this.formDate}`;
      }

      return `easyletter-document-${this.formDate}`;
    },
  },

  methods: {
    export() {
      this.previewDocument.render().then(() => {
        this.previewDocument.stream.on('finish', () => {
          saveAs(this.previewDocument.stream.toBlob(), `${this.filename}.pdf`, { type: 'application/pdf' });
        });
      });
    },

    async exportSerial() {
      if (this.formSerialImportFile) {
        const zip = new JSZip();

        await Promise.all(
          this.formSerialImportFile.map((data, index) => new Promise((resolve) => {
            if (index > 0 && this.exportProgress === null) return;

            this.exportProgress = index;

            const document = this.updateDocument(new Document());
            document.serialData = data;
            document.norm = DOCUMENT_NORMS.pin;

            document.render().then(() => {
              document.stream.on('finish', () => {
                zip.file(`${this.filename}_${index}.pdf`, document.stream.toBlob());

                resolve();
              });
            });
          })),
        );

        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${this.filename}.zip`);
        });
      }

      this.exportProgress = null;
      this.previewDocument.serialData = {};
      this.$store.dispatch('setCurrentDialog', null);
    },

    updateSerialImportFile($event) {
      if ($event.target.files.length) {
        const file = $event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
          this.formSerialImportFile = this.formatCsv(reader.result);
        };

        reader.readAsText(file, 'UTF-8');
      } else {
        this.formSerialImportFile = null;
      }
    },

    formatCsv(data) {
      const rows = data.split('\n');
      const header = rows[0];
      const entries = rows.slice(1)
        .filter((row) => row.length);

      const keys = header.split(',');

      return entries.map((entry) => {
        const values = entry.split(',');

        return Object.assign({}, ...keys.map((key, index) => ({ [key]: values[index] })));
      });
    },
  },
};
