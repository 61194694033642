<template>
  <dialog
    ref="dialog"
    tabindex="0"
    class="dialog"
    :class="{
      'dialog--initialized': initialized,
      [`dialog--${modifier}`]: modifier,
    }"
    :open="open || null"
  >
    <div class="dialog__content">
      <slot />
    </div>

    <footer
      v-if="buttonCancel || buttonConfirm"
      class="dialog__footer"
    >
      <Button
        v-if="buttonCancel"
        :text="buttonCancel || $t('global.cancel')"
        :style="'outline'"
        :disabled="buttonCancelDisabled"
        @click="$emit('cancel')"
      />

      <Button
        v-if="buttonConfirm"
        :text="buttonConfirm || $t('global.done')"
        :style="buttonConfirmStyle"
        :disabled="buttonConfirmDisabled"
        @click="$emit('confirm')"
      />
    </footer>
  </dialog>
</template>

<script src="./index.vue.js"></script>
