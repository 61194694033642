import { ref } from 'vue';

import Icon from '@/components/atoms/icon/index.vue';

export default {
  name: 'InputSearch',

  props: {
    modelValue: String,
    placeholder: String,
  },

  components: {
    Icon,
  },

  setup() {
    const active = ref(false);

    return {
      active,
    };
  },
};
