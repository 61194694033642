const MAX_COLORS = 5;

export default {
  name: 'Placeholder',

  props: {
    text: String,
    id: Number,
  },

  computed: {
    recurringId() {
      return this.id % MAX_COLORS;
    },
  },
};
