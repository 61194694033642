<template>
  <div
    class="register"
    :class="{ 'register--requesting': requesting }"
  >
    <img
      class="login__illustration login__illustration--left"
      src="@/assets/images/illustration-2.svg"
    />

    <img
      class="login__illustration login__illustration--right"
      src="@/assets/images/illustration-3.svg"
    />

    <transition>
      <div v-if="!requesting">
        <div class="login__container">
        <div class="login__container-inner">
          <header class="login__header">
            <div class="login__logo-wrapper">
              <img class="login__logo" src="@/assets/images/logo.svg" />
              <img class="login__logo login__logo--shadow" src="@/assets/images/logo.svg" />
              <img class="login__logo-batch" src="@/assets/images/batch-web.svg" />
            </div>

            <p class="login__slogan">{{ $t('global.slogan') }}</p>
          </header>

          <form class="login__form" @submit.prevent="register">
            <div class="login__fieldset">
              <label class="login__label" for="email">{{ $t('global.email') }}</label>
              <input
                id="email"
                class="login__input"
                v-model="email"
                type="email"
                :placeholder="$t('global.emailPlaceholder')"
                required
              />
            </div>

            <div class="login__fieldset">
              <label class="login__label" for="password">{{ $t('global.password') }}</label>
              <input
                id="password"
                class="login__input"
                v-model="password"
                type="password"
                :placeholder="$t('global.passwordPlaceholder')"
                autocomplete="off"
                required
              />
            </div>

            <div v-if="errors.length" class="login__fieldset login__fieldset--info">
              <p
                class="login__text login__text--small"
                v-for="(error, index) in errors"
                :key="index"
              >{{ $t(`register.errors.message${error}`) }}</p>
            </div>

            <div class="login__fieldset login__fieldset--info">
              <p
                class="login__text login__text--disclaimer"
                v-html="$t('register.disclaimer')"
              />
            </div>

            <footer class="login__actions t-inverted">
              <Button
                class="login__submit"
                type="submit"
                :text="$t('global.registerAccount')"
                :size="'large'"
              />

              <hr class="login__divider" />

              <p class="login__text">{{ $t('register.alreadyRegistered') }}</p>

              <Button
                class="login__submit"
                :text="$t('global.login')"
                :style="'outline'"
                :size="'large'"
                @click="$router.push({ name: 'login' })"
              />
            </footer>
          </form>
        </div>
      </div>

        <footer class="login__footer">
          <a class="login__footer-link" :href="`https://www.jungspree.com/${$i18n.locale}/privacy-policy/`" target="_blank">{{ $t('global.privacyPolicy') }}</a>
          &middot;
          <a class="login__footer-link" :href="`https://www.jungspree.com/${$i18n.locale}/imprint/`" target="_blank">{{ $t('global.imprint') }}</a>
        </footer>
      </div>
    </transition>
  </div>
</template>

<script src="./index.vue.js"></script>
