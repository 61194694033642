import { createStore } from 'vuex';
import app from './app';
import auth from './auth';
import editor from './editor';
import documents from './documents';
import templates from './templates';

export default createStore({
  modules: {
    app,
    auth,
    editor,
    documents,
    templates,
  },
});
