export default {
  "meta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyLetter - Briefe und Rechnungen erstellen und versenden"])}
  },
  "global": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile hinzufügen"])},
    "addColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte hinzufügen"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "registerFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos registrieren"])},
    "registerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
    "registerFreeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloses Konto erstellen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "openSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste öffnen"])},
    "closeSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenleiste schließen"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echte Briefe erstellen und senden – einfach und überall."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort"])}
  },
  "dialog": {
    "background": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefpapier anzeigen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie diese Option, um das Hintergrundbild im Dokument anzuzeigen"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild auswählen …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Auflösung für DIN 5008: 210 x 297 mm, 2480 x 3508 px, max. 150 kB"])}
    },
    "completion": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligenter Textassistent"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Briefart und beliebige Schlagworte aus die berücksichtigt werden sollen, um einen Textvorschlag zu erhalten. Diese Funktion ist experimentell und Sie sollten das Ergebnis vor dem Absenden überprüfen."])},
      "custom": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Art"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefart"])}
      },
      "tags": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Schlagwort"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Schlagwörter"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema, Inhalt"])}
      },
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textvorschlag anfragen"])},
      "suggestion": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generierter Textvorschlag"])}
      },
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textvorschlag anwenden"])}
    },
    "documentCreate": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Dokument"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Art des Dokuments aus, das Sie erstellen möchten. Sie können die Art des erstellten Dokuments später ändern"])},
      "letterHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfacher Brief"])},
      "letterDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionelle Briefvorlage nach DIN 5008 mit allen essenziellen Eingabefeldern und Gestaltungsoptionen"])},
      "serialHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienbrief"])},
      "serialDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Briefvorlage mit Verwaltung eigener Platzhalter und CSV-Import zur Erstellung von Serienbriefen"])},
      "invoiceHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung / Angebot"])},
      "invoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenerfassung zur Rechnungslegung und Angebotserstellung in Kombination mit Textinhalten und allen Gestaltungsoptionen"])}
    },
    "documentDelete": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument löschen"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie <strong>", _interpolate(_named("subject")), "</strong> wirklich löschen?"])}
    },
    "exportSerial": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienbrief exportieren"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Datenquelle aus, die für den Serienexport des Dokuments genutzt werden soll"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Datei auswählen …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achten Sie darauf, dass die von Ihnen im Dokument angelegten Platzhalter mit den Spaltennamen aus der Datei übereinstimmen"])}
    },
    "fontFamily": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftart ändern"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Schriftart aus der Liste aus"])}
    },
    "intro": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Gast fortfahren"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei EasyLetter! Erstellen Sie professionelle Dokumente für alle Zwecke - privat oder geschäftlich."])}
    },
    "introSend": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefe einfach versenden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie die praktische Versandfunktion, um Ihre Dokumente als <strong>echten Brief</strong> zu versenden.<br /><br />Übermitteln Sie Ihre Briefe in nur wenigen Klicks. Die <strong>PIN AG</strong> druckt, kuvertiert, frankiert und stellt Ihre Briefe zu."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstanden"])}
    },
    "letterhead": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefkopf anzeigen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie diese Option, um den Briefkopf im Dokument anzeigen zu lassen"])},
      "alignmentHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausrichtung"])},
      "alignmentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position auswählen"])},
      "alignmentTextLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
      "alignmentTextCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitte"])},
      "alignmentTextRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild auswählen …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Auflösung für DIN 5008: 205 x 22 mm, 2421 x 260 px, max. 150 kB"])}
    },
    "managePlaceholders": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzhalter bearbeiten"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen und entfernen Sie Platzhalter für die Befüllung Ihres Serienbriefes"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelegte Platzhalter"])},
      "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebige Eingabe"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Platzhalter"])}
    },
    "paywall": {
      "pro": {
        "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rundum professionell"])},
        "argument0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>5 Sendungen</strong> pro Monat inklusive<sup><strong>*</strong></sup>"])},
        "argument1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Briefpapier</strong> und <strong>Unterschriften</strong> einfügen"])},
        "argument2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Textformatierungen inklusive <strong>Fett</strong>, <i>Kursiv</i> und <u>Unterstrichen</u>"])},
        "argument3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftgröße und -art ändern"])},
        "argument4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Serienbriefe erstellen</strong> - Verwenden Sie eigene Platzhalter und CSV-Dateien, um echte Serienbriefe zu generieren und als <strong>PDF</strong> zu exportieren"])},
        "argument5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente für weitere Bearbeitung <strong>sperren</strong>"])},
        "kicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebteste Option"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt freischalten für monatlich"])},
        "price": {
          "credits0": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3,49 €"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2,91 €"])}
          },
          "credits5": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9,99 €"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7,99 €"])}
          }
        },
        "switch": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungszeitraum"])},
          "text": {
            "credits0": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>20&#8198;%</em> Rabatt bei jährlicher Zahlung"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jährliche Zahlung · 34,99 €"])}
            },
            "credits5": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>25&#8198;%</em> Rabatt bei jährlicher Zahlung"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jährliche Zahlung · 95,88 €"])}
            }
          }
        },
        "priceFooter": {
          "credits0": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3,49 €"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% sparen"])}
          },
          "credits5": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9,99 €"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25% sparen"])}
          }
        },
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter als PRO"])}
      },
      "business": {
        "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles für Ihr Geschäft"])},
        "argument0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>15 Sendungen</strong> pro Monat inklusive<sup><strong>*</strong></sup>"])},
        "argument1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Rechnungen</strong> sowie <strong>Angebote</strong> erstellen und versenden"])},
        "argument2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Serienbriefe erstellen</strong> - Verwenden Sie eigene Platzhalter und CSV-Dateien, um echte Serienbriefe zu generieren und als <strong>PDF</strong> zu exportieren"])},
        "argument3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Briefpapier</strong> und <strong>Unterschriften</strong> einfügen"])},
        "argument4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftgröße und -art ändern"])},
        "argument5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… und alle Funktionen von <i>Easy</i>Letter <strong>PRO</strong>"])},
        "kicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voller Funktionsumfang"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt freischalten für monatlich"])},
        "price": {
          "credits0": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5,99 €"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4,99 €"])}
          },
          "credits15": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19,99 €"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16,99 €"])}
          }
        },
        "switch": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungszeitraum"])},
          "text": {
            "credits0": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>20&#8198;%</em> Rabatt bei jährlicher Zahlung"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jährliche Abrechnung · 59,99 €"])}
            },
            "credits15": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>20&#8198;%</em> Rabatt bei jährlicher Zahlung"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jährliche Abrechnung · 203,88 €"])}
            }
          }
        },
        "priceFooter": {
          "credits0": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4,99 €"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% sparen"])}
          },
          "credits15": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19,99 €"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% sparen"])}
          }
        },
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter mit BUSINESS"])}
      },
      "credits": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Sendungsmenge<sup>*</sup>"])},
        "option0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
        "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Sendungen"])},
        "option15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 Sendungen"])}
      },
      "footer0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<sup><strong>*</strong></sup>Anzahl der Sendungen gilt pro Kalendermonat und umfasst Sendungen mit Druck in schwarzweiß, DIN A4, 1 – 3 Seiten, Versand deutschlandweit. Das Guthaben wird am Anfang eines Kalendermonats zurückgesetzt. Nicht genutzte Guthaben verfallen und werden nicht mit Guthaben aus dem Vormonat addiert oder verrechnet."])}
    },
    "upsellPro": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine PRO-Funktion entdeckt"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie <b>beliebig viele Dokumente</b> und nutzen Sie alle <strong>PRO</strong>-Funktionen von <i>Easy</i>Letter."])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
    },
    "send": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief senden"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein für Sie passendes Produkt aus und versenden Sie Ihren Brief. Bei Empfängeradressen außerhalb Deutschlands muss der internationale Versand verwendet werden"])},
      "noAvailableProducts": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Produkte verfügbar"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die ausgewählte Seitenanzahl steht aktuell kein Produkt zur Verfügung. Reduzieren Sie die Anzahl der Seiten, um den Brief versenden zu können."])}
      },
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inkl. MwSt."])},
      "buyMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr kaufen"])},
      "buyCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben kaufen"])},
      "useCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Guthaben verwenden & senden"])},
      "pinDomesticStandardBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardbrief (schwarzweiß)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerdeutscher Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 Seiten · schwarzweiß · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1,89 €"])}
      },
      "pinDomesticStandardC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardbrief (farbig)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerdeutscher Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 Seiten · farbig · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2,29 €"])}
      },
      "pinInternationalStandardBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardbrief (schwarzweiß)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationaler Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 Seiten · schwarzweiß · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2,49 €"])}
      },
      "pinInternationalStandardC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardbrief (farbig)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationaler Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 Seiten · farbig · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2,69 €"])}
      },
      "pinDomesticCompactBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaktbrief (schwarzweiß)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerdeutscher Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 Seiten · schwarzweiß · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3,49 €"])}
      },
      "pinDomesticCompactC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaktbrief (farbig)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerdeutscher Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 Seiten · farbig · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3,99 €"])}
      },
      "pinInternationalCompactBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaktbrief (schwarzweiß)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationaler Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 Seiten · schwarzweiß · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5,49 €"])}
      },
      "pinInternationalCompactC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaktbrief (farbig)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationaler Versand mit PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 Seiten · farbig · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5,99 €"])}
      },
      "attachments": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Anhänge für den Versand hinzu"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ihre Sendung umfasst <b>", _interpolate(_named("totalPages")), " Seiten</b> inkl. ", _interpolate(_named("count")), " Seite im Anhang"]), _normalize(["Ihre Sendung umfasst <b>", _interpolate(_named("totalPages")), " Seiten</b> inkl. ", _interpolate(_named("count")), " Seiten im Anhang"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen …"])}
      },
      "conditions": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandbedingungen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indem Sie auf <b>Senden</b> klicken, stimmen Sie den <a href=\"javascript:alert('Im Preis enthalten sind Druck, Kuvertierung, Frankierung und Versand mit eBrief der PIN AG. Indem Sie auf Senden tippen, stimmen Sie zu, dass wir Ihren Auftrag sofort ausführen dürfen. Nach Beginn der Ausführung können Sie Ihre Bestellung nicht mehr widerrufen. Der Briefversand erfolgt aus Deutschland und dauert europaweit in der Regel 1 – 3 Tage. Bei weltweitem Versand kann sich die Dauer verlängern.\n\nDas Anschriftfeld wird in einer Standardschrift und -größe gedruckt, um die Lesbarkeit für den Briefdienstleister zu gewährleisten. Als Auftraggeber haben Sie sicherzustellen, dass die in Auftrag gegebene Sendung eine vollständige und korrekte Adressierung aufweist.')\"><u>Versandbedingungen</u></a> und der <a href=\"https://www.jungspree.com/de/privacy-policy/\" target=\"_blank\"><u>Datenschutzerklärung</u></a> zu."])}
      },
      "success": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief wurde übermittelt"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Fragen zur Verarbeitung Ihrer Sendung können Sie die angezeigte Referenznummer verwenden. Die Nummer ermöglicht keine Sendungsverfolgung."])}
      },
      "error": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte versuchen Sie es später nocheinmal."])}
      }
    },
    "attachments": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge hinzufügen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Dokumente und Bilder aus, die Sie an die Sendung anfügen möchten. Bilder werden automatisch auf DIN A4 angelegt."])},
      "totalPages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Seite"]), _normalize([_interpolate(_named("count")), " Seiten"])])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIN A4, max. 2 MB"])},
      "previewText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Anhang aus, um die Vorschau zu sehen"])},
      "errors": {
        "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Dateigröße ist 2 MB"])},
        "filePageFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine oder mehrere Seiten der Datei haben nicht das Format DIN A4 (210 × 297 mm)."])}
      }
    },
    "signature": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift anzeigen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie diese Funktion, um die Unterschrift im Dokument anzuzeigen"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild auswählen …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Auflösung: 35 x 20 mm, 99 x 57 px, max. 150 kB"])}
    },
    "templates": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vorlagen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen und Verwalten Sie Vorlagen für Ihre Dokumente"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
      "type0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief"])},
      "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienbrief"])},
      "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Vorlage"])},
      "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Vorlage"])}
    },
    "userSettings": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Daten und Abonnements"])},
      "profileImageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild ändern …"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "managePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement verwalten"])},
      "showPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteile ansehen"])},
      "proPlan": {
        "headline": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind ein PRO"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werden Sie ein PRO"])}
        },
        "intro": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie genießen alle Vorteile dieser Version"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahren Sie die Vorteile von EasyLetter PRO"])}
        }
      },
      "businessPlan": {
        "headline": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr BUSINESS-Plan"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groß im Geschäft"])}
        },
        "intro": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie genießen alle Vorteile von EasyLetter BUSINESS"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahren Sie die Vorteile von EasyLetter BUSINESS"])}
        }
      }
    }
  },
  "featureLock": {
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRO"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUSINESS"])}
  },
  "menu": {
    "addPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite einfügen"])},
    "removeLastPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Seite entfernen"])},
    "lockDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument sperren"])},
    "unlockDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument entsperren"])},
    "formatBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fett"])},
    "formatItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursiv"])},
    "formatUnderline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstrichen"])},
    "changeFontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftgröße ändern"])},
    "updateFontFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftart ändern"])},
    "doInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erstellen"])},
    "undoInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung entfernen"])},
    "doSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienbrief erstellen"])},
    "undoSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienbrief entfernen"])},
    "managePlaceholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzhalter …"])}
  },
  "preview": {
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen werden gespeichert …"])},
    "savedAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gespeichert am ", _interpolate(_named("date"))])}
  },
  "sidebar": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dokumente"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen!"])},
    "openUserSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil anzeigen"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff, Adresse, Datum …"])},
    "documentSentAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dokument versendet am ", _interpolate(_named("date"))])},
    "documentNotSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument noch nicht versendet"])},
    "documentLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument gesperrt"])},
    "documentDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument löschen"])},
    "wallHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente speichern und verwalten"])},
    "wallText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich, um Dokumente von überall aus abrufen zu können"])}
  },
  "startpage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei <i>Easy</i><strong>Letter</strong>!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Dokument oder öffnen Sie die Seitenleiste, um Ihr Profil zu sehen oder ein gespeichertes Dokument zu bearbeiten"])},
    "textNoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie oben links auf <b>Neues Dokument</b>, um ein Dokument in der Vorschau zu erstellen oder melden Sie sich hier an:"])},
    "documents": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bearbeitet"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument erstellen"])}
    }
  },
  "toolbar": {
    "newDocument": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Dokument erstellen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Dokument"])}
    },
    "myTemplates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen verwalten"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vorlagen"])}
    },
    "exportDocument": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument exportieren"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])}
    },
    "sendDocument": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument versenden"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief senden"])}
    }
  },
  "editor": {
    "newDocumentDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Dokument"])},
    "newProductDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
    "form": {
      "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absender"])},
      "senderPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse des Absenders"])},
      "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
      "recipientPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse des Empfängers"])},
      "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
      "memoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine beliebige Notiz die unter der Adresse erscheint"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
      "infoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Kontaktdaten & Informationen"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsnummer"])},
      "taxRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
      "subjectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
      "textPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nachricht"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußzeile"])},
      "footerPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalteninhalt"])},
      "invoice": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
        "pricePerUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Einheit"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
        "applyTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer anwenden"])}
      }
    },
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassen …"])},
    "backgroundHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefpapier"])},
    "backgroundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie ein Hintergrundbild als Briefpapier hinzu"])},
    "letterheadHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefkopf"])},
    "letterheadDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisieren Sie Ihren Brief, indem Sie einen Briefkopf hinzufügen"])},
    "signatureHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift"])},
    "signatureDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine Unterschrift hinzu"])},
    "foldingMarkersHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falzmarken"])},
    "foldingMarkersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzt Faltmarkierungen am linken Seitenrand"])},
    "pageNumbersHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seitenzahlen"])},
    "pageNumbersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blendet die Seitennummerierung ein oder aus"])},
    "formTypeHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
    "formTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legt das Seitenformat fest"])},
    "activationRequired": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Aktivierung erforderlich"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-Mail-Adresse, um diese Funktion nutzen zu können"])}
    },
    "checkoutSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgang abgeschlossen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viel Spaß mit Ihrem Produkt!"])}
    },
    "checkoutCanceled": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgang abgebrochen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kaufvorgang wurde abgebrochen …"])}
    }
  },
  "login": {
    "registered": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse bestätigen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr EasyLetter-Konto wurde erfolgreich erstellt. Sie haben eine E-Mail zur vollständigen Kontoaktivierung erhalten. Bitte klicken Sie auf den Link in dieser E-Mail, um Ihr Konto zu aktivieren."])}
    },
    "activated": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto aktiviert"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr EasyLetter-Konto wurde erfolgreich aktiviert. Sie können jetzt den Briefversand nutzen."])}
    },
    "logout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgemeldet"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben sich erfolgreich abgemeldet."])}
    },
    "errors": {
      "messageNoActiveAccountFoundWithTheGivenCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse und/oder Passwort nicht korrekt"])}
    }
  },
  "register": {
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In unserer <a href=\"https://www.jungspree.com/de/privacy-policy/\" target=\"_blank\"><u>Datenschutzerklärung</u></a> erfahren Sie, wie wir Daten erfassen und verwenden."])},
    "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits registriert?"])},
    "errors": {
      "messageuserProfileWithThisEmailAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto mit dieser E-Mail existiert bereits"])},
      "messageThisPasswordIsTooShortItMustContainAtLeast8Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens 8 Zeichen enthalten"])},
      "messageThisPasswordIsEntirelyNumeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf nicht ausschließlich aus Zahlen bestehen"])},
      "messageThePasswordIsTooSimilarToTheEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf der E-Mail-Adresse nicht zu ähnlich sein"])}
    }
  },
  "norms": {
    "din5008A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIN 5008 (Form A)"])},
    "din5008B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIN 5008 (Form B)"])},
    "usLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US Letter"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN"])}
  },
  "document": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungs-Nr."])},
    "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Seite ", _interpolate(_list(0)), " von ", _interpolate(_list(1))])}
  }
}