import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogDocumentDelete',

  props: {
    open: Boolean,
    document: Object,
  },

  components: {
    Dialog,
  },
};
