<template>
  <button class="feature-lock" ref="el">
    <div class="feature-lock__inner">
      <Icon class="feature-lock__icon feature-lock__icon--star" name="star" />
      <Badge :text="$t(`featureLock.${badgeType}`)" :style="badgeType" />
    </div>
  </button>
</template>

<script src="./index.vue.js"></script>
