export const BREAKPOINTS = {
  s: '0px',
  m: '580px',
  l: '850px',
  xl: '1190px',
  xxl: '1540px',
};

export const TRANSITION_TIMES = {
  root: 200,
  long: 500,
};
