<template>
  <div class="startpage" :class="{ 'startpage--initialized': !requesting }">
    <img class="startpage__illustration" src="@/assets/images/illustration-0.svg" />

    <div class="startpage__inner">
      <template v-if="!requesting">
        <h1
          class="startpage__headline"
          v-html="$t('startpage.headline')"
        />
        <p
          class="startpage__text"
          v-html="currentUser ? $t('startpage.text') : $t('startpage.textNoUser')"
        />
      </template>

      <div class="startpage__documents" v-if="currentUser && documents.length">
        <h2 class="startpage__documents-headline">
          {{ $t('startpage.documents.headline') }}
        </h2>
        <ul class="startpage__documents-list">
          <li
          v-for="(document, index) in documents"
            :key="index"
            class="startpage__documents-item"
            tabindex="0"
            @click="selectDocument(document)"
            @keyup.enter="selectDocument(document)"
          >
            <Icon
              class="startpage__documents-item-icon"
              :name="`${formattedIconName(document.documentType)}-default`"
            />

            <div class="startpage__documents-item-content">
              <h3 class="startpage__documents-item-headline">{{ document.subject }}</h3>
              <p class="startpage__documents-item-text" v-html="formattedItemText(document)" />
            </div>
          </li>
        </ul>
      </div>

      <div class="startpage__actions">
        <template v-if="currentUser">
          <Button
            class="startpage__button"
            :text="$t('global.openSidebar')"
            :style="'outline'"
            @click="openSidebar"
          />

          <Button
            class="startpage__button"
            :text="$t('startpage.documents.create')"
            @click="toggleDialog('dialog-document-create')"
          />
        </template>

        <template v-else>
          <Button
            class="startpage__button"
            :text="$t('global.registerFree')"
            :style="'outline'"
            @click="$router.push({ name: 'register' })"
          />

          <Button
            class="startpage__button"
            :text="$t('global.login')"
            @click="$router.push({ name: 'login' })"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script src="./index.vue.js"></script>
