import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import axios from 'axios';

import i18n from '@/i18n';
import api from '@/api';

import Attachment from '@/services/document/attachment';

import Button from '@/components/atoms/button/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogAttachments',

  props: {
    open: Boolean,
  },

  components: {
    Button,

    Dialog,
  },

  setup() {
    const store = useStore();

    const loading = ref(false);
    const selectedAttachment = ref(null);
    const selectedAttachmentSource = ref(null);
    const errorMessage = ref(null);

    const currentDocument = computed(() => store.state.documents.currentDocument);
    const sortedAttachments = computed(() => (currentDocument.value?.attachments || []).sort(
      (a, b) => a.createdAt - b.createdAt,
    ));

    const selectedAttachmentChange = () => {
      selectedAttachmentSource.value = null;

      if (selectedAttachment.value?.filename) {
        if (selectedAttachment.value.filename.toLowerCase().split('.').slice(-1)[0] === 'pdf') {
          axios
            .get(selectedAttachment.value.file, { responseType: 'arraybuffer' })
            .then((response) => {
              const blob = new Blob([response.data], { type: 'application/pdf' });
              selectedAttachmentSource.value = URL.createObjectURL(blob);
            });
        }

        if (['jpg', 'jpeg'].includes(selectedAttachment.value.filename.toLowerCase().split('.').slice(-1)[0])) {
          const attachment = new Attachment({ image: { url: selectedAttachment.value.file } });

          attachment.render().then(() => {
            attachment.stream.on('finish', () => {
              selectedAttachmentSource.value = attachment.stream.toBlobURL('application/pdf');
            });
          });
        }
      }
    };

    const upload = (event) => {
      errorMessage.value = null;

      const MAX_SIZE = 2 * 1024 * 1024;

      if (event.target.files.length > 0) {
        const file = event.target.files[0];

        if (file.size > MAX_SIZE) {
          errorMessage.value = i18n.global.t('dialog.attachments.errors.fileSize');

          return;
        }

        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('documents', currentDocument.value.id);

        loading.value = true;
        api.attachments.create(formData).then(() => {
          store.dispatch('getDocument', currentDocument.value.id).then(() => {
            selectedAttachment.value = sortedAttachments.value[sortedAttachments.value.length - 1];
            selectedAttachmentChange();
          });
        }).catch((error) => {
          if (error.response?.status === 400 && error.response?.data?.file[0]?.includes('DIN A4')) {
            errorMessage.value = i18n.global.t('dialog.attachments.errors.filePageFormat');
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    };

    const remove = (attachment) => {
      api.attachments.update(attachment.id, {
        documents: attachment.documents.filter(
          (id) => id !== currentDocument.value.id,
        ),
      }).then(() => {
        store.dispatch('getDocument', currentDocument.value.id);
      });
    };

    return {
      loading,
      errorMessage,
      selectedAttachment,
      selectedAttachmentSource,
      selectedAttachmentChange,
      currentDocument,
      sortedAttachments,
      upload,
      remove,
    };
  },
};
