import axios from 'axios';
import humps from 'humps';

import api from '@/api';

export default {
  state: {
    documents: [],

    currentDocument: null,

    updating: false,

    request: null,
  },

  mutations: {
    setDocuments(state, documents) {
      state.documents = documents;
    },

    setCurrentDocument(state, document) {
      state.currentDocument = document;
    },

    setUpdating(state, value) {
      state.updating = value;
    },

    setRequest(state, request) {
      state.request = request;
    },

    updateSender(state, value) {
      state.currentDocument.sender = value;
    },

    updateRecipient(state, value) {
      state.currentDocument.recipient = value;
    },

    updateMemo(state, value) {
      state.currentDocument.memo = value;
    },

    updateInfo(state, value) {
      state.currentDocument.info = value;
    },

    updateSubject(state, value) {
      state.currentDocument.subject = value;
    },

    updateDate(state, value) {
      state.currentDocument.issuedAt = value;
    },

    updateText(state, value) {
      state.currentDocument.text = value;
    },

    updateFooter(state, value) {
      state.currentDocument.footer = value;
    },

    updatePageNumbers(state, value) {
      state.currentDocument.pageNumbers = value;
    },

    updateFoldingMarkers(state, value) {
      state.currentDocument.foldingMarkers = value;
    },

    updateDocumentType(state, value) {
      state.currentDocument.documentType = value;
    },

    updateNorm(state, value) {
      state.currentDocument.norm = value;
    },

    updateFontFamily(state, value) {
      state.currentDocument.fontFamily = value;
    },

    updateFontSize(state, value) {
      state.currentDocument.fontSize = value;
    },

    updateLocked(state, value) {
      state.currentDocument.locked = value;
    },

    updateBackground(state, value) {
      state.currentDocument.background = value;
    },

    updateBackgroundImage(state, value) {
      state.currentDocument.backgroundImage = value;
    },

    updateLetterhead(state, value) {
      state.currentDocument.letterhead = value;
    },

    updateletterheadImage(state, value) {
      state.currentDocument.letterheadImage = value;
    },

    updateLetterheadAlignment(state, value) {
      state.currentDocument.letterheadAlignment = value;
    },

    updateSignature(state, value) {
      state.currentDocument.signature = value;
    },

    updatesignatureImage(state, value) {
      state.currentDocument.signatureImage = value;
    },

    updateSerialPlaceholders(state, value) {
      state.currentDocument.serialPlaceholders = value;
    },

    updateInvoiceId(state, value) {
      state.currentDocument.invoiceId = value;
    },

    updateInvoiceTaxRate(state, value) {
      state.currentDocument.invoiceTaxRate = value;
    },

    updateInvoiceCurrency(state, value) {
      state.currentDocument.invoiceCurrency = value;
    },

    updateInvoiceRows(state, value) {
      state.currentDocument.invoiceRows = value;
    },
  },

  actions: {
    setCurrentDocument({ commit }, document) {
      commit('setCurrentDocument', document);
    },

    createDocument({ commit, state }, data) {
      return api.documents.create(humps.decamelizeKeys(data)).then((response) => {
        const createdDocument = humps.camelizeKeys(response.data);

        commit('setDocuments', [createdDocument, ...state.documents]);
        commit('setCurrentDocument', createdDocument);
      });
    },

    createPreviewDocument({ commit, state }, { documentType, locale }) {
      return api.documents.preview.create(documentType, locale).then((document) => {
        commit('setDocuments', [document, ...state.documents]);
        commit('setCurrentDocument', document);
      });
    },

    createExternalDocument({ commit, state }, { id, locale }) {
      return api.documents.external.create(id, locale).then((response) => {
        const date = parseInt(new Date().getTime() / 1000, 10);
        const data = { ...response.data, ...{ issuedAt: date, createdAt: date, updatedAt: date } };

        commit('setDocuments', [data, ...state.documents]);
        commit('setCurrentDocument', data);
      });
    },

    createAndPersistExternalDocument({ commit, state }, { id, locale }) {
      return api.documents.external.create(id, locale).then((response) => {
        const { data } = response;

        delete data.id;
        delete data.issuedAt;

        if (data.letterheadImage === null) delete data.letterheadImage;
        if (data.backgroundImage === null) delete data.backgroundImage;
        if (data.signatureImage === null) delete data.signatureImage;

        return api.documents.create(humps.decamelizeKeys(data)).then((apiResponse) => {
          const createdDocument = humps.camelizeKeys(apiResponse.data);

          commit('setDocuments', [createdDocument, ...state.documents]);
          commit('setCurrentDocument', createdDocument);
        });
      });
    },

    getDocument({ commit }, id) {
      return api.documents.get(id).then((response) => {
        commit('setCurrentDocument', humps.camelizeKeys(response.data));
      });
    },

    getDocuments({ commit }) {
      return api.documents.all().then((response) => {
        commit('setDocuments', humps.camelizeKeys(response.data));
      });
    },

    async updateDocument({ commit, state }, {
      id, data, options = {
        setCurrentDocument: true,
      },
    }) {
      await state.request;

      commit('setUpdating', true);
      commit('setRequest', api.documents.update(id, humps.decamelizeKeys(data)).then((response) => {
        const updatedDocument = humps.camelizeKeys(response.data);

        commit('setDocuments', [updatedDocument, ...state.documents].filter(
          (v, i, a) => a.findIndex((t) => (t.id === v.id)) === i,
        ));

        if (options.setCurrentDocument) commit('setCurrentDocument', updatedDocument);

        commit('setUpdating', false);
      }));
    },

    applyTemplate({ commit, dispatch, state }, { id, template }) {
      const data = template;
      const { backgroundImage, letterheadImage, signatureImage } = data;

      ['backgroundImage', 'letterheadImage', 'signatureImage'].forEach((key) => delete data[key]);

      commit('setUpdating', true);

      return api.documents.update(id, humps.decamelizeKeys(data)).then(async (response) => {
        const updatedDocument = humps.camelizeKeys(response.data);

        commit('setCurrentDocument', updatedDocument);
        commit('setDocuments', [updatedDocument, ...state.documents].filter(
          (v, i, a) => a.findIndex((t) => (t.id === v.id)) === i,
        ));

        let background = null;
        let letterhead = null;
        let signature = null;

        if (backgroundImage) {
          const backgroundResponse = await axios.get(backgroundImage, { responseType: 'blob' });

          background = new File([backgroundResponse.data], `background.${backgroundImage.split('.').pop()}`);
        }

        if (letterheadImage) {
          const letterheadResponse = await axios.get(letterheadImage, { responseType: 'blob' });

          letterhead = new File([letterheadResponse.data], `letterhead.${letterheadImage.split('.').pop()}`);
        }

        if (signatureImage) {
          const signatureResponse = await axios.get(signatureImage, { responseType: 'blob' });

          signature = new File([signatureResponse.data], `signature.${signatureImage.split('.').pop()}`);
        }

        if (background || letterhead || signature) {
          await dispatch('updateDocumentImages', {
            id: response.data.id,
            background,
            letterhead,
            signature,
          });
        }

        commit('setUpdating', false);
      });
    },

    updateDocumentBackground({ commit }, { id, file }) {
      const formData = new FormData();
      formData.append('background_image', file);

      return api.documents.updateDocumentImage(id, formData).then((response) => {
        commit('setCurrentDocument', humps.camelizeKeys(response.data));
      });
    },

    updateDocumentLetterhead({ commit }, { id, file }) {
      const formData = new FormData();
      formData.append('letterhead_image', file);

      return api.documents.updateDocumentImage(id, formData).then((response) => {
        commit('setCurrentDocument', humps.camelizeKeys(response.data));
      });
    },

    updateDocumentSignature({ commit }, { id, file }) {
      const formData = new FormData();
      formData.append('signature_image', file);

      return api.documents.updateDocumentImage(id, formData).then((response) => {
        commit('setCurrentDocument', humps.camelizeKeys(response.data));
      });
    },

    updateDocumentImages({ commit }, {
      id, background, letterhead, signature,
    }) {
      const formData = new FormData();
      if (background) formData.append('background_image', background);
      if (letterhead) formData.append('letterhead_image', letterhead);
      if (signature) formData.append('signature_image', signature);

      return api.documents.updateDocumentImage(id, formData).then((response) => {
        commit('setCurrentDocument', humps.camelizeKeys(response.data));
      });
    },

    updatePreviewDocument({ commit }, { id, data, locale }) {
      commit('setUpdating', true);

      return api.documents.preview.update(id, data, locale).then((document) => {
        commit('setCurrentDocument', document);
        commit('setUpdating', false);
      });
    },

    deleteDocument({ commit, state }, id) {
      return api.documents.delete(id).then(() => {
        commit('setDocuments', state.documents.filter((document) => document.id !== id));

        if (state.currentDocument && state.currentDocument.id === id) {
          commit('setCurrentDocument', null);
        }
      });
    },

    resetDocuments({ commit }) {
      commit('setDocuments', []);
      commit('setCurrentDocument', null);
    },
  },
};
