import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogBackground',

  props: {
    open: Boolean,
    modelValue: Boolean,
    backgroundImage: String,
  },

  components: {
    Dialog,
  },

  computed: {
    currentDocument() {
      return this.$store.state.documents.currentDocument;
    },
  },

  methods: {
    updateImage(event) {
      this.$store.dispatch('updateDocumentBackground', {
        id: this.currentDocument.id,
        file: event.target.files[0],
      }).then(() => this.$emit('updatePreview'));
    },
  },
};
