import { ref } from 'vue';

import api from '@/api';

import Button from '@/components/atoms/button/index.vue';
import Placeholder from '@/components/atoms/placeholder/index.vue';

import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogCompletion',

  props: {
    open: Boolean,
  },

  components: {
    Button,
    Placeholder,

    Dialog,
  },

  computed: {
    currentDocument() {
      return this.$store.state.documents.currentDocument;
    },
  },

  setup() {
    return {
      loading: ref(false),
      completionTypeOptions: ['Geschäftsbrief', 'Kündigung', 'Mahnung', 'Bewerbung', 'Anschreiben', 'Angebot', 'Empfehlungsschreiben', 'Einladungsschreiben', 'Beschwerde', 'Dankesbrief', 'custom'],
      completionType: ref(''),
      completionTagInput: ref(''),
      completionTypeText: ref(''),
      completionTags: ref([]),
      suggestion: ref(''),
    };
  },

  methods: {
    addCompletionTag() {
      this.completionTags.push(this.completionTagInput);
      this.completionTagInput = '';
    },

    deleteCompletionTag(index) {
      this.completionTags.splice(index, 1);
    },

    requestCompletion() {
      this.loading = true;

      api.misc.ai.completion({
        type: this.completionType === 'custom' ? this.completionTypeText : this.completionType,
        ...this.completionTags.length && { tags: this.completionTags.join(',') },
      }).then((response) => {
        this.suggestion = response.data.text?.trim();
        this.loading = false;
      }).catch((error) => {
        // eslint-disable-next-line
        console.log(error.response && error.response.status);

        this.loading = false;
      });
    },
  },
};
