<template>
  <span
    class="badge"
    :class="{ [`badge--${style}`]: style }"
  >
    {{ text }}
  </span>
</template>

<script src="./index.vue.js"></script>
