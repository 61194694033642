import { createApp } from 'vue';
import { svgSpritePlugin } from 'vue-svg-sprite';

import packageJson from '../package.json';

import App from './app/index.vue';

import router from './router';
import i18n from './i18n';
import store from './store';

import CookieConsentPlugin from './plugins/cookie-consent';

createApp(App)
  .use(router)
  .use(i18n)
  .use(store)
  .use(CookieConsentPlugin)
  .use(svgSpritePlugin, {
    url: '/img/icons.svg',
  })
  .mount('#app');

// eslint-disable-next-line no-console
console.info(`EasyLetter ${packageJson.version}`);
