import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Button from '@/components/atoms/button/index.vue';
import Icon from '@/components/atoms/icon/index.vue';

export default {
  name: 'Startpage',

  components: {
    Button,
    Icon,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const requesting = computed(() => store.state.app.requesting);
    const currentUser = computed(() => store.state.auth.currentUser);

    const documents = computed(() => store.state.documents.documents);
    const sortedDocuments = computed(
      () => documents.value.slice(0, 3).sort((a, b) => b.updatedAt - a.updatedAt),
    );

    const selectDocument = (document) => {
      router.push({ name: 'document', params: { documentId: document.id } });
    };

    const formattedDate = (timestamp) => new Date(timestamp * 1000).toLocaleDateString(undefined, {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
    });

    const formattedIconName = (documentType) => [
      'document-letter',
      'document-serial',
      navigator.language.includes('de') ? 'document-invoice-euro' : 'document-invoice-dollar',
    ][documentType];

    const formattedItemText = (document) => (
      document.documentType === 2 && document.invoiceId ? `<b>${document.invoiceId}</b> &middot; ${formattedDate(document.updatedAt)}` : formattedDate(document.updatedAt)
    );

    const openSidebar = () => store.commit('setShowSidebar', true);
    const toggleDialog = (dialog) => store.commit('setCurrentDialog', dialog);

    return {
      currentUser,
      documents: sortedDocuments,
      selectDocument,
      formattedIconName,
      formattedItemText,
      openSidebar,
      toggleDialog,
      requesting,
    };
  },
};
