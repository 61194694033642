export default {
  "meta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyLetter - Send real invoices and letters online"])}
  },
  "global": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add row"])},
    "addColumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add column"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "registerFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up for free"])},
    "registerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "registerFreeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create free account"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "openSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open sidebar"])},
    "closeSidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close sidebar"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and send real letters - easy and anywhere."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])}
  },
  "dialog": {
    "background": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show background"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate this option to display the background image in the document"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select image …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal resolution for DIN 5008: 210 x 297 mm, 2480 x 3508 px, max. 150 kB"])}
    },
    "completion": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent text assistant"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a letter type and any keywords that should be taken into account in order to receive a text suggestion. This function is experimental and you should check the result before sending."])},
      "custom": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom type"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letter type"])}
      },
      "tags": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New keyword"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All keywords"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic, context"])}
      },
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request text suggestion"])},
      "suggestion": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated text suggestion"])}
      },
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply text suggestion"])}
    },
    "documentCreate": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New document"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of document you want to create. You can change the type of document created later"])},
      "letterHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple letter"])},
      "letterDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional letter template according to US Letter with all essential input fields and design options"])},
      "serialHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial letter"])},
      "serialDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended letter template with management of your own markers and CSV import for creating serial letters"])},
      "invoiceHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice / Offer"])},
      "invoiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost recording for invoicing and preparation of offers in combination with text content and all design options"])}
    },
    "documentDelete": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete document"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete <strong>", _interpolate(_named("subject")), "</strong>?"])}
    },
    "exportSerial": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export serial letter"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a data source to be used for the serial export of the document"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select CSV file …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure that the markers you create in the document match the column names from the file"])}
    },
    "fontFamily": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change font"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a font from the list"])}
    },
    "intro": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue as a guest"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to EasyLetter! Create professional documents for all purposes - private or business."])}
    },
    "introSend": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send letters easily"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the practical mailing function to send your documents as a <strong>real letter</strong>.<br /><br /><strong>PIN AG</strong> prints, envelopes, stamps and sends your letter in just a few clicks."])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
    },
    "letterhead": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show letterhead"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable this option to display the letterhead in the document"])},
      "alignmentHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alignment"])},
      "alignmentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select alignment"])},
      "alignmentTextLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
      "alignmentTextCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
      "alignmentTextRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select image …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal resolution for DIN 5008: 205 x 22 mm, 2421 x 260 px, max. 150 kB"])}
    },
    "managePlaceholders": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage markers"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and delete markers for filling your serial letter"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created markers"])},
      "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any keyword"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New marker"])}
    },
    "paywall": {
      "pro": {
        "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional all round"])},
        "argument0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>5 shipments</strong> per month included<sup><strong>*</strong></sup>"])},
        "argument1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert <strong>stationery</strong> and <strong>signatures</strong>"])},
        "argument2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All text formatting options including <strong>bold</strong>, <i>italic</i> and <u>underline</u>"])},
        "argument3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change font size and style"])},
        "argument4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Create serial letters</strong> - Use custom placeholders and CSV files to generate real mail merge and export as <strong>PDF</strong>"])},
        "argument5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Lock</strong> documents for further processing"])},
        "kicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most popular"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock now for monthly"])},
        "price": {
          "credits0": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€3.49"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€2.91"])}
          },
          "credits5": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€9.99"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€7.99"])}
          }
        },
        "switch": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period"])},
          "text": {
            "credits0": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>20&#8198;%</em> discount anually"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annual billing · €34.99"])}
            },
            "credits5": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>25&#8198;%</em> discount anually"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annual billing · €95.88"])}
            }
          }
        },
        "priceFooter": {
          "credits0": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€3.49"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save 20%"])}
          },
          "credits5": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€9.99"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save 25%"])}
          }
        },
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
      },
      "business": {
        "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything for your business"])},
        "argument0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>15 shipments</strong> per month included<sup><strong>*</strong></sup>"])},
        "argument1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and send <strong>invoices</strong> and <strong>offers</strong>"])},
        "argument2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Create serial letters</strong> - Use custom placeholders and CSV files to generate real mail merge and export as <strong>PDF</strong>"])},
        "argument3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert <strong>stationery</strong> and <strong>signatures</strong>"])},
        "argument4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change font size and style"])},
        "argument5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… and all other features of <i>Easy</i>Letter <strong>PRO</strong>"])},
        "kicker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited features"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock now for monthly"])},
        "price": {
          "credits0": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€5.99"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€4.99"])}
          },
          "credits15": {
            "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€19.99"])},
            "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€16.99"])}
          }
        },
        "switch": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period"])},
          "text": {
            "credits0": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>20&#8198;%</em> discount anually"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annual billing · €59.99"])}
            },
            "credits15": {
              "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>20&#8198;%</em> discount anually"])},
              "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annual billing · €203.88"])}
            }
          }
        },
        "priceFooter": {
          "credits0": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€5.99"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save 20%"])}
          },
          "credits15": {
            "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€19.99"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save 20%"])}
          }
        },
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
      },
      "credits": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly included shipments<sup>*</sup>"])},
        "option0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Shipments"])},
        "option15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 Shipments"])}
      },
      "footer0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<sup><strong>*</strong></sup>The number of shipments applies per calendar month and includes shipments printed in black and white, DIN A4, 1 - 3 pages, shipping throughout Germany. The credit gets resetted at the beginning of each calendar month. Unused credits expire and are not accumulated or added to credits from previous months."])}
    },
    "upsellPro": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have discovered a PRO feature"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create <b>any number of documents</b> and use all <strong>PRO</strong> features of <i>Easy</i>Letter."])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
    },
    "send": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send letter"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a product that suits you and send your letter. International shipping must be used for recipient addresses outside of Germany"])},
      "noAvailableProducts": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products available"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no product available for the selected number of pages. Reduce the number of pages to be able to send the letter."])}
      },
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incl. VAT"])},
      "buyMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy more"])},
      "buyCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy credit"])},
      "useCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use 1 credit & send"])},
      "pinDomesticStandardBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard letter (black and white)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping within Germany with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 pages · black and white · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€1.89"])}
      },
      "pinDomesticStandardC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard letter (color)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping within Germany with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 pages · color · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€2.29"])}
      },
      "pinInternationalStandardBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard letter (black and white)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International shipping with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 pages · black and white · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€2.49"])}
      },
      "pinInternationalStandardC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard letter (color)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International shipping with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 – 3 pages · color · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€2.69"])}
      },
      "pinDomesticCompactBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact letter (black and white)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping within Germany with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 pages · black and white · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€3.49"])}
      },
      "pinDomesticCompactC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact letter (color)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping within Germany with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 pages · color · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€3.99"])}
      },
      "pinInternationalCompactBw": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact letter (black and white)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International shipping with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 pages · black and white · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€5.49"])}
      },
      "pinInternationalCompactC": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact letter (color)"])},
        "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International shipping with PIN AG"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 – 9 pages · color · DIN A4"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€5.99"])}
      },
      "attachments": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachments for this mailing"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Your shipment has <b>", _interpolate(_named("totalPages")), " pages</b> including ", _interpolate(_named("count")), " page as attachment"]), _normalize(["Your shipment has <b>", _interpolate(_named("totalPages")), " pages</b> including ", _interpolate(_named("count")), " pages as attachment"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select …"])}
      },
      "conditions": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping policy"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking <b>Send</b>, you agree to the <a href=\"javascript:alert('The price includes printing, enveloping, franking and shipping with eBrief from PIN AG. By If you tap on Send, you agree that we may execute your order immediately. Once execution has started, you can no longer revoke your order. Letters are sent from Germany and usually take 1 - 3 days throughout Europe. Worldwide shipping can vary extend the duration.\n\nThe address field is printed in a standard font and size to ensure legibility for the mail service provider. As the customer, you are responsible for ensuring that the mailing ordered has a complete and correct address.')\"><u>Shipping conditions</u></a> and the <a href=\"https://www.jungspree.com/en/privacy-policy/\" target=\"_blank\"><u>Privacy policy</u></a>."])}
      },
      "success": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letter has been sent"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about the processing of your shipment, you can use the reference number displayed. The number does not enable shipment tracking."])}
      },
      "error": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later."])}
      }
    },
    "attachments": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachments"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select documents and images you want to attach to the shipment. Images are automatically created on DIN A4."])},
      "totalPages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " page"]), _normalize([_interpolate(_named("count")), " pages"])])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose file …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIN A4, max. 2 MB"])},
      "previewText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an attachment to see the preview"])},
      "errors": {
        "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum file size is 2 MB"])},
        "filePageFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more pages of the file are not in format DIN A4 (210 × 297 mm)."])}
      }
    },
    "signature": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show signature"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable this option to display the signature in the document"])},
      "fileInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select image …"])},
      "fileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal resolution: 35 x 20 mm, 99 x 57 px, max. 150 kB"])}
    },
    "templates": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Templates"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and manage templates for your documents"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "type0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letter"])},
      "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial letter"])},
      "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New template"])},
      "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name"])}
    },
    "userSettings": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your data and subscriptions"])},
      "profileImageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change profile picture …"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "managePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage subscription"])},
      "showPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View benefits"])},
      "proPlan": {
        "headline": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are a PRO"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a PRO"])}
        },
        "intro": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You enjoy all the benefits of this version"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the benefits of EasyLetter PRO"])}
        }
      },
      "businessPlan": {
        "headline": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your BUSINESS plan"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big in Business"])}
        },
        "intro": {
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You enjoy all the benefits of EasyLetter BUSINESS"])},
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn the benefits of EasyLetter BUSINESS"])}
        }
      }
    }
  },
  "featureLock": {
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRO"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUSINESS"])}
  },
  "menu": {
    "addPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add page"])},
    "removeLastPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove last page"])},
    "lockDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock document"])},
    "unlockDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock document"])},
    "formatBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
    "formatItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
    "formatUnderline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underline"])},
    "changeFontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change font size"])},
    "updateFontFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change font"])},
    "doInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create invoice"])},
    "undoInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove invoice"])},
    "doSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create serial letter"])},
    "undoSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove serial letter"])},
    "managePlaceholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeholders …"])}
  },
  "preview": {
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving changes …"])},
    "savedAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saved on ", _interpolate(_named("date"))])}
  },
  "sidebar": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All documents"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
    "openUserSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View profile"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject, address, date..."])},
    "documentSentAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Document sent on ", _interpolate(_named("date"))])},
    "documentNotSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document not sent yet"])},
    "documentLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document locked"])},
    "documentDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete document"])},
    "wallHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and manage documents"])},
    "wallText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to access documents from anywhere"])}
  },
  "startpage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to <i>Easy</i><strong>Letter</strong>!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a document or open the sidebar to view your profile or to edit a saved document"])},
    "textNoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the button <b>New document</b> in the upper left side of the toolbar to create a preview document or sign in here:"])},
    "documents": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently updated"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create document"])}
    }
  },
  "toolbar": {
    "newDocument": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new document"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New document"])}
    },
    "myTemplates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage templates"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My templates"])}
    },
    "exportDocument": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export document"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
    },
    "sendDocument": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send document"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send letter"])}
    }
  },
  "editor": {
    "newDocumentDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New document"])},
    "newProductDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "form": {
      "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
      "senderPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender's address"])},
      "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
      "recipientPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's address"])},
      "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
      "memoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any note that will appear under the address"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "infoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further contact details & information"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
      "taxRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "subjectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "textPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer"])},
      "footerPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column content"])},
      "invoice": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "pricePerUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per unit"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "applyTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply tax"])}
      }
    },
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit …"])},
    "backgroundHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
    "backgroundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a background image as stationery"])},
    "letterheadHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letterhead"])},
    "letterheadDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalize your letter by adding a letterhead"])},
    "signatureHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "signatureDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a signature"])},
    "foldingMarkersHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folding markers"])},
    "foldingMarkersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sets folding markers on the left side of the page"])},
    "pageNumbersHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page numbers"])},
    "pageNumbersDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show or hide page numbering"])},
    "formTypeHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
    "formTypeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifies the page format"])},
    "activationRequired": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email activation required"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your email address to be able to use this function"])}
    },
    "checkoutSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase complete"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have fun with your product!"])}
    },
    "checkoutCanceled": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase aborted"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purchase process was canceled …"])}
    }
  },
  "login": {
    "registered": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm email address"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your EasyLetter account has been successfully created. You have received a full account activation email. Please click the link in this email to activate your account."])}
    },
    "activated": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activated"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your EasyLetter account has been successfully activated. You can now send letters."])}
    },
    "logout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed out"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully signed out."])}
    },
    "errors": {
      "messageNoActiveAccountFoundWithTheGivenCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email address or password"])}
    }
  },
  "register": {
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our <a href=\"https://www.jungspree.com/en/privacy-policy/\" target=\"_blank\"><u>Privacy policy</u></a> learn how we use and collect data."])},
    "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered?"])},
    "errors": {
      "messageuserProfileWithThisEmailAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account with this email already exists"])},
      "messageThisPasswordIsTooShortItMustContainAtLeast8Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain at least 8 characters"])},
      "messageThisPasswordIsEntirelyNumeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot consist of numbers only"])},
      "messageThePasswordIsTooSimilarToTheEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not be too similar to email address"])}
    }
  },
  "norms": {
    "din5008A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIN 5008 (Form A)"])},
    "din5008B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIN 5008 (Form B)"])},
    "usLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US Letter"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN"])}
  },
  "document": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice-No."])},
    "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Page ", _interpolate(_list(0)), " of ", _interpolate(_list(1))])}
  }
}