<template>
  <div
    class="preview"
    :class="{
      'preview--rendering': rendering,
      'preview--updating': updating
    }"
  >
    <iframe
      class="preview__frame"
      :src="`${source}#toolbar=0&navpanes=0&zoom=75`"
    />
    <div
      class="preview__statusbar"
    >
      <div class="preview__statusbar-section">
        <div v-if="updating" class="preview__statusbar-loader" />

        <span v-if="updatedText" class="preview__text">
          {{ updatedText }}
        </span>
      </div>

      <div class="preview__statusbar-section">
        <Icon class="preview__icon" name="font-family" />
        <span class="preview__text">
          {{ fontFamily }}, {{ fontSize }}&thinsp;pt
        </span>

        <span class="preview__text preview__text--light">
          {{ $t(`norms.${norm}`) }}
        </span>
      </div>
    </div>

    <button
      class="preview__button"
      @click="$emit('toggle')">
      <Icon
        class="preview__button-icon"
        :name="show ? 'arrow-left' : 'preview'"
      />
    </button>
  </div>
</template>

<script src="./index.vue.js"></script>
