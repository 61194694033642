import Startpage from '@/components/molecules/pages/startpage/index.vue';

export default {
  state: {
    showSidebar: false,

    currentPage: Startpage.name,
  },

  mutations: {
    setShowSidebar(state, status) {
      state.showSidebar = status;
    },

    setCurrentPage(state, page) {
      state.currentPage = page;
    },
  },

  actions: {
    setShowSidebar({ commit }, status) {
      commit('setShowSidebar', status);
    },

    setCurrentPage({ commit }, page) {
      commit('setCurrentPage', page);
    },
  },
};
