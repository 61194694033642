import { computed } from 'vue';
import { useStore } from 'vuex';

import Startpage from '@/components/molecules/pages/startpage/index.vue';

export default {
  name: 'PageManager',

  components: {
    Startpage,
  },

  setup() {
    const store = useStore();

    const currentPage = computed(() => store.state.editor.currentPage);

    return {
      currentPage,
    };
  },
};
