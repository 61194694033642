export default {
  de: {
    documents: [
      {
        id: 'b42ef0e6-74ce-11ec-90d6-0242ac120003',
        subject: 'Ein einfacher Brief',
        sender: 'Max Mustermann\nHauptstraße 123\n12345 Musterstadt',
        recipient: 'Anna Musterfrau\nHauptstraße 25\n12345 Musterstadt',
        memo: 'Dies ist eine *Notiz*',
        info: '*Kontakt*\nTelefon: (0123) 1234 567\nFax: (0123) 1234 567\n\nE-Mail: max.mustermann@email.de',
        text: ['Sehr geehrte Damen und Herren,\n\nSie können unterschiedliche Formatierungen im Fließtext verwenden. Zum Beispiel *fett*, _unterstrichen_ oder\n\\kursive\\ Textabschnitte.\n\nWeiterhin können Sie mehrseitige Dokumente erstellen und einen Briefkopf hinterlegen. Mit \\Easy\\Letter *PRO* können Sie zusätzlich ein Briefpapier anlegen sowie eine Unterschrift hinzufügen.\n\nHaben Sie nach der Registrierung Ihre E-Mail-Adresse bestätigt, können Sie mit der Funktion *Senden* Ihre Briefe bequem per Klick als echten Brief deutschlandweit oder international versenden lassen.', 'Alle Seiten erhalten die Informationen aus der Fußzeile und die Seitennummerierung erfolgt automatisch. Praktische Falzmarken am linken Rand helfen Ihnen beim Falten des Dokuments.\n\nEine hochgeladene Unterschrift wird automatisch am Ende des Dokuments platziert:'],
        footer: ['Dies ist eine Fußzeile', 'Hier können weitere Informationen\nhinterlegt werden', 'Wie Bankverbindungen,\nE-Mail-Adressen\noder Telefonnummern', 'Es können bis zu\n4 Spalten erstellt werden'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: [],

        invoiceId: null,
        invoiceTaxRate: 0,
        invoiceCurrency: 'EUR',
        invoiceRows: [['Entry', 1, 100, null, true]],

        documentType: 0,

        sentAt: 1659341714,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
      {
        id: 'be906254-74ce-11ec-90d6-0242ac120003',
        subject: 'Ein Serienbrief',
        sender: 'Max Mustermann\nHauptstraße 123\n12345 Musterstadt',
        recipient: 'Anna Musterfrau\nHauptstraße 25\n12345 Musterstadt',
        memo: 'Dies ist eine *Notiz*',
        info: '*Kontakt*\nTelefon: (0123) 1234 567\nFax: (0123) 1234 567\n\nE-Mail: max.mustermann@email.de',
        text: ['Sehr geehrte Damen und Herren,\n\nSie können unterschiedliche Platzhalter frei erstellen und im Fließtext nutzen. Zum Beispiel: #Vorname# oder #TEST#.\n\nDiese Platzhalter werden mit Informationen aus Ihrer CSV-Datei beim Export angereichert und für jeden Datensatz einzeln als PDF-Datei gespeichert.'],
        footer: ['Dies ist eine Fußzeile', 'Hier können weitere Informationen\nhinterlegt werden', 'Wie Bankverbindungen,\nE-Mail-Adressen\noder Telefonnummern', 'Es können bis zu\n4 Spalten erstellt werden'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: ['Vorname', 'Complex_', 'ABC1', 'MARKER', 'TEST'],

        invoiceId: null,
        invoiceTaxRate: 0,
        invoiceCurrency: 'EUR',
        invoiceRows: [],

        documentType: 1,

        sentAt: 1659341714,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
      {
        id: 'c58e89f0-74ce-11ec-90d6-0242ac120003',
        subject: 'Eine Rechnung',
        sender: 'Max Mustermann\nHauptstraße 123\n12345 Musterstadt',
        recipient: 'Anna Musterfrau\nHauptstraße 25\n12345 Musterstadt',
        memo: 'Dies ist eine *Notiz*',
        info: '*Kontakt*\nTelefon: (0123) 1234 567\nFax: (0123) 1234 567\n\nE-Mail: max.mustermann@email.de',
        text: ['Fügen Sie beliebigen Text zum Dokument hinzu, um dem Empfänger weitere Angaben zu Ihrem Angebot oder Ihrer Rechnung zu geben.\n\nAuch eine Unterschrift kann hier gesetzt werden:'],
        footer: ['Dies ist eine Fußzeile', 'Hier können weitere Informationen\nhinterlegt werden', 'Wie Bankverbindungen,\nE-Mail-Adressen\noder Telefonnummern', 'Es können bis zu\n4 Spalten erstellt werden'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: [],

        invoiceId: 'ABC-2022-001',
        invoiceTaxRate: 19.0,
        invoiceCurrency: 'EUR',
        invoiceRows: [
          ['Produkt #123', '1.50', '100.00', '*150,00 EUR*', true],
          ['Leistungsbeschreibung mit unterschiedlichen Formatierungen: \\Kursiv\\ oder auch weitere wie zum Beispiel *fett* oder _unterstrichen_', '1.50', '130.00', '*150,00 EUR*', true],
          ['Dienstleistung #456', '1.50', '40.56', '*150,00 EUR*', true],
          ['Sie können pro Zeile den festgelegten Steuersatz anwenden', '1.00', '100.00', '*150,00 EUR*', true],
          ['Der fällige Betrag wird automatisch berechnet', '1.50', '100.00', '*150,00 EUR*', true],
        ],

        documentType: 2,

        sentAt: null,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
    ],
  },

  en: {
    documents: [
      {
        id: 'b42ef0e6-74ce-11ec-90d6-0242ac120003',
        subject: 'A simple letter',
        sender: 'John Smith\n132, Main Street\nNew York 12401',
        recipient: 'Alex Best\n102, Main Street\nNew York 12300',
        memo: 'This is a *memo*',
        info: '*Contact*\nPhone: (555) 555-1234\ne-mail: john.smith@email.com',
        text: ['Dear Sir or Madam,\n\nYou can use different formatting in the body text. For example *bold*, _underlined_ or\n\\italic\\ sections of text.\n\nYou can also create multi-page documents and store a letterhead. With \\Easy\\Letter *PRO* you can also create stationery and add a signature.\n\nIf you have confirmed your e-mail address after registration, you can use the *Send* function to conveniently send your letters with a click can be sent as a real letter throughout Germany or internationally.', 'All pages receive the information from the footer and the page numbering is done automatically. Practical fold marks on the left edge help you to fold the document.\n\nAn uploaded signature is automatically placed at the end of the document:'],
        footer: ['This is a footer', 'Further information\ncan be presented here', 'Such as bank details,\nemail addresses\nor telephone numbers', 'Up to 4 columns\ncan be created'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: [],

        invoiceId: null,
        invoiceTaxRate: 0,
        invoiceCurrency: 'EUR',
        invoiceRows: [['Entry', 1, 100, null, true]],

        documentType: 0,

        sentAt: 1659341714,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
      {
        id: 'be906254-74ce-11ec-90d6-0242ac120003',
        subject: 'A serial/form letter',
        sender: 'John Smith\n132, Main Street\nNew York 12401',
        recipient: 'Alex Best\n102, Main Street\nNew York 12300',
        memo: 'This is a *memo*',
        info: '*Contact*\nPhone: (555) 555-1234\ne-mail: john.smith@email.com',
        text: ['Dear Sir or Madam,\n\nYou can freely create different markers and use them in the text. For example: #Firstname# or #TEST#.\n\nThese markers are enriched with information from your CSV file during the export and saved individually as a PDF file for each data record.'],
        footer: ['This is a footer', 'Further information\ncan be presented here', 'Such as bank details,\nemail addresses\nor telephone numbers', 'Up to 4 columns\ncan be created'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: ['Firstname', 'Complex_', 'ABC1', 'MARKER', 'TEST'],

        invoiceId: null,
        invoiceTaxRate: 0,
        invoiceCurrency: 'EUR',
        invoiceRows: [],

        documentType: 1,

        sentAt: 1659341714,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
      {
        id: 'c58e89f0-74ce-11ec-90d6-0242ac120003',
        subject: 'An invoice',
        sender: 'John Smith\n132, Main Street\nNew York 12401',
        recipient: 'Alex Best\n102, Main Street\nNew York 12300',
        memo: 'This is a *memo*',
        info: '*Contact*\nPhone: (555) 555-1234\ne-mail: john.smith@email.com',
        text: ['Add any text to the document to give the recipient more information about your offer or invoice.\n\nYou can also put a signature here:'],
        footer: ['This is a footer', 'Further information\ncan be presented here', 'Such as bank details,\nemail addresses\nor telephone numbers', 'Up to 4 columns\ncan be created'],

        background: false,
        backgroundImage: '/images/dummy/background.jpg',

        letterhead: false,
        letterheadImage: '/images/dummy/letterhead-small.jpg',
        letterheadAlignment: 2,

        signature: true,
        signatureImage: '/images/dummy/signature.png',

        pageNumbers: true,
        foldingMarkers: true,
        fontFamily: 'helvetica',
        fontSize: 9,
        norm: 'din5008A',
        locked: false,

        serialPlaceholders: [],

        invoiceId: 'ABC-2022-001',
        invoiceTaxRate: 19.0,
        invoiceCurrency: 'EUR',
        invoiceRows: [
          ['Product #123', '1.50', '100.00', '*150,00 EUR*', true],
          ['Description of services with different formatting: For example \\italics\\ or others such as *bold* or _underlined_', '1.50', '130.00', '*150,00 EUR*', true],
          ['Service #456', '1.50', '40.56', '*150,00 EUR*', true],
          ['You can apply the specified tax rate per line', '1.00', '100.00', '*150,00 EUR*', true],
          ['The amount due will be calculated automatically', '1.50', '100.00', '*150,00 EUR*', true],
        ],

        documentType: 2,

        sentAt: null,
        issuedAt: 1659341714,
        createdAt: 1659341714,
        updatedAt: 1659341714,
      },
    ],
  },
};
