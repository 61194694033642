import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import Cookies from 'js-cookie';

import i18n from '@/i18n';

import { TRANSITION_TIMES } from '@/assets/javascripts/constants';

import Icon from '@/components/atoms/icon/index.vue';

import InputSearch from '@/components/molecules/input-search/index.vue';

import avatarImage from '@/assets/images/avatar.svg';

export default {
  name: 'Sidebar',

  components: {
    Icon,
    InputSearch,
  },

  setup(_, context) {
    const searchTerm = ref('');

    const store = useStore();
    const route = useRoute();

    const setCurrentDocument = (documentId) => {
      setTimeout(() => {
        store.commit('setRequesting', true);

        store.dispatch('getDocument', documentId).then(() => {
          store.commit('setRequesting', false);

          context.emit('selectedDocument');
        });
      }, TRANSITION_TIMES.long);
    };

    const closeSidebar = () => store.commit('setShowSidebar', false);

    watch(() => route.params.documentId, () => {
      if (route.params.documentId) {
        setCurrentDocument(route.params.documentId);
      } else {
        store.dispatch('setCurrentDocument', null);
      }
    });

    onMounted(() => {
      if (route.params.documentId) {
        setCurrentDocument(route.params.documentId);
      }
    });

    return {
      searchTerm,

      closeSidebar,
    };
  },

  computed: {
    avatarImage() {
      return this.currentUser && this.currentUser.avatarImage
        ? this.currentUser.avatarImage : avatarImage;
    },

    profileName() {
      return this.currentUser ? this.currentUser.email : i18n.global.t('sidebar.welcome');
    },

    currentUser() {
      return this.$store.state.auth.currentUser;
    },

    currentDocument() {
      return this.$store.state.documents.currentDocument;
    },

    documents() {
      return this.$store.state.documents.documents;
    },

    documentsFiltered() {
      const documents = this.searchTerm.length ? this.documentsIndex
        .filter((doc) => doc.content.includes(
          this.searchTerm.toLowerCase(),
        ))
        .map((doc) => doc.document) : this.documents;

      return documents
        .sort((a, b) => b.createdAt - a.createdAt);
    },

    documentsIndex() {
      return this.documents.map((document) => ({
        document,
        content: `
          ${document.subject}
          ${document.sender}
          ${document.recipient}
          ${document.invoiceId}
          ${new Date(document.issuedAt * 1000).toLocaleDateString()}
          ${this.formattedDate(document.issuedAt)}
        `.toLowerCase(),
      }));
    },
  },

  methods: {
    selectDocument(document) {
      this.$emit('hide');

      this.$router.push({ name: 'document', params: { documentId: document.id } });
    },

    formattedDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString(undefined, {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      });
    },

    formattedItemText(document) {
      return document.documentType === 2 && document.invoiceId
        ? `<b>${document.invoiceId}</b> &middot; ${this.formattedDate(document.issuedAt)}` : this.formattedDate(document.issuedAt);
    },

    formattedIconName(documentType) {
      return [
        'document-letter',
        'document-serial',
        navigator.language.includes('de') ? 'document-invoice-euro' : 'document-invoice-dollar',
      ][documentType];
    },

    logout() {
      this.$router.push({ name: 'login', query: { logout: '1' } }).then(() => {
        this.$store.dispatch('resetCurrentUser');
        this.$store.dispatch('resetDocuments');

        Cookies.remove('jwt-access');
        Cookies.remove('jwt-refresh');
      });
    },
  },

  mounted() {
    if (!this.currentUser && (Cookies.get('jwt-access') || Cookies.get('jwt-refresh'))) {
      this.$store.commit('setRequesting', true);

      this.$store.dispatch('getCurrentUser').then(() => {
        this.$store.dispatch('getDocuments');
        this.$store.commit('setRequesting', false);
      });
    }
  },
};
