import Dialog from '@/components/particles/dialog/index.vue';

export default {
  name: 'DialogMessage',

  props: {
    open: Boolean,
    title: String,
    text: String,
  },

  components: {
    Dialog,
  },
};
