import DOCUMENT_DEFAULTS from '@/services/document/defaults';
import DOCUMENT_TYPES from '@/services/document/types';

import { formatText, insertPlaceholder } from '@/services/document/utils';

import Button from '@/components/atoms/button/index.vue';
import Icon from '@/components/atoms/icon/index.vue';
import Placeholder from '@/components/atoms/placeholder/index.vue';

import FeatureLock from '@/components/molecules/feature-lock/index.vue';

export default {
  name: 'Menu',

  props: {
    formDocumentType: Number,
    formFontSize: Number,
    formSender: String,
    formRecipient: String,
    formMemo: String,
    formInfo: String,
    formText: Array,
    formFooter: Array,
    formLocked: Boolean,
    formSerialPlaceholders: Array,
    formInvoiceRows: Array,

    currentSelection: Object,
    currentFocus: Object,
  },

  components: {
    Button,
    Icon,
    Placeholder,

    FeatureLock,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.currentUser;
    },

    isFormSerial() {
      return this.formDocumentType === DOCUMENT_TYPES.SERIAL;
    },

    isFormInvoice() {
      return this.formDocumentType === DOCUMENT_TYPES.INVOICE;
    },

    menuIconInvoice() {
      return navigator.language.includes('de') ? 'invoice-euro' : 'invoice-dollar';
    },
  },

  methods: {
    addPage() {
      this.formText.push('');

      this.$emit('updateContent');
    },

    removeLastPage() {
      this.formText.splice(-1);

      this.$emit('updateContent');
    },

    formatBold() {
      this.formatInputs('bold');

      this.$emit('updateContent');
    },

    formatItalic() {
      this.formatInputs('italic');

      this.$emit('updateContent');
    },

    formatUnderline() {
      this.formatInputs('underline');

      this.$emit('updateContent');
    },

    changeFontSize() {
      this.$emit('updateFontSize', this.formFontSize < DOCUMENT_DEFAULTS.fontSizeMax
        ? this.formFontSize + 1 : DOCUMENT_DEFAULTS.fontSize);
    },

    addPlaceholder(text) {
      this.formatPlaceholder(text);

      this.$emit('updateContent');
    },

    formatPlaceholder(text) {
      const placeholder = `#${text}#`;
      const cachedSelectionStart = this.currentFocus.selectionStart;

      if (this.currentFocus.id.includes('formText')) {
        const index = this.currentFocus.id.split('-').pop();

        this.$emit('updateText', {
          index,
          value: insertPlaceholder(
            this.formText[index],
            this.currentFocus.selectionStart,
            this.currentFocus.selectionEnd,
            placeholder,
          ),
        });
      } else if (this.currentFocus.id.includes('formFooter')) {
        const index = this.currentFocus.id.split('-').pop();

        this.$emit('updateFooter', {
          index,
          value: insertPlaceholder(
            this.formFooter[index],
            this.currentFocus.selectionStart,
            this.currentFocus.selectionEnd,
            placeholder,
          ),
        });
      } else if (this.currentFocus.id.includes('formInvoiceRowDescription')) {
        const index = this.currentFocus.id.split('-').pop();

        this.$emit('updateInvoiceDescription', {
          index,
          value: insertPlaceholder(
            this.formInvoiceRows[index][0],
            this.currentFocus.selectionStart,
            this.currentFocus.selectionEnd,
            placeholder,
          ),
        });
      } else {
        this.$emit('updateField', {
          id: this.currentFocus.id,
          value: insertPlaceholder(
            this[this.currentFocus.id],
            this.currentFocus.selectionStart,
            this.currentFocus.selectionEnd,
            placeholder,
          ),
        });
      }

      this.$nextTick(() => {
        this.currentFocus.select();

        this.currentFocus.setSelectionRange(
          cachedSelectionStart + placeholder.length,
          cachedSelectionStart + placeholder.length,
        );
      });
    },

    formatInputs(style) {
      const cachedSelectionStart = this.currentSelection.selectionStart;
      const cachedSelectionEnd = this.currentSelection.selectionEnd;
      const cachedFieldLength = this.currentSelection.value.length;

      if (this.currentSelection.id.includes('formText')) {
        const index = this.currentSelection.id.split('-').pop();

        this.$emit('updateText', {
          index,
          value: formatText(
            this.formText[index],
            this.currentSelection.selectionStart,
            this.currentSelection.selectionEnd,
            DOCUMENT_DEFAULTS.markupGlyphs[style],
          ),
        });
      } else if (this.currentSelection.id.includes('formFooter')) {
        const index = this.currentSelection.id.split('-').pop();

        this.$emit('updateFooter', {
          index,
          value: formatText(
            this.formFooter[index],
            this.currentSelection.selectionStart,
            this.currentSelection.selectionEnd,
            DOCUMENT_DEFAULTS.markupGlyphs[style],
          ),
        });
      } else if (this.currentSelection.id.includes('formInvoiceRowDescription')) {
        const index = this.currentSelection.id.split('-').pop();

        this.$emit('updateInvoiceDescription', {
          index,
          value: formatText(
            this.formInvoiceRows[index][0],
            this.currentSelection.selectionStart,
            this.currentSelection.selectionEnd,
            DOCUMENT_DEFAULTS.markupGlyphs[style],
          ),
        });
      } else {
        this.$emit('updateField', {
          id: this.currentSelection.id,
          value: formatText(
            this[this.currentSelection.id],
            this.currentSelection.selectionStart,
            this.currentSelection.selectionEnd,
            DOCUMENT_DEFAULTS.markupGlyphs[style],
          ),
        });
      }

      this.$nextTick(() => {
        this.currentSelection.select();

        if (this.currentSelection.value.length > cachedFieldLength) {
          this.currentSelection.setSelectionRange(cachedSelectionStart + 1, cachedSelectionEnd + 1);
        } else {
          this.currentSelection.setSelectionRange(cachedSelectionStart - 1, cachedSelectionEnd - 1);
        }
      });
    },
  },
};
